import React, { useCallback } from 'react';
import styled from 'styled-components';
import Button from '@splunk/react-ui/Button';
import { AngularInjector } from '../../AngularUtils';
import { CommonTooltip } from '../CommonTooltip';

type VizTypeSwitcherProps = {
    chartTypes: Array<{ name: string; icon: string; value: string }>;
    canUnselect: boolean;
    changeChartType: (value: string) => void;
    chartType: string;
};

const StyledButton = styled(Button)`
    width: 24px;
    height: 20px;
    min-width: 24px;
    min-height: 20px;
    padding: 0;

    i.chart-visualization-button:after {
        top: -6px;
    }
`;

export const VizTypeSwitcher = ({
    chartTypes,
    changeChartType,
    chartType,
    canUnselect,
}: VizTypeSwitcherProps): React.JSX.Element => {
    const userAnalytics = AngularInjector.useInjectedClass('userAnalytics');

    const handleChangeVizType = useCallback(
        (value) => {
            changeChartType(value);
            userAnalytics.event('click', `chart-type-switch-${value}`);
        },
        [changeChartType, userAnalytics]
    );

    return (
        <>
            {chartTypes.map((type) => (
                <CommonTooltip
                    key={type.name}
                    content={type.name}
                    contentRelationship="label"
                    component={
                        <StyledButton
                            appearance="subtle"
                            className={`chart-visualization-button ${
                                !canUnselect ? 'nonClickable' : ''
                            }`}
                            aria-label={type.name}
                            aria-pressed={type.value === chartType}
                            icon={
                                <i
                                    className={`${
                                        type.icon
                                    } chart-visualization-button large middle chart-style ${
                                        type.value === chartType ? 'active' : ''
                                    }`}
                                />
                            }
                            onClick={(): void => handleChangeVizType(type.value)}
                        />
                    }
                    defaultPlacement="above"
                />
            ))}
        </>
    );
};
