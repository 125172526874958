import templateUrl from './detectorWizardMessageSuggestions.tpl.html';

angular.module('signalview.detector.wizard').component('detectorWizardMessageSuggestions', {
    bindings: {
        ruleDimensions: '<',
        alertSignalInputs: '<',
        plots: '<',
        addVariableSuggestions: '<',
        rule: '<',
        filterText: '<',
        helperFunction: '<',
        setTabSuggestion: '<',
    },
    templateUrl,
    controller: [
        '$scope',
        'detectorUtils',
        '_',
        'detectorWizardMessageHandlebarService',
        function ($scope, detectorUtils, _, detectorWizardMessageHandlebarService) {
            const ctrl = this;

            ctrl.$onInit = $onInit;

            const booleanVariables = ['anomalous', 'normal'];

            const objectVariables = ['dimensions'];

            const listVariables = ['detectorTeams', 'detectorTags'];

            const notificationVariables = [
                {
                    variableTitle: 'Detector and Rule Details',
                    variables: [
                        { name: 'detectorName', description: 'The name of this detector' },
                        {
                            name: 'ruleName',
                            description: 'The name of the rule that triggered the alert',
                        },
                        {
                            name: 'ruleSeverity',
                            description:
                                'The severity of this rule (Critical, Major, Minor, Warning, Info)',
                        },
                        {
                            name: 'readableRule',
                            description: 'The readable description for this rule',
                        },
                        {
                            name: 'runbookUrl',
                            description: 'URL of page to consult when this alert is triggered',
                        },
                        {
                            name: 'tip',
                            description:
                                'Plain text suggested first course of action, such as a command line to execute',
                        },
                        {
                            name: 'detectorId',
                            description:
                                'The ID of this detector (can be used to programmatically reference this detector)',
                        },
                        { name: 'detectorUrl', description: 'The URL of this detector' },
                        {
                            name: 'detectorTeams',
                            description:
                                "List of all teams linked to this detector, in the following format: [Team{id='TeamA_id', name='TeamA'}, Team{id='TeamB_id', name='TeamB'}]",
                        },
                        {
                            name: 'detectorTags',
                            description:
                                'List of all tags added to this detector, in the following format: [tag1, tag2, ...]',
                        },
                    ],
                },
                {
                    variableTitle: 'Alert Details',
                    variables: [
                        {
                            name: 'timestamp',
                            description:
                                'The GMT timestamp of this alert, in this format: Fri, 13 Oct 2017 20:32:39 GMT',
                        },
                        {
                            name: 'anomalyState',
                            description: 'The state of this event. OK or ANOMALOUS',
                        },
                        {
                            name: 'anomalous',
                            description: 'Boolean; true indicates that the alert triggered',
                            helperDescription: 'Detector has triggered',
                        },
                        {
                            name: 'normal',
                            description: 'Boolean; true indicates that the alert cleared',
                            helperDescription: 'Detector has cleared',
                        },
                        {
                            name: 'imageUrl',
                            description:
                                'The URL for the preview image shown in the notification message',
                        },
                        {
                            name: 'incidentId',
                            description:
                                'The ID of this incident (the incidentID is the same for both the tirgger and the clear alerts)',
                        },
                    ],
                },
                {
                    variableTitle: 'Organization Details',
                    variables: [
                        {
                            name: 'organizationId',
                            description:
                                'The organization ID (can be used to programmatically reference this organization)',
                        },
                    ],
                },
            ];

            $scope.listOfVariablesForHTMLEscaping = [
                'detectorName',
                'ruleName',
                'readableRule',
                'runbookUrl',
                'tip',
                'dimensions',
            ];
            $scope.functionVariables = [
                { name: '#if ', description: 'Conditional' },
                { name: '#each ', description: 'Iterate over items in a list' },
                { name: '#unless ', description: 'Conditional, an inverse of #if function' },
                {
                    name: '#notEmpty ',
                    description: 'If there are dimensions associated with the signal',
                },
            ];

            const disallowedKeys = ['computationId', 'sf_originatingMetric'];

            function $onInit() {
                $scope.suggestions = getVariables();
                ctrl.insertVariable = insertVariable;
            }

            function getVariables() {
                const variables = angular.copy(notificationVariables);

                let dimensionDescription = [];
                if (ctrl.ruleDimensions) {
                    dimensionDescription = getDimensionDescription(ctrl.ruleDimensions);
                }

                const plotDataDescription = ctrl.alertSignalInputs
                    ? getInputDescriptions(ctrl.alertSignalInputs)
                    : getPlotDataDescription();

                const eventAnnotationDescriptions = getEventAnnotationDescriptions();
                const signalDetails = plotDataDescription
                    .concat(dimensionDescription)
                    .concat(eventAnnotationDescriptions);
                if (signalDetails.length) {
                    variables.push({
                        variableTitle: 'Signal Details',
                        variables: signalDetails,
                    });
                }

                return variables;
            }

            function getEventAnnotationDescriptions() {
                const eventAnnotationDescriptions = [];
                const eventAnnotations = detectorUtils.getEventAnnotations(ctrl.rule);
                eventAnnotations.forEach((eventAnnotation) => {
                    eventAnnotationDescriptions.push({
                        name: `event_annotations.${eventAnnotation.name}`,
                        description: eventAnnotation.description,
                    });
                });

                return eventAnnotationDescriptions;
            }

            function getDimensionDescription(dimensions) {
                const items =
                    Object.keys(dimensions).filter((key) => {
                        return disallowedKeys.indexOf(key) === -1;
                    }) || [];
                const dimensionItems = items.map((item) => {
                    // When dimension names contain dots, you must enclose them in square brackets for the variable to work
                    const dimensionVariable = item.indexOf('.') !== -1 ? `[${item}]` : item;
                    return {
                        name: `dimensions.${dimensionVariable}`,
                        description: `The value of the dimension "${item}" for the signal being monitored`,
                    };
                });

                return dimensionItems.concat({
                    name: 'dimensions',
                    description: 'List of all dimensions for the signal being monitored',
                    helperDescription: 'There are dimensions associated with the signal',
                });
            }

            function getPlotDataDescription() {
                const message = detectorUtils.getAutoDetectorRuleParameterizedString(
                    ctrl.rule,
                    ctrl.plots,
                    true
                );
                const inputs = detectorWizardMessageHandlebarService.getInputVariables(message);
                return getInputDescriptions(inputs);
            }

            function getInputDescriptions(inputs = []) {
                return inputs.map((letter) => {
                    return {
                        name: `inputs.${letter}.value`,
                        description: `The value of the signal on plot ${letter}`,
                    };
                });
            }

            function getEscapedSuggestion(suggestion) {
                let suggestionText = `{{${suggestion}}}`;
                if ($scope.listOfVariablesForHTMLEscaping.indexOf(suggestion) !== -1) {
                    suggestionText = `{{{${suggestion}}}}`;
                }
                return suggestionText;
            }

            function insertVariable(suggestion) {
                ctrl.addVariableSuggestions(getEscapedSuggestion(suggestion));
            }

            function filterSortVariables(suggestions, filterText, helperFunction) {
                let ft = filterText || '';
                ft = ft.toLowerCase();

                const filteredSuggestions = suggestions.filter((suggestion) => {
                    if (helperFunction) {
                        if (['#if', '#unless'].includes(helperFunction)) {
                            return booleanVariables.includes(suggestion.name);
                        } else if (helperFunction === '#notEmpty') {
                            return objectVariables.includes(suggestion.name);
                        } else if (helperFunction === '#each') {
                            return listVariables.includes(suggestion.name);
                        }
                    }

                    if (!ft) {
                        return true;
                    }

                    return _.includes(suggestion.name.toLowerCase(), ft);
                });

                const sortedSuggestions = _.sortBy(filteredSuggestions, (suggestion) => {
                    const suggestionName = suggestion.name.toLowerCase();

                    if (!ft) {
                        return suggestionName;
                    }

                    return suggestionName.indexOf(ft);
                });

                return sortedSuggestions;
            }

            ctrl.$onChanges = function () {
                let suggestions = getVariables();

                suggestions.forEach((suggestion) => {
                    if (suggestion.variables && suggestion.variables.length) {
                        suggestion.variables = filterSortVariables(
                            suggestion.variables,
                            ctrl.filterText,
                            ctrl.helperFunction
                        );
                    }

                    if (suggestion.variables.length) {
                        suggestion.firstMatchedIndex = _.toLower(
                            suggestion.variables[0].name
                        ).indexOf(_.toLower(ctrl.filterText));
                    } else {
                        suggestion.firstMatchedIndex = Infinity;
                    }
                });

                suggestions = suggestions.filter((suggestion) => suggestion.variables.length);

                suggestions = _.sortBy(suggestions, (suggestion) => suggestion.firstMatchedIndex);

                const filteredFunctions = filterSortVariables(
                    $scope.functionVariables,
                    ctrl.filterText,
                    ctrl.helperFunction
                );
                let functionsFirstMatchIndex = Infinity;
                if (filteredFunctions && filteredFunctions.length) {
                    functionsFirstMatchIndex = _.toLower(filteredFunctions[0].name).indexOf(
                        _.toLower(ctrl.filterText)
                    );
                }

                let matchedSuggestions = null;

                if (suggestions.length && filteredFunctions.length) {
                    matchedSuggestions =
                        functionsFirstMatchIndex < suggestions[0].firstMatchedIndex
                            ? filteredFunctions
                            : suggestions[0].variables;
                } else if (suggestions.length) {
                    matchedSuggestions = suggestions[0].variables;
                } else if (filteredFunctions.length) {
                    matchedSuggestions = filteredFunctions;
                }

                if (matchedSuggestions && matchedSuggestions.length) {
                    ctrl.setTabSuggestion(getEscapedSuggestion(matchedSuggestions[0].name));
                } else {
                    ctrl.setTabSuggestion(null);
                }

                $scope.suggestions = suggestions;
                $scope.filteredFunctions = filteredFunctions;
            };
        },
    ],
});
