import React, { useCallback } from 'react';
import styled from 'styled-components';
import Button from '@splunk/react-ui/Button';
import { AngularInjector } from '../../../common/AngularUtils';
import { CommonTooltip } from '../CommonTooltip';

type VisualizationButtonProps = {
    onClick: () => void;
    disabled: boolean;
    iconClass: string;
};

const StyledButton = styled(Button)`
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    padding: 0;
    margin-left: 4px;
`;

export const VisualizationButton = ({
    iconClass,
    disabled,
    onClick,
}: VisualizationButtonProps): React.JSX.Element => {
    const userAnalytics = AngularInjector.useInjectedClass('userAnalytics');

    const handleClick = useCallback(() => {
        onClick();
        userAnalytics.event('click', 'plot-visualization-override');
    }, [onClick, userAnalytics]);

    return (
        <CommonTooltip
            component={
                <StyledButton
                    appearance="subtle"
                    disabled={disabled}
                    icon={<i className={iconClass} />}
                    onClick={handleClick}
                />
            }
            content="Plot visualization type in use"
            contentRelationship="label"
            defaultPlacement="above"
        />
    );
};
