import templateUrl from './infraNavClusterMap.tpl.html';
import clusterMapTooltip from './infraNavClusterMapTooltip.tpl.html';
import primaryHeader from './headers/primaryHeader.tpl.html';

/**
 * This is a copy of the k8s clusterMap component, with some modifications to
 * allow it to be used in the new olly k8s infra navigators.
 */

export default {
    templateUrl,
    bindings: {
        vizConfig: '<',
        sourceFilters: '<',
        variables: '<',
        filterSuggestionQueryCallback: '<',
        onSelection: '<',
        time: '<',
        hierarchicalNavConfig: '<',
        onDataUpdated: '<?',
        mapSelectionFilter: '<?',
        onContextChanged: '<?',
    },
    controller: [
        '_',
        '$scope',
        '$timeout',
        '$compile',
        '$element',
        'TetherDrop',
        'clusterMapUtil',
        'infraNavClusterMapViz',
        'infraNavClusterMapStreamer',
        'infoSidebarUtil',
        'ANALYZER_EVENT',
        'infraNavClusterMapFilterUtils',
        'featureEnabled',
        function (
            _,
            $scope,
            $timeout,
            $compile,
            $element,
            TetherDrop,
            clusterMapUtil,
            infraNavClusterMapViz,
            infraNavClusterMapStreamer,
            infoSidebarUtil,
            ANALYZER_EVENT,
            infraNavClusterMapFilterUtils,
            featureEnabled
        ) {
            const TOOLTIP_DEBOUNCE_TIMEOUT = 100;
            const DEFAULT_GROUP_BY = 'kubernetes_cluster';
            const CLUSTER_RESOURCE_KEY = 'k8sclusters';
            const $ctrl = this;
            const mapElementClass = '.cluster-map-viz';
            const headerClass = 'header-container';

            const headerDOMToScopeMap = new Map();

            const debouncedShowTooltip = _.debounce(showTooltip, TOOLTIP_DEBOUNCE_TIMEOUT);
            let resourceSelectionPromise;

            // This maps maps hierarchical items to header templates.
            // The primaryHeader template renders the groupBy (kubernetes_cluster) data value
            const clusterGroupTemplateMap = {
                header: {
                    primary: primaryHeader,
                },
                footer: null,
            };

            let dataService;
            let dataConfig;
            let viz;
            let tooltip;
            let streamingStarted = false;
            const debouncedStartFullDepthStreaming = _.debounce(startFullDepthStreaming, 1000);

            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;
            $ctrl.loadingMap = true;
            $ctrl.getCurrentQuery = getCurrentQuery;
            $ctrl.getHeaderEntitySeverityClass = getHeaderEntitySeverityClass;
            $ctrl.removeTooltip = removeTooltip;
            $ctrl.showTooltip = debouncedShowTooltip;

            function $onInit() {
                dataService = infraNavClusterMapStreamer($ctrl.hierarchicalNavConfig);
                dataConfig = dataService.getConfig();

                const mapContainerElement = $element.find(mapElementClass).get(0);
                const vizConfig = Object.assign({ element: mapContainerElement }, $ctrl.vizConfig);
                viz = infraNavClusterMapViz(vizConfig, dataConfig);
                viz.setPrimaryHeaderCallback(addPrimaryHeader);

                bindEvents(mapContainerElement);
                debouncedStartFullDepthStreaming();
                $ctrl.filterSuggestionQueryCallback(getCurrentQuery);
                updateVizFilterState();
            }

            function $onChanges({ sourceFilters, variables, time, mapSelectionFilter }) {
                const shouldUpdateFilterState =
                    !!sourceFilters || !!mapSelectionFilter || !!variables;
                let restartStreamingJob = false;
                if (featureEnabled('kubernetesListNavigators') && !!sourceFilters) {
                    // if one of the k8s filters (specified in replaceOnlyFilters k8s config) is modified, then we want to restart the
                    // signalflow streaming job so it gets re-run with the new set of k8s filters.
                    if (isK8sFilterUpdated(sourceFilters)) {
                        restartStreamingJob = true;
                    }
                }
                if (shouldUpdateFilterState) {
                    $ctrl.loadingMap = true;
                    updateVizFilterState();

                    if (viz) {
                        setMapAutoSelectionOverrides();
                    }
                }

                if (time) {
                    restartStreamingJob = true;
                }

                if (restartStreamingJob) {
                    debouncedStartFullDepthStreaming();
                }
            }

            /**
             * Returns true if any of the hierarchical k8s filters (as defined by
             * replaceOnlyFilters in the content configuration) have changed in the
             * list of filters
             * @param sourceFilters
             * @returns {boolean}
             */
            function isK8sFilterUpdated(sourceFilters) {
                let k8sFilterChanged = false;
                if ($ctrl.hierarchicalNavConfig.get('k8snodes')?.getConfig()?.metrics?.length > 0) {
                    const replaceOnlyFilters = $ctrl.hierarchicalNavConfig
                        .get('k8snodes')
                        ?.getConfig()?.metrics[0].job?.replaceOnlyFilters;
                    if (replaceOnlyFilters?.length > 0) {
                        // check if the current (or previous) filters have changed for any of the configured K8s properties
                        const currentK8sFilters = sourceFilters?.currentValue?.filter((filter) =>
                            replaceOnlyFilters.includes(filter?.property)
                        );
                        const previousK8sFilters = Array.isArray(sourceFilters?.previousValue)
                            ? sourceFilters?.previousValue?.filter((filter) =>
                                  replaceOnlyFilters.includes(filter?.property)
                              )
                            : [];
                        // if there are any k8s filters, check for differences
                        if (currentK8sFilters?.length || previousK8sFilters?.length) {
                            if (currentK8sFilters?.length !== previousK8sFilters?.length) {
                                // a k8s filter was added or removed
                                k8sFilterChanged = true;
                            } else {
                                // k8s filter properties are unchanged; see if any k8s filter *values* have changed
                                for (
                                    let i = 0;
                                    i < currentK8sFilters.length && !k8sFilterChanged;
                                    i++
                                ) {
                                    const currentK8s = currentK8sFilters[i].values;
                                    const previousK8s = previousK8sFilters[i].values;
                                    k8sFilterChanged =
                                        currentK8s.filter((x) => !previousK8s.includes(x)).length >
                                            0 ||
                                        previousK8s.filter((x) => !currentK8s.includes(x)).length >
                                            0;
                                }
                            }
                        }
                    }
                }
                return k8sFilterChanged;
            }

            function bindEvents(mapContainerElement) {
                viz.on(viz.EVENTS.ZOOM, onZoom);
                viz.on(viz.EVENTS.MOUSEOVER, debouncedShowTooltip);
                viz.on(viz.EVENTS.MOUSEOUT, removeTooltip);
                viz.on(viz.EVENTS.RENDER, onVizRender);
                viz.on(viz.EVENTS.DESTROY, onElementDestroy);
                viz.on(viz.EVENTS.CLICK, onResourceClick);
                dataService.setCallback(onMapStateUpdate);

                const resizeObserver = new ResizeObserver(() => viz.resize());
                resizeObserver.observe(mapContainerElement);

                $scope.$on('$destroy', function () {
                    resizeObserver.disconnect();
                    debouncedStartFullDepthStreaming.cancel();
                    dataService.cleanup();
                    removeTooltip();
                });
            }

            function getCurrentQuery() {
                return dataConfig.getSignalFlowForAllResources();
            }

            // Use with a debounce only
            function startFullDepthStreaming() {
                // Reset every time a new set of streaming queries are started
                // This is to prevent the hasNoData state from being set when changing filters or time range
                // When the dataService.cleanup method calls the onMapStateUpdate callback after clearning the data
                streamingStarted = false;
                dataService.cleanup();

                $ctrl.loadingMap = true;
                const resourceHierarchy = dataConfig.getResourceHierarchy();
                for (const resource of resourceHierarchy) {
                    const resourceConfig = dataConfig.get(resource);
                    if (resourceConfig && resourceConfig.hasStreamingJob()) {
                        dataService.startStreaming(resource, $ctrl.time);
                    }
                }
                streamingStarted = true;
            }

            function getResourceToZoomDepth() {
                const groupByKeys = dataConfig?.getHierarchicalGroupByKeys();
                if (!groupByKeys) {
                    return -1;
                }

                if (isMapSelectionZoomable()) {
                    // If a mapSelectionFilter is passed in and it can be zoomed, return
                    // the depth corresponding to the filter property
                    return groupByKeys.findIndex(
                        (key) => key === $ctrl.mapSelectionFilter.property
                    );
                } else {
                    // If the mapSelectionFilter was passed in but cannot be zoomed in on, check for
                    // a filter corresponding to the deepest level that can be zoomed, and return
                    // that depth (if such a filter exists). If no mapSelectionFilter was passed in
                    // but a filter on a cluster exists, return the cluster level depth (selected
                    // cluster will not be passed in through mapSelectionFilter).
                    const possibleSelectionDepth = $ctrl.mapSelectionFilter
                        ? viz.getMaxZoomLevel()
                        : dataConfig?.getResourceDepth(CLUSTER_RESOURCE_KEY);
                    const filtersForDepth = getFiltersOnIdPropertyForDepth(possibleSelectionDepth);
                    return filtersForDepth && filtersForDepth.length > 0
                        ? possibleSelectionDepth
                        : -1;
                }
            }

            function onMapStateUpdate(stateRoot) {
                if (streamingStarted) {
                    $ctrl.loadingMap = false;
                    $ctrl.hasNoData = stateRoot.children.length === 0;
                }

                $scope.$applyAsync(() => viz.update(stateRoot));

                const unaryIdFilteredBranch = stateRoot.unaryIdFilteredBranch || [];

                const depth = getResourceToZoomDepth();
                let resourceToZoom = null;
                if (depth >= 0 && unaryIdFilteredBranch.length > depth) {
                    resourceToZoom = unaryIdFilteredBranch[depth];
                    updateFilterStateForAutoZoom(resourceToZoom);
                }

                viz.zoomToResource(resourceToZoom || stateRoot);
                if ($ctrl.onDataUpdated) {
                    $ctrl.onDataUpdated(stateRoot, $ctrl.loadingMap);
                }
            }

            function onZoom(originalEvent, data, { phase }) {
                if (phase === viz.PHASES.IN_PROGRESS) {
                    removeTooltip();
                } else if (phase === viz.PHASES.FINISHED) {
                    setMapAutoSelectionOverrides();
                }
            }

            // Set filters on click
            function onResourceClick(originalEvent, data) {
                const target = this;
                const isResourceFilteredOut =
                    data.resourceIsFilteredOut ||
                    infraNavClusterMapFilterUtils.isParentFilteredOut(data);

                $timeout.cancel(resourceSelectionPromise);
                resourceSelectionPromise = $timeout(() => {
                    if ($ctrl.onSelection && !isResourceFilteredOut) {
                        $ctrl.loadingMap = true;
                        $ctrl.onSelection(data);
                    }

                    if (originalEvent && target && !isResourceFilteredOut) {
                        setInfoSidebarRef(target, data);
                    }

                    $scope.$apply();
                });
            }

            function getOverridesToSetForData(data) {
                const hierarchicalProps = dataConfig.getHierarchicalGroupByKeys();

                const overridesToSet = [];

                // Remove all hierarchical filters with depth greater than current
                for (let depth = data.depth + 1; depth < hierarchicalProps.length; depth++) {
                    const property = hierarchicalProps[depth];
                    if (property) {
                        overridesToSet.push({ property, propertyValue: null });
                    }
                }

                // Set all hierarchical filters with depth less than or equal to current
                while (data) {
                    const property = hierarchicalProps[data.depth];
                    if (property) {
                        overridesToSet.push({ property, propertyValue: data.data[property] });
                    }
                    data = data.parent;
                }

                return overridesToSet;
            }

            function isMapSelectionZoomable() {
                if (!$ctrl.mapSelectionFilter) {
                    return false;
                }

                const mapSelectionDepth = dataConfig
                    .getHierarchicalGroupByKeys()
                    .findIndex((groupByKey) => groupByKey === $ctrl.mapSelectionFilter.property);
                return mapSelectionDepth >= 0 && viz.isValidZoomLevel(mapSelectionDepth);
            }

            function getFiltersOnIdPropertyForDepth(depth) {
                const groupByKeys = dataConfig?.getHierarchicalGroupByKeys();
                if (_.isNil(groupByKeys) || _.isNil(depth)) {
                    return null;
                }

                return getFilters().filter(
                    (filter) => filter.property === groupByKeys[depth] && !filter.NOT
                );
            }

            function setMapAutoSelectionOverrides() {
                // The currently selected resource (if any) will either be passed in through
                // the mapSelectionFilter param, or for a selected cluster will be
                // represented as filter(s) on the cluster group by key passed in among the
                // other filters.
                const selectionFilters = $ctrl.mapSelectionFilter
                    ? [$ctrl.mapSelectionFilter]
                    : getFiltersOnIdPropertyForDepth(
                          dataConfig?.getResourceDepth(CLUSTER_RESOURCE_KEY)
                      );
                viz.setAutoSelectionFilters(selectionFilters ?? []);
            }

            function setInfoSidebarRef(element, data) {
                const resourceConfig = dataConfig.get(dataConfig.getResourceAtDepth(data.depth));

                if (!resourceConfig || !resourceConfig.getGroupByKey()) {
                    return;
                }

                let resourceOverrides = getOverridesToSetForData(data);
                resourceOverrides = resourceOverrides.filter(
                    ({ propertyValue }) => !_.isEmpty(propertyValue)
                );
                const panel = infoSidebarUtil.getPanelForFilters(
                    resourceConfig.getGroupByKey(),
                    resourceOverrides
                );

                if (!panel) {
                    return;
                }

                viz.setSelection(element, data);
            }

            function updateFilterStateForAutoZoom(data) {
                const currentStateFilters = dataService.getStateFilters() || [];
                const appliedFilterKeys = currentStateFilters.map((f) => f.property);
                const overridesForData = getOverridesToSetForData(data);
                const overridesToAdd = overridesForData.filter(
                    ({ property }) => !appliedFilterKeys.includes(property)
                );
                const mergedFilters = currentStateFilters.concat(overridesToAdd);

                dataService.passivelySetStateFilters(mergedFilters);
            }

            function onVizRender(originalEvent, data, { phase }) {
                if (phase !== viz.PHASES.FINISHED) {
                    return;
                }

                const analyzerContexts = data.map(({ depth, groupedUpon, groupId }) => {
                    return {
                        context: dataConfig.getResourceAtDepth(depth),
                        property: groupedUpon,
                        propertyValue: groupId,
                    };
                });
                $ctrl.onContextChanged(analyzerContexts);
                $scope.$applyAsync();
            }

            function getObjectTemplateMapForResourceType(resourceType) {
                let objectTemplate;
                const resourceHierarchy = dataConfig.getResourceHierarchy();
                const index = resourceHierarchy.indexOf(resourceType);
                // map the first and second configs in the hiearchy (after the 0-indexed GLOBAL config) to the ObjectTemplateMap properties,
                // as the resourceTypes may have different names and not map exactly (eg: 'k8sclusters' --> 'CLUSTER')
                if (index === 1 || index === 2) {
                    objectTemplate = clusterGroupTemplateMap;
                } else {
                    // fallback
                    if (resourceType === 'CLUSTER' || resourceType === 'NODE') {
                        objectTemplate = clusterGroupTemplateMap;
                    }
                }
                return objectTemplate;
            }

            function addPrimaryHeader(data, resourceType, size) {
                const objectTemplateMap = getObjectTemplateMapForResourceType(resourceType);
                const primary = objectTemplateMap ? objectTemplateMap.header.primary : null;
                addHeader(this, data, primary, size);
            }

            function addHeader(DOM, datum, headerTemplate, size) {
                const headerElement = angular.element(
                    `<div class="${headerClass}" ng-include="template">`
                );
                angular.element(DOM).append(headerElement);

                const childScope = $scope.$new();
                childScope.clusterMapResource = datum;

                childScope.template = headerTemplate;
                childScope.data = datum.data;
                childScope.datum = datum;
                childScope.size = size - 2;

                // add top-level groupBy/id field to the scope; the header template will display this data property value in the header
                // of each group
                let groupBy = DEFAULT_GROUP_BY;
                const resourceHierarchy = dataConfig.getResourceHierarchy();
                if (resourceHierarchy && resourceHierarchy.length > 1) {
                    // get the top hierarchical group (after the GLOBAL item at the 0-index)
                    const resourceConfig = dataConfig.get(resourceHierarchy[1]);
                    if (resourceConfig) {
                        groupBy = resourceConfig.getGroupByKey();
                    }
                }
                childScope.groupBy = groupBy;

                $compile(headerElement)(childScope);
                headerDOMToScopeMap.set(DOM, childScope);
            }

            function getHeaderEntitySeverityClass(headerEntity) {
                return clusterMapUtil.getStateColorClass(
                    headerEntity.data,
                    dataConfig
                        .get(dataConfig.getResourceAtDepth(headerEntity.depth))
                        .getColorByConfig(),
                    headerEntity.depth
                );
            }

            function onElementDestroy(originalEvent, elementsRemoved) {
                const headers = Array.from(headerDOMToScopeMap.keys());
                const removedEls = angular.element(elementsRemoved);

                headers
                    .filter((header) => removedEls.has(header).length || removedEls.is(header))
                    .forEach(function (header) {
                        if (headerDOMToScopeMap.has(header)) {
                            headerDOMToScopeMap.get(header).$destroy();
                            headerDOMToScopeMap.delete(header);
                        }
                    });
            }

            function showTooltip(originalEvent, datum) {
                if (!datum || datum.depth === 0 || viz.isTransitioning()) {
                    return;
                }

                if (!tooltip) {
                    const tooltipScope = $scope.$new();
                    tooltipScope.template = clusterMapTooltip;

                    tooltip = {
                        tooltipScope,
                        content: angular.element(
                            '<div class="cluster-map-tooltip"><div ng-include="template"></div></div>'
                        )[0],
                    };

                    $compile(tooltip.content)(tooltipScope);
                }

                removeTooltip();

                const dropOptions = {
                    target: this.node(),
                    position: 'right center',
                    content: tooltip.content,
                    classPrefix: 'cluster-map-',
                    classes: 'resource-tooltip cluster-map-tooltip',
                    tetherOptions: {},
                    constrainToScrollParent: false,
                };

                const { tooltipScope } = tooltip;
                const resourceType = dataConfig.getResourceAtDepth(datum.depth);
                const isSelected = viz.getSelectedId() === datum.id;
                const isZoomable = viz.isZoomable(datum);
                const isResourceFilteredOut =
                    datum.resourceIsFilteredOut ||
                    infraNavClusterMapFilterUtils.isParentFilteredOut(datum);
                let interactionMessage;

                if (isZoomable) {
                    interactionMessage = 'Click to zoom';
                } else if (!isSelected) {
                    const willZoomOnClick =
                        viz.isDeeperThanMaxZoomLevel(datum.depth) &&
                        datum.depth > viz.currentOverviewDepth() + 1;
                    interactionMessage = willZoomOnClick ? 'Click to zoom' : 'Click to select';
                }

                if (isResourceFilteredOut) {
                    interactionMessage = '';
                }

                tooltipScope.interactionMessage = interactionMessage;
                tooltipScope.data = dataConfig.get(resourceType).getTooltipData(datum);
                tooltip.tether = new TetherDrop(dropOptions);

                // Run Digest cycle on new data and allow DOM to Update. This makes Tether to pick up correct size.
                tooltip.tooltipScope.$applyAsync(() => {
                    tooltip.DOMUpdatePromise = $timeout(
                        () => tooltip.tether && tooltip.tether.open()
                    );
                });
            }

            function removeTooltip() {
                // Flush debounce queue
                debouncedShowTooltip(null, null);

                if (tooltip && tooltip.tether) {
                    $timeout.cancel(tooltip.DOMUpdatePromise);
                    tooltip.tether.remove();
                    tooltip.tether.destroy();
                    delete tooltip.tether;
                }
            }

            $scope.$on(
                ANALYZER_EVENT.CLUSTER_MAP_LOOK_FOR_MATCHING_CHILD,
                function (event, cluster, target, requiredFilters) {
                    const child = dataService.findChildMatchingAnalyzerResult(cluster, target);
                    if (child) {
                        $scope.$emit(
                            ANALYZER_EVENT.CLUSTER_MAP_FOUND_MATCHING_CHILD,
                            child,
                            requiredFilters,
                            target.key
                        );
                    }
                }
            );

            function updateVizFilterState() {
                const filters = getFilters();
                if (dataService) {
                    dataService.updateFilterState(filters);
                }
                $scope.$applyAsync();
            }

            function getFilters() {
                const variableMappedFilters = ($ctrl.variables || []).map(
                    ({ property, value }) => ({ property, propertyValue: value, NOT: false })
                );
                const sourceFilters = $ctrl.sourceFilters || [];
                let mapSelectionFilter = [];
                if (isMapSelectionZoomable()) {
                    mapSelectionFilter = angular.copy($ctrl.mapSelectionFilter);
                    mapSelectionFilter.isMapSelectionFilter = true;
                }
                // copy filters so the original filters are not modified during the state update.
                return angular.copy(
                    sourceFilters.concat(mapSelectionFilter).concat(variableMappedFilters)
                );
            }
        },
    ],
};
