import React from 'react';
import { DashboardNavHeaderStatus } from './DashboardNavHeaderStatus';
import { PageHeaderWrapper } from '../../../../app/header/PageHeaderWrapper';
import {
    DashboardNavHeaderActions,
    DashboardNavHeaderActionsProps,
} from './DashboardNavHeaderActions';
import { PageHeaderProps } from '@splunk/olly-common';

type DashboardNavPageHeaderWrapperProps = Pick<PageHeaderProps, 'description' | 'navigation'> &
    Pick<
        DashboardNavHeaderActionsProps,
        | 'actions'
        | 'hasDeleteDashboardGroupCapability'
        | 'isExportEnabled'
        | 'groupActions'
        | 'onTrackClick'
    > & {
        showActions?: boolean;
        showStatus?: boolean;
    };

export const DashboardNavPageHeaderWrapper = ({
    actions,
    description,
    hasDeleteDashboardGroupCapability,
    isExportEnabled,
    groupActions,
    showActions,
    navigation,
    onTrackClick,
    showStatus,
}: DashboardNavPageHeaderWrapperProps): React.JSX.Element => {
    return (
        <PageHeaderWrapper
            description={description}
            navigation={navigation}
            rightContent={
                actions !== undefined && groupActions !== undefined && showActions ? (
                    <DashboardNavHeaderActions
                        actions={actions}
                        hasDeleteDashboardGroupCapability={hasDeleteDashboardGroupCapability}
                        isExportEnabled={isExportEnabled}
                        groupActions={groupActions}
                        onTrackClick={onTrackClick}
                    />
                ) : null
            }
            titleContent={showStatus ? <DashboardNavHeaderStatus /> : null}
        />
    );
};
