import { react2angular } from 'react2angular';
import crossLinkUtils from './crossLinkUtils';
import crossLink from './crossLink';
import crossLinkAnchor from './crossLinkAnchor';
import crossLinkDataService from './crossLinkDataService';
import CrossLinkRedirect from './CrossLinkRedirect';
import propertyKeyMap from './propertyKeyMap';
import crossLinkCondition from './crossLinkCondition';
import crossLinkDefinitionRow from './crossLinkDefinitionRow';
import crossLinkDefinitionForms from './crossLinkDefinitionForms';
import crossLinkUpdatableTargetsFactory from './crossLinkUpdatableTargetsFactory';
import NavigatorSelector from './navigatorSelector';
import AppdShowOnOption from './AppdShowOnOption';
import AppdShowOnWrapper from './AppdShowOnWrapper';
import AppdUrlOption from './AppdUrlOption';
import AppdTerraform from './AppdTerraform';

angular
    .module('signalview.crossLinks', [
        'sfUtil',
        'sfx.api.v2',
        'signalview.timePicker',
        'signalview.urlOverrides',
    ])
    .constant('CROSS_LINK_EVENTS', {
        INVALIDATED_CACHE: 'cross link cache invalidated',
        OPEN_CONFIG_MODAL: 'cross link open group config',
        CROSS_LINK_UPDATED: 'cross link updated',
    })
    .constant('CROSS_LINK_TYPES', {
        EXTERNAL_LINK: 'EXTERNAL_LINK',
        SPLUNK_LINK: 'SPLUNK_LINK',
        INTERNAL_LINK: 'INTERNAL_LINK',
        KIBANA_LINK: 'KIBANA_LINK',
        INTERNAL_NAVIGATOR_LINK: 'INTERNAL_NAVIGATOR_LINK',
        APPD_LINK: 'APPD_LINK',
        NAVIGATOR_LINK: 'NavigatorLink',
    })
    .constant('EXTERNAL_LINK_OPTIONS', {
        TIME_FORMATS: {
            // The property values below are string objects instead of literals to avoid interning, making properties with the same value unequal.
            ISO8601: new String('ISO 8601: YYYY-MM-DDThh:mm:ss.SSSZ'),
            Epoch: new String('Unix time: epoch milliseconds'),
            EpochSeconds: new String('Unix time: epoch seconds'),
        },
        TIME_WINDOW_OPTIONS: {
            '1s': '1 second',
            '1m': '1 minute',
            '5m': '5 minute',
            '15m': '15 minute',
            '1h': '1 hour',
            '12h': '12 hour',
            '1d': '1 day',
            '1w': '1 week',
        },
        defaults: {
            TIME_FORMAT: 'ISO8601',
            TIME_WINDOW: '60000',
        },
    })
    .component('crossLinkRedirect', CrossLinkRedirect)
    .component('crossLink', crossLink)
    .component('propertyKeyMap', propertyKeyMap)
    .component('crossLinkCondition', crossLinkCondition)
    .component('crossLinkDefinitionRow', crossLinkDefinitionRow)
    .component('crossLinkDefinitionForms', crossLinkDefinitionForms)
    .component(
        'navigatorSelector',
        react2angular(NavigatorSelector, ['onNavigatorSelector', 'target'])
    )
    .component(
        'appdShowOnOption',
        react2angular(AppdShowOnOption, [
            'preselectAppdService',
            'appdServiceName',
            'onUpdateAppdShowOnOption',
            'onAppdShowonFocus',
            'onAppdShowonBlur',
            'definition',
        ])
    )
    .component(
        'appdShowOnWrapper',
        react2angular(AppdShowOnWrapper, [
            'preselectAppdService',
            'appdServiceName',
            'onUpdateAppdShowOnOption',
            'onAppdShowonFocus',
            'onAppdShowonBlur',
            'definition',
        ])
    )
    .component(
        'appdUrlOption',
        react2angular(AppdUrlOption, [
            'onUpdateAppdLink',
            'appdUrl',
            'isLinkValid',
            'onAppdUrlBoxFocus',
            'onAppdUrlBoxBlur',
            'target',
            'urlErrorMessage',
        ])
    )
    .component('appdTerraform', react2angular(AppdTerraform))
    .directive('crossLinkAnchor', crossLinkAnchor)
    .factory('crossLinkUpdatableTargets', crossLinkUpdatableTargetsFactory)
    .service('crossLinkUtils', crossLinkUtils)
    .service('crossLinkDataService', crossLinkDataService);
