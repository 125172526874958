export default [
    'crossLinkDataService',
    'CROSS_LINK_EVENTS',
    'crossLinkUtils',
    'routeParameterService',
    'userAnalytics',
    'urlOverridesService',
    function (
        crossLinkDataService,
        CROSS_LINK_EVENTS,
        crossLinkUtils,
        routeParameterService,
        userAnalytics,
        urlOverridesService,
        CROSS_LINK_TYPES
    ) {
        return {
            restrict: 'A',
            scope: {
                dimensions: '<?crossLinkAnchorDimensions',
                context: '<?crossLinkAnchorContext',
                propertyName: '<?crossLinkAnchorPropertyName',
                propertyValue: '<?crossLinkAnchorPropertyValue',
                timeRange: '<?crossLinkAnchorTimeRange',
            },
            bindToController: true,
            controller: [
                '$scope',
                '$element',
                function ($scope, $element) {
                    const $ctrl = this;
                    const HAS_CROSS_LINK_CLASS = 'has-cross-link';
                    let hasBoundEvents = false;
                    let deregisterRouteWatch;
                    let urlFiltersContext = null;

                    $ctrl.$onChanges = $onChanges;
                    $ctrl.$onDestroy = $onDestroy;
                    $ctrl.$onInit = $onInit;

                    const unregisterRouteWatchGroup = routeParameterService.registerRouteWatchGroup(
                        ['sources[]', 'variables[]'],
                        function (loc, changed) {
                            if (!_.isEmpty(changed)) {
                                urlFiltersContext =
                                    crossLinkDataService.getContextFromURLOverrides();
                                getCrossLinkAndSetHref();
                            }
                        }
                    );

                    function $onChanges(changesObj) {
                        const { dimensions, propertyName, propertyValue, context } = changesObj;

                        if (dimensions || propertyName || propertyValue) {
                            urlFiltersContext = crossLinkDataService.getContextFromURLOverrides();
                            setDimension();
                            getCrossLinkAndSetHref();
                        }

                        if (context) {
                            getCrossLinkAndSetHref();
                        }
                    }

                    function $onDestroy() {
                        unbindEvents();
                        unregisterRouteWatchGroup();
                    }

                    function $onInit() {
                        $scope.$on(CROSS_LINK_EVENTS.INVALIDATED_CACHE, () => {
                            setDimension();
                            getCrossLinkAndSetHref();
                        });

                        setDimension();
                        getCrossLinkAndSetHref();
                    }

                    function setDimension() {
                        if ($ctrl.dimensions) {
                            $ctrl.dimension = angular.isArray($ctrl.dimensions)
                                ? $ctrl.dimensions[0]
                                : $ctrl.dimensions;
                        } else if ($ctrl.propertyName) {
                            $ctrl.dimension = {
                                propertyName: $ctrl.propertyName,
                                propertyValue: $ctrl.propertyValue,
                            };
                        }
                    }

                    function getCrossLinkAndSetHref() {
                        if ($ctrl.dimension) {
                            const { propertyName, propertyValue } = $ctrl.dimension;

                            const extendedContext = crossLinkDataService.getExtendedContext(
                                $ctrl.dimensions || [$ctrl.dimension],
                                null,
                                urlFiltersContext,
                                $ctrl.context
                            );

                            crossLinkDataService.attachCrosslinkTargets(
                                [$ctrl.dimension],
                                extendedContext
                            );

                            crossLinkDataService
                                .getCrossLinkByTrigger(propertyName, propertyValue, extendedContext)
                                .then(({ link: crossLink, isInternal }) => {
                                    if (isInternal && crossLink && crossLink.defaultLink) {
                                        // case 1: internal and default link
                                        $ctrl.crossLink = crossLink;
                                        $ctrl.defaultLink = crossLink.defaultLink;

                                        setHref();
                                        $element.addClass(HAS_CROSS_LINK_CLASS);

                                        bindEvents();
                                    } else if (!isInternal && crossLink) {
                                        // case 2: external (custom) link
                                        $ctrl.crossLink = crossLink;

                                        setExternalHref(extendedContext);
                                        $element.addClass(HAS_CROSS_LINK_CLASS);
                                    } else {
                                        // default case
                                        $element.removeClass(HAS_CROSS_LINK_CLASS);
                                        $element.removeAttr('href');
                                        $element.removeAttr('title');
                                    }
                                });
                        }
                    }

                    function unbindEvents() {
                        if (hasBoundEvents) {
                            $element.off('click');
                            deregisterRouteWatch();

                            hasBoundEvents = false;
                        }
                    }

                    function bindEvents() {
                        if (!hasBoundEvents) {
                            // if cross link has default target, it will need to be updated
                            // when url params change.
                            deregisterRouteWatch = routeParameterService.registerRouteWatchGroup(
                                [
                                    'startTime',
                                    'endTime',
                                    'startTimeUTC',
                                    'endTimeUTC',
                                    'sources[]',
                                    'variables[]',
                                ],
                                () => {
                                    setHref();
                                }
                            );

                            // only attaching track-click here in order to avoid counting
                            // clicks on things without bound cross links.
                            $element.on('click', handleClick);

                            hasBoundEvents = true;
                        }
                    }

                    function handleClick() {
                        if ($ctrl.defaultLink.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK) {
                            userAnalytics.event(
                                'click',
                                'navigator-default-cross-link',
                                null,
                                $ctrl.defaultLink?.navigatorCode ||
                                    crossLinkUtils.CUSTOM_ORGANIZATION_SCOPE_ENTITY
                            );
                        } else {
                            userAnalytics.event('click', 'dashboard-default-cross-link');
                        }
                    }

                    function setHref() {
                        const href = crossLinkUtils.getRedirectHref(
                            $ctrl.crossLink,
                            $ctrl.dimension.propertyValue,
                            $ctrl.defaultLink
                        );

                        $element.attr('href', href);

                        const title = crossLinkUtils.getCrossLinkTitle($ctrl.defaultLink);
                        $element.attr('title', title);
                    }

                    function setExternalHref(extendedContext) {
                        const crossLinkTarget = $ctrl.crossLink.targets[0];
                        const dimension = $ctrl.dimension;
                        const dimensionExternalTargets = dimension?.targets?.external;
                        const attachedTarget = dimensionExternalTargets?.find(
                            (target) => target.target === crossLinkTarget.url
                        );

                        if (!attachedTarget) {
                            return;
                        }

                        const { targetGenerator, target, targetName, targetMap, contextData } =
                            attachedTarget;

                        const url = targetGenerator(
                            target,
                            targetMap,
                            dimension,
                            $ctrl.timeRange || urlOverridesService.getGlobalTimePicker(),
                            {
                                ...contextData,
                                properties: extendedContext,
                            }
                        );

                        $element.attr('href', url);
                        $element.attr('target', '_blank');
                        $element.attr('title', targetName);
                    }
                },
            ],
        };
    },
];
