import React, { useState } from 'react';

import styled from 'styled-components';

import Button from '@splunk/react-ui/Button';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Modal from '@splunk/react-ui/Modal';
import Text from '@splunk/react-ui/Text';
import TextArea from '@splunk/react-ui/TextArea';

export type ChartEditModalProps = {
    name?: string;
    description?: string;
    open?: boolean;
    handleRequestClose?: () => void;
    update: (params: { name?: string; description?: string }) => void;
};

const StyledModal = styled(Modal)`
    width: 38.25rem;
`;

const StyledModalBody = styled(Modal.Body)`
    padding: 1.5rem;
`;

export const ChartEditModal = ({
    name: rawName,
    description: rawDescription,
    open,
    handleRequestClose,
    update,
}: ChartEditModalProps): JSX.Element => {
    const [name, setName] = useState(rawName);
    const [description, setDescription] = useState(rawDescription);

    return (
        <StyledModal open={open} initialFocus="container">
            <Modal.Header title="Edit name and description" onRequestClose={handleRequestClose} />
            <StyledModalBody>
                <ControlGroup label="Name" labelPosition="top">
                    <Text
                        name="title"
                        onChange={(_, { value }): void => setName(value)}
                        value={name}
                    />
                </ControlGroup>

                <ControlGroup label="Description" labelPosition="top">
                    <TextArea
                        name="description"
                        onChange={(_, { value }): void => setDescription(value)}
                        rowsMin={6}
                        value={description}
                    />
                </ControlGroup>
            </StyledModalBody>
            <Modal.Footer>
                <Button appearance="secondary" onClick={handleRequestClose} label="Cancel" />
                <Button
                    onClick={(): void => {
                        update({ name, description });
                    }}
                    appearance="primary"
                    label="Update"
                    type="button"
                />
            </Modal.Footer>
        </StyledModal>
    );
};
