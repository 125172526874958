import { Monitoring } from '../../../../app/routing/Sitemaps';

export const AlertNavController = [
    '$scope',
    'detectorWizardModal',
    'currentUser',
    'featureEnabled',
    function ($scope, detectorWizardModal, currentUser, featureEnabled) {
        const $ctrl = this;

        $scope.detectorWizardModal = detectorWizardModal;
        $scope.currentUser = currentUser;
        $scope.headerNavigation = [{ id: Monitoring.map.id, label: Monitoring.map.label }];
        $ctrl.magneticPageHeader = featureEnabled('magneticPageHeader');

        this.$onInit = function () {
            this.setHierarchicalNav(Monitoring.name, Monitoring.IDs.alerts);
            $scope.setHierarchicalNav = this.setHierarchicalNav;
        };

        this.$onDestroy = function () {
            this.setHierarchicalNav();
        };
    },
];
