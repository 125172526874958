import templateUrl from './chartTypeSwitcher.tpl.html';

export const chartTypeSwitcher = [
    'chartVisualizationService',
    '$timeout',
    function (chartVisualizationService, $timeout) {
        return {
            scope: {
                chartType: '=',
                chartMode: '=?',
                canUnselect: '=',
            },
            templateUrl,
            restrict: 'E',
            link: function (scope) {
                if (angular.isUndefined(scope.canUnselect)) {
                    scope.canUnselect = false;
                }
                scope.allChartTypes = chartVisualizationService.getChartTypes();

                scope.changeChartType = function (type) {
                    if (scope.chartType === type && scope.canUnselect === true) {
                        scope.chartType = null;
                    } else {
                        scope.chartType = type;
                    }
                    // Chart type is only used to distinguish among graphs
                    $timeout(() => {
                        scope.$emit('chartTypeChanged', { mode: 'graph', type: type });
                    }, 0);
                };

                scope.$on('chartModeChanged', function (evt, mode) {
                    if (mode !== 'graph') {
                        // Chart type is only used to distinguish among graphs
                        scope.chartType = null;
                    }
                });
            },
        };
    },
];
