import { axisLabelFormatter } from './axisLabelFormatter.js';
import { chartDisplay } from './chartdisplay.js';
import { chartDisplayCanvasRenderer } from './chartDisplayCanvasRenderer.js';
import { ChartDisplayOverlayRenderer } from './chartDisplayOverlayRenderer.js';
import { chartDisplayUtils } from './chartDisplayUtils.js';
import { chartEventDataSource } from './chartEventDataSource.js';
import { chartModeSwitcher } from './chartModeSwitcher.js';
import { ChartPanningControl } from './chartPanningControl.js';
import { chartTypeSwitcher } from './chartTypeSwitcher.js';
import { chartVisualizationService } from './chartVisualizationService.js';
import { detectorEventTable } from './detectorEventTable.js';
import { heatmapChartLabel } from './heatmap/heatmapChartLabel.js';
import { heatmapChart } from './heatmap/heatmapChart.js';
import { modelToStartEnd } from './modelToStartEnd.js';
import { resolutionPill } from './resolutionPill.js';
import { SAMPLE_CONSTANTS } from './samplingConstants.js';
import { TypedCorrelationStore } from './typedCorrelationStore.js';
import { ThresholdCorrelator } from './thresholdCorrelator.js';
import { jobRerunProgressDonut } from './jobRerunProgressDonut.js';
import { ChartDisplayDebounceService } from './chartDisplayDebounceService.js';
import { chartDisplayDebounceOverlay } from './chartDisplayDebounceOverlay.js';
import { chartDisplayToo } from './chartDisplayToo.js';
import { chartDisplayOmega } from './chartDisplayOmega.js';
import { singleValueChart } from './singleValueChart.js';
import { listChart } from './listChart.js';
import { gaugeChart } from './gaugeChart.js';
import { textChart } from './textChart.js';
import { newChartTypeTemplate } from './newChartTypeTemplate.js';
import { samplingAndRenderingIcons } from './samplingAndRenderingIcons.js';
import { ChartExportService } from './chartExportService.js';
import { barChart } from './barChart.js';
import { mapChart } from './mapChart.js';
import { ChartNoDataService } from './ChartNoDataService.js';
import TableChartContainer from '../../../../app/charting/chartdisplay/tableChart/tableGridChart';
import LogsChartContainerWrapper from '../../../../app/charting/logsChart/logsChartWrapper';
import LogsTimeSeriesChartContainerWrapper from '../../../../app/charting/logsChart/logsTimeSeriesChartWrapper';
import filterChipWrapper from '../../../../app/charting/logsChart/filterChipWrapper.js';
import chartDisplayHoverTooltip from './chartDisplayHoverTooltip.js';
import { singleLineAlertTicker } from './singleLineAlertTicker/singleLineAlertTicker.js';
import { alertsList } from './singleLineAlertTicker/alertsList.js';
import { chartDisplayValueHighlighterComponent } from './chartDisplayValueHighlighter/chartDisplayValueHighlighter.js';
import { chartLoadedEvent } from './chartLoadedEvent.js';
import { chartOverlay } from './chartOverlay.js';
import NoDataMessage from '../../../../app/charting/chartdisplay/NoDataMessage.js';
import { archivedMetricsWarningBar } from '../../../../common/ui/archived-metrics/ArchivedMetricsWarningBar';
import { archivedMetricsWarningIcon } from './archivedMetricsWarningIcon';
import { WarningIcon } from '../../../../common/ui/icons/WarningIcon';
import { VizTypeSwitcher } from '../../../../common/ui/dashboard/VizTypeSwitcher';
import { reminderNotificationSettings } from '../../../../common/ui/repeated-notifications/ReminderNotificationSettings';

import uiGrid from 'angular-ui-grid';

import './shared/module';
import './dyGraphUtils/dyGraphUtils.js';
import './dyGraphConfig/module';
import './chartSorter/module';
import './chartTooltip/module';
import './sparkline/sparkline.js';
import { react2angular } from 'react2angular';

angular
    .module('chartdisplay', [
        'chartbuilderUtil',
        'dyGraphUtils',
        'signalview.sparkline',
        'jqueryui',
        'signalview.timeservice',
        'signalview.urlOverrides',
        'sfx.filterControls',
        'signalview.instrumentation',
        'sfx.ui',
        'sfx.debug',
        'sfx.security',
        uiGrid,
        'signalview.dyGraphConfig',
        'signalview.chartdisplay.shared',
        'signalview.chartTooltip',
        'signalview.chartSorter',
        'signalview.common.tracing',
        'signalview.consts',
        'signalview.markdown',
        'signalview.themeService',
        'signalview.eventsPanel',
        'signalview.detector',
        'signalview.documentState',
        'signalview.superpowers',
        'signalview.heatmap',
        'signalview.utils',
        'signalview-utils.signalflowv2',
        'sfx.data',
        'signalview.streamDataProviders',
        'signalview.maps',
        'signalview.routeParameters',
    ])
    .component('chartDisplayDebounceOverlay', chartDisplayDebounceOverlay)
    .component('chartDisplayToo', chartDisplayToo)
    .component('chartDisplayOmega', chartDisplayOmega)
    .component('singleValueChart', singleValueChart)
    .component('listChart', listChart)
    .component('gaugeChart', gaugeChart)
    .component('textChart', textChart)
    .component('heatmapChart', heatmapChart)
    .component('newChartTypeTemplate', newChartTypeTemplate)
    .component('samplingAndRenderingIcons', samplingAndRenderingIcons)
    .component('archivedMetricsWarningIcon', archivedMetricsWarningIcon)
    .component('barChart', barChart)
    .component('mapChart', mapChart)
    .component('archivedMetricsWarningBar', archivedMetricsWarningBar)
    .component('warningIcon', react2angular(WarningIcon, []))
    .component('reminderNotificationSettings', reminderNotificationSettings)
    .component(
        'tableChartContainer',
        react2angular(
            TableChartContainer,
            [
                'chartModel',
                'themeKey',
                'openHref',
                'isPreview',
                'jobMessageSummary',
                'chartRollupMessage',
                'hideMissingValues',
                'maximumPrecision',
                'updateInterval',
                'useKmg2',
                'onNewDataProvider',
                'alertState',
                'inEditor',
                'onSortChanged',
                'useExportToCsv',
                'onGroupByChanged',
                'onLegendColumnConfigChanged',
                'onPlotChange',
                'onTimestampChanged',
                'chartHeight',
                'builderDisplayMode',
            ],
            ['CHART_CONSTANTS', 'INTERNAL_PROPERTY_DENYLIST', 'chartLoadedEvent']
        )
    )
    .component(
        'logsChartContainerWrapper',
        react2angular(
            LogsChartContainerWrapper,
            [
                'chartModel',
                'themeKey',
                'chartHeight',
                'setLinkUrl',
                'filters',
                'replaceOnlyFilters',
                'setLogsFilters',
                'setLogsIndexes',
                'setLogsTimeRange',
                'requestOverlayContainer',
                'removeOverlayContainer',
            ],
            [
                'auth',
                'urlOverridesService',
                'routeParameterService',
                'relatedContentService',
                'featureFlags',
                '$scope',
                'integrations',
            ]
        )
    )
    .component(
        'logsTimeSeriesChartContainerWrapper',
        react2angular(
            LogsTimeSeriesChartContainerWrapper,
            [
                'chartModel',
                'themeKey',
                'chartHeight',
                'chartWidth',
                'setLinkUrl',
                'filters',
                'replaceOnlyFilters',
                'setLogsFilters',
                'setLogsIndexes',
                'setLogsTimeRange',
                'requestOverlayContainer',
                'removeOverlayContainer',
            ],
            [
                'auth',
                'urlOverridesService',
                'routeParameterService',
                'relatedContentService',
                'featureFlags',
                '$scope',
                'integrations',
            ]
        )
    )
    .component(
        'filterChipWrapper',
        react2angular(filterChipWrapper, ['filterText'], ['themeService'])
    )
    .component('chartDisplayHoverTooltip', chartDisplayHoverTooltip)
    .component('singleLineAlertTicker', singleLineAlertTicker)
    .component('alertsList', alertsList)
    .component('chartDisplayValueHighlighter', chartDisplayValueHighlighterComponent)
    .component('chartNoDataMessage', react2angular(NoDataMessage))

    .constant('SAMPLE_CONSTANTS', SAMPLE_CONSTANTS)

    .directive('chartModeSwitcher', chartModeSwitcher)

    .component(
        'vizTypeSwitcher',
        react2angular(VizTypeSwitcher, [
            'chartType',
            'chartTypes',
            'changeChartType',
            'canUnselect',
        ])
    )
    .directive('chartDisplay', chartDisplay)
    .directive('detectorEventTable', detectorEventTable)
    .directive('heatmapChartLabel', heatmapChartLabel)
    .directive('resolutionPill', resolutionPill)
    .directive('jobRerunProgressDonut', jobRerunProgressDonut)
    .directive('chartTypeSwitcher', chartTypeSwitcher)
    .directive('chartOverlay', chartOverlay)

    .service('axisLabelFormatter', axisLabelFormatter)
    .service('chartDisplayCanvasRenderer', chartDisplayCanvasRenderer)
    .service('chartDisplayUtils', chartDisplayUtils)
    .service('chartEventDataSource', chartEventDataSource)
    .service('ChartPanningControl', ChartPanningControl)
    .service('chartVisualizationService', chartVisualizationService)
    .service('modelToStartEnd', modelToStartEnd)
    .service('ChartDisplayDebounceService', ChartDisplayDebounceService)
    .service('ChartExportService', ChartExportService)
    .service('chartLoadedEvent', chartLoadedEvent)
    .service('ChartNoDataService', ChartNoDataService)

    .factory('ThresholdCorrelator', ThresholdCorrelator)
    .factory('TypedCorrelationStore', TypedCorrelationStore)
    .factory('ChartDisplayOverlayRenderer', ChartDisplayOverlayRenderer);
