import Button from '@splunk/react-ui/Button';
import React from 'react';
import styled from 'styled-components';

const StyledChartPageHeaderActions = styled.div`
    display: flex;
`;

export type ChartPageHeaderActionsProps = {
    allowSave: () => boolean;
    canSaveToDashboard?: boolean;
    canSaveToParent: () => boolean;
    close: (forceDigest: boolean) => void;
    hasCreateChartCapability?: boolean;
    onTrackClick: (value: string) => void;
    save: (andClose: boolean) => void;
    saveAs: (andClose?: boolean) => void;
    showSaveAsOnly?: boolean;
};

export const ChartPageHeaderActions = ({
    allowSave,
    canSaveToDashboard,
    canSaveToParent,
    close,
    hasCreateChartCapability,
    onTrackClick,
    save,
    saveAs,
    showSaveAsOnly,
}: ChartPageHeaderActionsProps): React.JSX.Element => {
    return (
        <StyledChartPageHeaderActions>
            <Button
                className="btn btn-default"
                onClick={(): void => {
                    close(true);
                }}
                title="Close and go back"
            >
                Close
            </Button>

            {!showSaveAsOnly && (
                <Button
                    appearance="primary"
                    className={'btn btn-primary chart-save-button pull-right text-transform-none'}
                    disabled={!canSaveToParent() || !allowSave() || !hasCreateChartCapability}
                    onClick={(): void => {
                        save(true);
                        onTrackClick('chart-save-chart');
                    }}
                >
                    Save and close
                </Button>
            )}

            {showSaveAsOnly && (
                <Button
                    appearance="primary"
                    className="btn btn-primary pull-right chart-save-button text-transform-none"
                    disabled={!allowSave() || !hasCreateChartCapability}
                    onClick={(): void => {
                        saveAs(true);
                        onTrackClick('chart-save-as-chart');
                    }}
                >
                    Save as&hellip;
                </Button>
            )}

            {canSaveToDashboard && (
                <Button
                    appearance="primary"
                    className="btn btn-primary pull-right chart-save-button text-transform-none"
                    title="Save chart to a dashboard"
                    disabled={!allowSave() || !hasCreateChartCapability}
                    onClick={(): void => {
                        saveAs();
                        onTrackClick('chart-save-as-chart');
                    }}
                >
                    Save as&hellip;
                </Button>
            )}
        </StyledChartPageHeaderActions>
    );
};
