import React from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import Information from '@splunk/react-icons/Information';
import { CommonTooltip } from '../CommonTooltip';

type TooltipProps = {
    content: string;
};

const StyledInfoIcon = styled(Information)`
    color: ${variables.statusColorMedium};
`;

export const InfoTooltip: React.FC<TooltipProps> = ({ content }: TooltipProps) => (
    <CommonTooltip
        content={content}
        contentRelationship="label"
        component={<StyledInfoIcon tabIndex={0} />}
    ></CommonTooltip>
);
