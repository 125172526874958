import templateUrl from './detectorWizard.tpl.html';
import preflightInfoPanelTemplateUrl from '../preflightInfoPanel.tpl.html';
import {
    convertMSToString,
    convertStringToMS,
    safeLookup,
} from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { NO_CAPABILITIES_TOOLTIP } from '../../../common/data/rbac/common';

/**
 * Detector wizard directive shows a detector with a pre-selected rule (for edit or creation).
 * The detector
 *  itself might be a new model that needs to be created.
 * The wizard is divided into stages that allow picking a signal, alert condition, alert settings,
 * message/criticality settings and notification settings.
 * For a new rule, expectation is that user goes from one stage to the next in succession.
 * For an existing rule, user can move to any stage as long as it is selectable.
 * For example, user cannot select the alert condition or settings stages without having a signal selected.
 *
 * Params:
 * - detector (object): the detector
 * - rule (object): the detector rule
 * - isNewRule (boolean): indicating if it's a new rule (true) OR saved rule (false/undefined)
 * - preferences (object): the user perferences
 * - getSignalResolutionPromise (promise): promise for getting the plot signal resolution
 * - done (function): call back function when the detector is saved/updated
 * - cancel (function): call back function when the detector changes are not saved/updated
 * - detectorType (APM or INFRA): the detector type
 * - apmDetectorOptions (object): including the options used ONLY for apm type detector
 * - apmDetectorOptions:
 * - apmMetricType (ERRORS, LATENCY, WORKFLOW_ERRORS, WORKFLOW_LATENCY): the apm metric type
 * - serviceEndpointSelection (ServiceEndpointSelection): the service and endpoint(s) selection
 * - businessWorkflowSelection (BusinessWorkflowSelection): the business workflow(s) selection
 */
angular.module('signalview.detector.wizard').directive('detectorWizard', [
    '_',
    '$q',
    '$log',
    '$window',
    '$timeout',
    'APM_METRIC_TYPE',
    'CHART_DISPLAY_EVENTS',
    'DETECTOR_TYPES',
    'timeToRange',
    'programTextUtils',
    'chartbuilderUtil',
    'featureEnabled',
    'localStorage',
    'detectorUtils',
    'plotUtils',
    'notifyBlockService',
    'timepickerUtils',
    'confirmService',
    'ChartDisplayDebounceService',
    'ServiceEndpointSelection',
    'BusinessWorkflowSelection',
    'v2DetectorConverter',
    'SPLUNK_APM_PRODUCT_NAME',
    'detectorVersionService',
    'notificationsUtil',
    'signalTypeService',
    'hasCapability',
    function (
        _,
        $q,
        $log,
        $window,
        $timeout,
        APM_METRIC_TYPE,
        CHART_DISPLAY_EVENTS,
        DETECTOR_TYPES,
        timeToRange,
        programTextUtils,
        chartbuilderUtil,
        featureEnabled,
        localStorage,
        detectorUtils,
        plotUtils,
        notifyBlockService,
        timepickerUtils,
        confirmService,
        ChartDisplayDebounceService,
        ServiceEndpointSelection,
        BusinessWorkflowSelection,
        v2DetectorConverter,
        SPLUNK_APM_PRODUCT_NAME,
        detectorVersionService,
        notificationsUtil,
        signalTypeService,
        hasCapability
    ) {
        return {
            restrict: 'E',
            scope: {
                detector: '=',
                rule: '=',
                isNewRule: '=',
                preferences: '=',
                getSignalResolutionPromise: '=',
                done: '=',
                cancel: '=',
                detectorType: '<',
                detectorTypeRecommendationFailed: '<',
                apmDetectorOptions: '<',
                hasWritePermission: '<',
                orgSettings: '=',
            },
            templateUrl,
            link: {
                pre: function ($scope) {
                    $scope.model = $scope.detector;
                    $scope.chartDisplayDebouncer = new ChartDisplayDebounceService();
                    $scope.chartDisplayDebouncer.setEnabled(false);
                    $scope.isWizard = true;
                    $scope.isFlow2 = detectorUtils.isFlow2($scope.model);
                    $scope.compoundConditionsEnabled = $scope.isFlow2;
                    $scope.compoundSelected = false;
                    $scope.dimensions = [];
                    $scope.selectedDetectorType = null;
                },
                post: function ($scope) {
                    $scope.jobScope = { jobFeedback: [] };
                    $scope.preflightInfoPanelTemplateUrl = preflightInfoPanelTemplateUrl;

                    // fields on rule object that is supposed to be carried over regardless of whatever is updated in alert settings
                    const RULE_FIELDS = [
                        'invalid',
                        'name',
                        'notifications',
                        'severityLevel',
                        'uniqueKey',
                        'runbookUrl',
                        'tip',
                        'reminderNotification',
                    ];

                    $scope.archivedMetricsWarningEntity =
                        $scope.detector.id || $scope.detector.sf_id ? 'detector' : 'newDetector';
                    $scope.isReminderNotificationValid = detectorUtils.isReminderNotificationValid;

                    $scope.$on(
                        CHART_DISPLAY_EVENTS.ARCHIVED_METRICS_FOUND,
                        function (event, metrics) {
                            $scope.archivedMetrics = metrics;
                        }
                    );

                    $scope.apm2Enabled = featureEnabled('apm2');

                    const isApm2WorkflowsEnabled = featureEnabled('apm2Workflows');

                    // used for keeping the previous plots (before switching detector type)
                    let previousDetectorTypeCachedPlots = [];

                    // used for passing chart state across chart/signal components
                    $scope.sharedChartState = {};

                    hasCapability(Capability.UPDATE_DETECTOR).then(
                        (hasUpdateDetectorCapability) => {
                            $scope.hasUpdateDetectorCapability = hasUpdateDetectorCapability;
                        }
                    );

                    // condition that checks if the current detector is valid or if the current rule is not new
                    // this is used to determine if some stages are selectable
                    function isValidOrOldRule() {
                        return $scope.isValid || !$scope.isNewRule;
                    }

                    function alwaysTrue() {
                        return true;
                    }

                    function isVisited(index) {
                        return $scope.stages[index].visited;
                    }

                    // the various stages that are involved in the wizard flow
                    $scope.stages = [
                        {
                            key: 'signal',
                            name: 'Alert signal',
                            isEnabled: alwaysTrue,
                            summary: function () {
                                return $scope.signalSummary;
                            },
                            isCompleted: function (index) {
                                if (isApmDetector()) {
                                    return (
                                        hasCompletedSignalTypeSelection(
                                            $scope.selectedApmMetricType
                                        ) && isVisited(index)
                                    );
                                } else {
                                    return $scope.preSelectedSignal && isVisited(index);
                                }
                            },
                        },
                        {
                            key: 'alert',
                            name: 'Alert condition',
                            isEnabled: alwaysTrue,
                            summary: function () {
                                return $scope.categories[$scope.categoryShown].path.displayName;
                            },
                            isCompleted: function (index) {
                                return $scope.selectedFunc && isVisited(index);
                            },
                        },
                        {
                            key: 'settings',
                            name: 'Alert settings',
                            isEnabled: function () {
                                return $scope.selectedFunc && $scope.preSelectedSignal;
                            },
                            summary: function () {
                                return $scope.readable;
                            },
                            isCompleted: function (index) {
                                return $scope.isValid && isVisited(index);
                            },
                        },
                        {
                            key: 'message',
                            name: 'Alert message',
                            isEnabled: isValidOrOldRule,
                            isCompleted: isVisited,
                        },
                        {
                            key: 'recipients',
                            name: 'Alert recipients',
                            isEnabled: alwaysTrue,
                            summary: function () {
                                let notifications = $scope.rule.notifications;
                                if (
                                    notifications &&
                                    detectorVersionService.getInternalVersion($scope.detector) === 2
                                ) {
                                    // map notification rules to v1 notification list
                                    notifications =
                                        notificationsUtil.convertV2ListToV1(notifications);
                                }
                                return (
                                    (notifications || []).map(notifyBlockService).join(', ') ||
                                    'No recipients'
                                );
                            },
                            isCompleted: function (index) {
                                return (
                                    $scope.isReminderNotificationValid(
                                        $scope.rule.reminderNotification
                                    ) && isVisited(index)
                                );
                            },
                        },
                        {
                            key: 'activate',
                            name: 'Activate...',
                            isEnabled: alwaysTrue,
                        },
                    ];

                    $scope.stages.forEach((s) => (s.visited = !$scope.isNewRule));

                    const noPreflight = { signal: true, alert: true };

                    $scope.stageKeys = {};
                    $scope.stages.forEach(function (s, i) {
                        $scope.stageKeys[s.key] = i;
                    });

                    $scope.pinAfterLoad = function (time) {
                        $scope.$broadcast(
                            'chart pin after load',
                            time,
                            $scope.sharedChartState.currentTabId === 'event'
                        );
                    };

                    function getCurrentStageKey() {
                        return ($scope.stages[$scope.selectedStage] || {}).key;
                    }

                    // function that selects a passed in stage
                    $scope.selectStage = function (stageIndex) {
                        const previousStageKey = getCurrentStageKey();
                        if ($scope.selectedStage === stageIndex) {
                            return;
                        }
                        if (
                            $scope.stages[$scope.selectedStage] &&
                            $scope.stages[$scope.selectedStage].isEnabled()
                        ) {
                            $scope.stages[$scope.selectedStage].visited = true;
                        }

                        if (previousStageKey === 'message') {
                            $scope.customMessagesNeedsWarning = false;
                        }

                        $scope.selectedStage = stageIndex;
                        const stageKey = getCurrentStageKey();
                        $scope.isActivateStage = stageKey === 'activate';
                        if (
                            !noPreflight[stageKey] &&
                            (!previousStageKey || noPreflight[previousStageKey]) &&
                            !$scope.isPreflight
                        ) {
                            setPreviewRule();
                        }
                        $scope.$broadcast('detector wizard selected stage', stageKey);
                    };

                    function isPreflight() {
                        return !noPreflight[getCurrentStageKey()];
                    }
                    $scope.selectTabByKeydown = function ($event, index) {
                        if ($event) {
                            if ($event.keyCode === 40 && index <= $scope.stages.length - 2) {
                                document.getElementById(`${index + 2}-alertTab`).focus();
                            } else if ($event.keyCode === 38 && index > 0) {
                                document.getElementById(`${index}-alertTab`).focus();
                            } else if ($event.keyCode === 13) {
                                $scope.selectStage(index);
                            }
                        }
                    };
                    // advance to next stage in wizard flow
                    $scope.goToNextStage = function () {
                        const nextStage = $scope.selectedStage + 1;
                        $scope.selectStage(nextStage);
                    };

                    $scope.showSummary = function () {
                        $scope.selectStage($scope.stageKeys.activate);
                    };

                    function onPlotChanges() {
                        const uiModel = $scope.detector.sf_uiModel;
                        try {
                            const detectorPlotUniqueKey = plotUtils.getDetectorPlot(
                                $scope.detector
                            )?.uniqueKey;
                            const programOptionOverrides =
                                detectorVersionService.getInternalVersion($scope.detector) === 2
                                    ? {
                                          includeEvents: true,
                                          plotsToExclude:
                                              detectorPlotUniqueKey || detectorPlotUniqueKey === 0
                                                  ? [detectorPlotUniqueKey]
                                                  : [],
                                      }
                                    : {};
                            programTextUtils.refreshProgramText(
                                $scope.detector,
                                programOptionOverrides
                            );
                        } catch (e) {
                            $log.error('Failed refreshing program text.', e);
                        }
                        $scope.numPlots = detectorUtils.getNumMetricPlots(uiModel);
                        $scope.setPlotOptions();
                        $scope.generateSignalSummary();
                        setPreviewRule();
                    }

                    // when the signals component updates the list of plots
                    // we update them in the detector model and also that chart can be accordingly updated
                    $scope.onSignalUpdate = function (plots, uniqueKey) {
                        $scope.hasUnsavedChanges = true;
                        const uiModel = $scope.detector.sf_uiModel;
                        uiModel.allPlots.splice.apply(
                            uiModel.allPlots,
                            [0, uiModel.allPlots.length].concat(plots)
                        );
                        uiModel.currentUniqueKey = uniqueKey;
                        onPlotChanges();
                    };

                    // when user picks a target signal to monitor, we update the
                    // preSelectedSignal variable and also set the default chart resolution for display
                    // since signal might be of a coarse or fine resolution and we need to show enough data in the chart
                    $scope.preSelectedPlot = null;
                    $scope.onSignalSelect = function (plot) {
                        $scope.preSelectedPlot = plot;
                        const plotLetter = plotUtils.getLetterFromUniqueKey(plot.uniqueKey);
                        $scope.setPreSelectedSignal(plotLetter);
                        onPlotChanges();
                    };

                    $scope.onConfigChange = function (config, maxDelay, timezone) {
                        $scope.hasUnsavedChanges = true;
                        if (config) {
                            angular.extend($scope.detector.sf_uiModel.chartconfig, config);
                        }
                        if (angular.isDefined(maxDelay)) {
                            $scope.detector.sf_jobMaxDelay = maxDelay;
                        }
                        if (angular.isDefined(timezone)) {
                            $scope.detector.sf_timezone = timezone;
                        }
                    };

                    // when the message component has values modified, this function callback sets the values
                    // in the detector model
                    $scope.onMessageSelect = function (
                        severityLevel,
                        runbookUrl,
                        tip,
                        messageSubject,
                        messageBody
                    ) {
                        $scope.rule.severityLevel = severityLevel;
                        $scope.rule.runbookUrl = runbookUrl || '';
                        $scope.rule.tip = tip || '';
                        if (messageSubject) {
                            $scope.rule.parameterizedSubject = messageSubject;
                        }
                        if (messageBody) {
                            $scope.rule.parameterized = messageBody;
                        }
                    };

                    $scope.clearMessage = function (clearMessageBody, clearMessageSubject) {
                        if (clearMessageBody) {
                            $scope.rule.parameterized = '';
                        }

                        if (clearMessageSubject) {
                            $scope.rule.parameterizedSubject = '';
                        }
                    };

                    // when the recipients component has notification settings modified, this function callback sets the values
                    // in the detector model
                    $scope.onRecipientsSelect = function (recipients) {
                        $scope.rule.notifications = angular.copy(recipients);
                    };

                    // when in the signal component advanced view, user can select tabs such as plot options, chart options or data table.
                    // this value needs to be updated in the shared chart state so the chart component can do the right thing
                    $scope.onSelectLegendTab = function (tab) {
                        const tabId = (tab || {}).id;
                        $scope.sharedChartState.currentTabId = tabId;
                        $scope.hideLegend = !(tabId === 'data' || tabId === 'event');
                        if (tabId === 'data') {
                            $scope.$broadcast(CHART_DISPLAY_EVENTS.LEGEND_TAB_SELECTED, 'data');
                        } else if (tabId === 'event') {
                            $scope.$broadcast(CHART_DISPLAY_EVENTS.LEGEND_TAB_SELECTED, 'event');
                        }
                    };

                    // When we get job feedback messages from the chart, we need to send this
                    // message to other components so they may display information such as plot resolution, etc.
                    $scope.processJobMessages = function (messages) {
                        // If we are importing any analytics modules, we'll get a line offset due to import statement.
                        const plotIndexToLineOffset = isPreflight() && $scope.rule.module ? 1 : 0;
                        const plotKeyToInfoMap = chartbuilderUtil.processJobMessages(
                            $scope.detector.sf_uiModel.allPlots,
                            messages,
                            plotIndexToLineOffset
                        );
                        $scope.throttled = chartbuilderUtil.processJobThrottle(
                            plotKeyToInfoMap,
                            $scope.detector
                        );
                        $scope.$broadcast('plotTimeSeriesData', plotKeyToInfoMap);

                        if (messages && messages.length) {
                            $scope.fetchChartData();
                        }
                    };

                    $scope.$watch('jobScope.jobFeedback', $scope.processJobMessages);

                    $scope.getDisabledActivationTooltipText = function () {
                        if (!$scope.hasUpdateDetectorCapability) {
                            return NO_CAPABILITIES_TOOLTIP;
                        } else if (!$scope.validPlots) {
                            return 'There are invalid plots.';
                        } else if (!$scope.hasWritePermission) {
                            return "You cannot update the alert rule because you don't have write permission for this detector.";
                        } else {
                            return '';
                        }
                    };

                    $scope.closeWizard = function () {
                        if (!$scope.hasUnsavedChanges) {
                            $scope.cancel();
                        } else {
                            const confirmModal = confirmService.confirm({
                                title: 'Discard Changes',
                                text: [
                                    'There are unsaved changes.',
                                    'Are you sure you want to discard these changes?',
                                    "To save this rule, see the 'Activate' tab.",
                                ],
                                yesText: 'Yes discard the changes',
                                noText: 'Cancel',
                                danger: false,
                            });
                            confirmModal.then((yesDiscard) => {
                                if (yesDiscard) {
                                    $scope.cancel();
                                }
                            });
                        }
                    };

                    function checkDisabledRule() {
                        if (!$scope.rule.disabled) {
                            return $q.when();
                        } else {
                            const confirmModal = confirmService.confirm({
                                title: 'Enable Rule',
                                text: [
                                    "This rule '" + $scope.rule.name + "' is currently disabled.",
                                    'Would you like to enable it?',
                                ],
                                yesText: 'Enable and Save',
                                noText: 'Keep disabled and Save',
                                danger: false,
                            });
                            return confirmModal.then((yesEnable) => {
                                if (yesEnable) {
                                    $scope.rule.disabled = false;
                                }
                            });
                        }
                    }

                    function saveDetector() {
                        $scope.saving = true;

                        const isLegacyDetectorModel =
                            !$scope.detector.sf_id && !$scope.detector.sf_modelVersion;
                        return (
                            isLegacyDetectorModel
                                ? v2DetectorConverter.saveV1AsV2($scope.detector)
                                : detectorUtils.saveDetector($scope.detector)
                        )
                            .then($scope.done, function (err) {
                                $log.error('Failed saving detector.', err);
                                const errorMessage = safeLookup(err, 'data.message');
                                $window.alert(
                                    'There was an error saving this detector rule. \n' +
                                        errorMessage
                                );
                                throw new Error(err);
                            })
                            .finally(function () {
                                $scope.saving = false;
                            });
                    }

                    // when the rule is activated - create or update, we save the detector
                    // and pass control back to the parent widget that has this directive instantiated.
                    // for a new detector, we show a modal prompt for detector name
                    $scope.activate = function () {
                        $scope.rule.readable = $scope.readable;

                        if (!$scope.rule.parameterized) {
                            const parameterized =
                                detectorUtils.getAutoDetectorRuleParameterizedString(
                                    $scope.rule,
                                    $scope.plots
                                );
                            $scope.rule.parameterized = parameterized.replace(
                                /{{\s*timestamp\s*}}/g,
                                '{{dateTimeFormat timestamp format="full"}}'
                            );
                        }

                        // swap rule in-place so we don't mess up ordering
                        $scope.detector.sf_uiModel.rules.some(function (rule, index) {
                            if (rule.uniqueKey === $scope.rule.uniqueKey) {
                                $scope.detector.sf_uiModel.rules[index] = $scope.rule;
                            }
                        });

                        if (isApmDetector() && $scope.apmDetectorOptions) {
                            // for apm detector, if it's created from flow (other than alert page - 'Create Detector' button, ex services page)
                            // we want to use the rule name as the detector name
                            $scope.detector.sf_detector = $scope.rule.name;
                        }

                        checkDisabledRule()
                            .then(() =>
                                detectorUtils.showConfirmNoNotifications($scope.detector.sf_uiModel)
                            )
                            .then(() => saveDetector())
                            .catch(function (nameErr) {
                                $log.warn('Detector name modal did not complete.', nameErr);
                            });
                    };

                    // this function is used to generate a summary for picked signal
                    // showing signal name and filters applied
                    function getSignalNameAndFilters() {
                        const key = plotUtils.getUniqueKeyFromLetter($scope.preSelectedSignal);
                        const plot = $scope.plots.filter(function (p) {
                            return p.uniqueKey === key;
                        })[0];
                        if (!plot) return '';

                        const filters = (plot.queryItems || [])
                            .map(function (f) {
                                return (
                                    (f.NOT ? '!' : '') +
                                    f.property +
                                    ':' +
                                    (f.propertyValue || '').toString()
                                );
                            })
                            .join(', ');
                        return plot.name + (filters ? ', ' + filters : '');
                    }

                    $scope.generateSignalSummary = function () {
                        $scope.numSources = 0;
                        if (!$scope.preSelectedSignal) {
                            return;
                        }
                        $scope.signalSummary = getSignalNameAndFilters();
                    };

                    // when settings component updates sensitivity, we call the shared controller's update settings functions
                    $scope.updateSensitivity = function (sensitivity) {
                        $scope.sensitivityModel.value = sensitivity;
                        $scope.sensitivityChanges();
                    };

                    function getPlotLetter(plot) {
                        return chartbuilderUtil.getLetterFromUniqueKey(plot.uniqueKey);
                    }

                    function updateSelection(initial) {
                        if (!initial) {
                            $scope.stages
                                .filter((s) => s.key === 'settings')
                                .forEach((s) => (s.visited = false));
                        }

                        if (typeof $scope.selection.category !== 'undefined') {
                            $scope.categoryShown = $scope.selection.category;
                        }

                        if (typeof $scope.selection.func !== 'undefined') {
                            selectFunc(initial);
                        }
                    }

                    $scope.preSelectedSignal = null;

                    $scope.categoryMouseEnter = function (index) {
                        if (
                            typeof $scope.selection.category === 'undefined' &&
                            $scope.categoryShown !== index
                        ) {
                            $scope.categoryShown = index;
                            selectFunc();
                            formatReadableRuleString();
                        }
                    };

                    $scope.categoryClick = function (index, $event) {
                        if ($scope.selection.category !== index) {
                            $event.stopPropagation();
                            $scope.selection = {
                                category: index,
                                func: 0,
                            };
                            $scope.customMessagesNeedsWarning = $scope.rule.isCustomizedMessage;
                            updateSelection();
                            clearWatchOnRule();
                            setWatchOnRule();
                        }
                    };

                    function populateScaledResolution() {
                        // populate a temporary assoc array of parameter name to preferred scaled resolutions
                        // keep scaling semantics in here alone
                        const jobResolution = $scope.jobInfo.resolution;
                        if (!$scope.selectedFunc || !$scope.selectedFunc.sensitivity) {
                            return;
                        }
                        $scope.selectedFunc.sensitivity.options.forEach(function (sen) {
                            if (sen.resolutionConstraint) {
                                angular.forEach(sen.resolutionConstraint, function (value, key) {
                                    const multiple = value.minDefaultMultiple;
                                    const scaledResolution = jobResolution * multiple;
                                    const objValue = sen.inputs[key];
                                    const isDurationProperty = objValue.hasOwnProperty('duration');
                                    const objMsValue = convertStringToMS(
                                        isDurationProperty ? objValue.duration : objValue
                                    );
                                    const preferredMsValue = Math.max(objMsValue, scaledResolution);
                                    const preferredValueString =
                                        convertMSToString(preferredMsValue);
                                    if (!sen.scaledResolution) {
                                        sen.scaledResolution = {};
                                    }
                                    sen.scaledResolution[key] = angular.copy(sen.inputs[key]);
                                    if (isDurationProperty) {
                                        sen.scaledResolution[key].duration = preferredValueString;
                                    } else {
                                        sen.scaledResolution[key] = preferredValueString;
                                    }
                                });
                            }
                        });
                    }

                    function setDurations() {
                        if (!$scope.selectedFunc) {
                            return;
                        }
                        populateScaledResolution();
                        if (!$scope.sensitivityModel) {
                            let currentMatch = null;
                            if ($scope.selectedFunc.sensitivity && $scope.rule) {
                                $scope.selectedFunc.sensitivity.options.some(function (sen) {
                                    if (
                                        isMatch(
                                            $scope.rule.inputs,
                                            sen.inputs,
                                            sen.scaledResolution || {},
                                            sen.resolutionConstraint || {}
                                        )
                                    ) {
                                        currentMatch = sen.name;
                                    } else {
                                        return false;
                                    }
                                });
                            }
                            if (currentMatch) {
                                setSensitivityModelName(currentMatch);
                            }
                        }
                        if ($scope.sensitivityModel && $scope.selectedFunc.sensitivity) {
                            $scope.sensitivityChanges();
                        }
                    }

                    function setSensitivityModelName(name) {
                        $scope.sensitivityModel = { value: name };
                        if (name === 'custom') {
                            $scope.expand = true;
                        }
                    }

                    function assignSensitivityModel() {
                        if (
                            ($scope.sensitivityModel &&
                                $scope.sensitivityModel.value === 'custom') ||
                            !$scope.jobInfo
                        ) {
                            return;
                        }
                        if ($scope.selectedFunc && $scope.selectedFunc.sensitivity && $scope.rule) {
                            if (
                                !$scope.selectedFunc.sensitivity.options.some(function (sen) {
                                    if (
                                        isMatch(
                                            $scope.rule.inputs,
                                            sen.inputs,
                                            sen.scaledResolution || {},
                                            sen.resolutionConstraint || {}
                                        )
                                    ) {
                                        setSensitivityModelName(sen.name);
                                        return true;
                                    } else {
                                        return false;
                                    }
                                })
                            ) {
                                setSensitivityModelName('custom');
                            }
                        }
                    }

                    $scope.categoryContainerClicked = function () {
                        // if container is clicked, clear all the selection
                        $scope.selection = {};
                        $scope.categoryShown = null;
                        $scope.selectedFunc = null;

                        $scope.rule = {};
                        clearWatchOnRule();
                    };

                    function getFirstVisible(plots) {
                        let firstVisible = null;
                        if (
                            plots.some(function (plot) {
                                if (!plot.transient && !plot.invisible && plot.type !== 'event') {
                                    firstVisible = plot;
                                    return true;
                                } else {
                                    return false;
                                }
                            })
                        ) {
                            return firstVisible;
                        } else {
                            return plots[0];
                        }
                    }

                    let inputValid = {};
                    const inputCache = {}; // input cache to quickly restore the inputs when they switch back to it
                    function selectFunc(initial) {
                        const oldSelection = $scope.selectedFunc || {};
                        const oldModule = oldSelection.module || {};
                        const oldFunc = oldSelection.function;
                        const oldModulePath = (oldModule.path || '') + ' ' + (oldModule.name || '');
                        let oldFuncPath;
                        if (oldFunc) {
                            oldFuncPath = oldModulePath + ' ' + oldFunc.name;
                        }
                        $scope.selectedFunc = angular.copy(
                            $scope.categories[$scope.categoryShown].functions[
                                $scope.selection.func || 0
                            ]
                        );
                        const newModule = ($scope.selectedFunc || {}).module || {};
                        const newModulePath = (newModule.path || '') + ' ' + (newModule.name || '');
                        let newFuncPath;
                        if ($scope.selectedFunc.function) {
                            newFuncPath = newModulePath + ' ' + $scope.selectedFunc.function.name;
                        }
                        const isSameModulePath = oldModulePath === newModulePath;
                        const isSameFunction =
                            isSameModulePath &&
                            angular.equals(oldFunc, $scope.selectedFunc.function);
                        const inputs = $scope.selectedFunc.inputs;
                        if (inputs) {
                            $scope.selectedFunc.sortedInputKeys = Object.keys(inputs).sort(
                                function (a, b) {
                                    const valA = inputs[a];
                                    const valB = inputs[b];
                                    const priorityA = angular.isDefined(valA.priority)
                                        ? valA.priority
                                        : Number.MAX_SAFE_INTEGER;
                                    const priorityB = angular.isDefined(valB.priority)
                                        ? valB.priority
                                        : Number.MAX_SAFE_INTEGER;
                                    return priorityA - priorityB;
                                }
                            );

                            angular.forEach(inputs, function (val) {
                                if (val.dataType.type === 'Stream') {
                                    // assign data type values for all plots to stream input
                                    val.dataType.values = PLOT_OPTIONS;
                                }
                            });
                        }
                        if (oldFuncPath) {
                            inputCache[oldFuncPath] = angular.copy($scope.rule);
                        }

                        if (!isSameModulePath) {
                            $scope.expand = false;
                            $scope.sensitivityModel = {};
                        }

                        if (!initial && $scope.selectedFunc) {
                            const originalFields = {};
                            RULE_FIELDS.forEach(function (propertyName) {
                                if (angular.isDefined($scope.rule[propertyName])) {
                                    originalFields[propertyName] = $scope.rule[propertyName];
                                }
                            });

                            // if it's not initial selection meaning user has select a new function,
                            // initialize it with default value.
                            if (
                                $scope.selectedFunc.function.type === 'static' ||
                                $scope.selectedFunc.function.type === 'dynamic'
                            ) {
                                if (!$scope.rule.thresholdMode) {
                                    // if current rule doesn't have threshold mode, meaning it's empty or a function, the overwrite it
                                    $scope.rule = {
                                        // use pre-selected signal if available, instead of default first visible signal
                                        targetPlot:
                                            $scope.preSelectedSignal ||
                                            ($scope.plots.length
                                                ? getPlotLetter(getFirstVisible($scope.plots))
                                                : null),
                                        jobResolution: '1 second',
                                        showThreshold: true,
                                        parameterized: $scope.rule.parameterized,
                                        parameterizedSubject: $scope.rule.parameterizedSubject,
                                        isCustomizedMessage: $scope.rule.isCustomizedMessage,
                                    };
                                    if (
                                        $scope.compoundConditionsEnabled &&
                                        $scope.selectedFunc.function.type === 'dynamic'
                                    ) {
                                        $scope.rule.conditions = [
                                            {
                                                thresholdMode: 'above',
                                                triggerMode: 'immediately',
                                                targetPlot: $scope.rule.targetPlot,
                                            },
                                        ];
                                    } else {
                                        $scope.rule.thresholdMode = 'above';
                                        $scope.rule.triggerMode = 'immediately';
                                    }

                                    $scope.isValid = false;
                                } else if (
                                    $scope.selectedFunc.function.type === 'static' &&
                                    detectorUtils.hasDynamicThreshold($scope.rule)
                                ) {
                                    delete $scope.rule.above;
                                    delete $scope.rule.below;
                                    $scope.isValid = false;
                                } else if (
                                    $scope.compoundConditionsEnabled &&
                                    $scope.selectedFunc.function.type === 'dynamic' &&
                                    !detectorUtils.hasCompoundConditions($scope.rule)
                                ) {
                                    // go to transformDynamicRuleToCompoundConditions for more information
                                    // transforming current rule to compound conditions format
                                    $scope.rule.conditions =
                                        transformDynamicRuleToCompoundConditions();
                                }

                                // Set the auto clear alerts value to the org default only if it hasn't been set previously
                                if (
                                    $scope.orgSettings.sf_alertAutoClearDuration &&
                                    !$scope.rule.autoResolveAfter
                                ) {
                                    $scope.rule.autoResolveAfter =
                                        $scope.orgSettings.sf_alertAutoClearDuration;
                                }
                            } else {
                                let cachedValues = {};
                                if (isSameModulePath) {
                                    if ($scope.sensitivityModel.value === 'custom') {
                                        cachedValues = angular.copy($scope.rule.inputs);
                                    } else if ($scope.selectedFunc.sensitivity) {
                                        // not all functions have sensitivities
                                        $scope.selectedFunc.sensitivity.options.some(function (
                                            sen
                                        ) {
                                            if (sen.name === $scope.sensitivityModel.value) {
                                                angular.extend(cachedValues, sen.inputs);
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        });
                                    }
                                } else if (newFuncPath && inputCache[newFuncPath]) {
                                    cachedValues = inputCache[newFuncPath].inputs;
                                }
                                $scope.rule = {
                                    package: $scope.selectedFunc.package,
                                    path: $scope.categories[$scope.categoryShown].path.name,
                                    module: $scope.selectedFunc.module.name,
                                    moduleImportAlias: $scope.selectedFunc.module.moduleImportAlias,
                                    function: $scope.selectedFunc.function.name,
                                    version: $scope.selectedFunc.version,
                                    inputs: {},
                                    type: 'Function',
                                    showThreshold: $scope.selectedFunc.showThreshold,
                                    parameterized: $scope.rule.parameterized,
                                    parameterizedSubject: $scope.rule.parameterizedSubject,
                                    isCustomizedMessage: $scope.rule.isCustomizedMessage,
                                    apmEnvironmentSelection: $scope.rule.apmEnvironmentSelection,
                                    apmFilters: $scope.rule.apmFilters,
                                    apmServiceEndpointSelections:
                                        $scope.rule.apmServiceEndpointSelections,
                                    apmBusinessWorkflowSelections:
                                        $scope.rule.apmBusinessWorkflowSelections,
                                    reminderNotification: $scope.rule.reminderNotification,
                                };

                                if (!isSameFunction) {
                                    inputValid = {};
                                }
                                angular.forEach($scope.selectedFunc.inputs, function (val, key) {
                                    const isStream = val.dataType.type === 'Stream';
                                    // use pre-selected signal if available, instead of default first visible signal or cached value
                                    if ($scope.preSelectedSignal && isStream) {
                                        $scope.rule.inputs[key] = $scope.preSelectedSignal;
                                        inputValid[key] = true;
                                    } else {
                                        if (angular.isDefined(cachedValues[key])) {
                                            $scope.rule.inputs[key] = cachedValues[key];
                                        } else if (val.dataType.type === 'Stream') {
                                            $scope.rule.inputs[key] = PLOT_OPTIONS.length
                                                ? getFirstVisible(PLOT_OPTIONS).label
                                                : null;
                                        } else if (val.optional) {
                                            // Optional values do not require being set so don't preset them at function selection time

                                            // If the optional parameter is the auto clear, do set it if there is an org wide default
                                            if (
                                                $scope.orgSettings.sf_alertAutoClearDuration &&
                                                val.dataType.type === 'AutoResolveAfter'
                                            ) {
                                                $scope.rule.inputs[key] =
                                                    $scope.orgSettings.sf_alertAutoClearDuration;
                                            }
                                        } else {
                                            $scope.rule.inputs[key] = val.defaultValue;
                                            // once default value is used we're sure it's valid
                                            inputValid[key] = true;
                                        }
                                    }
                                });
                            }
                            // copy values from original rule prior to changing function/input-settings
                            angular.extend($scope.rule, originalFields);
                        }

                        if ($scope.selectedFunc) {
                            // function selector
                            $scope.functionSelector = {
                                dataType: {
                                    type: 'String',
                                    values: $scope.categories[$scope.categoryShown].functions.map(
                                        function (f) {
                                            return {
                                                displayName: f.function.displayName,
                                                value: f.function.name,
                                            };
                                        }
                                    ),
                                },
                                defaultValue: $scope.selectedFunc.function.name,
                            };

                            // copy description, displayName, prompt, aboveTheFold, dataType, etc.
                            const tooltip = $scope.categories[$scope.categoryShown].tooltip || {};
                            $scope.functionSelector.description = tooltip.description;
                            $scope.functionSelector.displayName = tooltip.displayName;
                            $scope.functionSelector.aboveTheFold = tooltip.aboveTheFold;
                            $scope.functionSelector.prompt = tooltip.prompt;
                            if (tooltip.dataType && tooltip.dataType.select) {
                                $scope.functionSelector.dataType.select = tooltip.dataType.select;
                            }
                        }

                        $scope.hasMoreOptions = false;
                        if ($scope.selectedFunc && $scope.selectedFunc.inputs) {
                            if (initial) {
                                // assume that all inputs are valid if opening the modal from selected input
                                angular.forEach($scope.selectedFunc.inputs, function (val, key) {
                                    inputValid[key] = true;
                                });
                            }
                            angular.forEach($scope.selectedFunc.inputs, function (val) {
                                if (val.aboveTheFold === false) {
                                    $scope.hasMoreOptions = true;
                                }
                            });

                            // update validity since we might be coming here from a hover to selection state
                            // and inputs will be the same and won't retrigger input validation
                            // if we have a pre-selected signal, don't wait for metadata based input to set value since it may
                            // not be active in the view
                            if (isSameFunction || $scope.preSelectedSignal) {
                                updateValidity();
                                setJobInfo();
                            }
                        }
                    }

                    $scope.updateFunctionSelector = function (value) {
                        if (
                            typeof $scope.categoryShown !== 'undefined' &&
                            typeof $scope.selection.category === 'undefined'
                        ) {
                            // hover mode, ignore
                            return;
                        }
                        const functions = $scope.categories[$scope.categoryShown].functions;
                        for (let i = 0; i < functions.length; i++) {
                            if (functions[i].function.name === value) {
                                if ($scope.selection.func !== i) {
                                    // could already set by clicking on the category
                                    $scope.selection.func = i;
                                    selectFunc();
                                    setWatchOnRule();
                                }
                                return;
                            }
                        }
                    };

                    $scope.functionClick = function (categoryIndex, functionIndex) {
                        if (
                            $scope.selection.category === categoryIndex &&
                            $scope.selection.func === functionIndex
                        ) {
                            return;
                        }
                        $scope.selection = {
                            category: categoryIndex,
                            func: functionIndex,
                        };
                        selectFunc();
                    };

                    let PLOT_OPTIONS;

                    $scope.setPlotOptions = function () {
                        $scope.plots = $scope.detector.sf_uiModel.allPlots;
                        $scope.numPlots = $scope.plots.length;
                        PLOT_OPTIONS = $scope.plots
                            .filter(function (plot) {
                                return !plot.transient && plot.type !== 'event';
                            })
                            .map(function (plot) {
                                const plotLetter = getPlotLetter(plot);
                                return {
                                    label: plotLetter,
                                    name: plot.name,
                                    type: plot.type,
                                    invisible: plot.invisible,
                                    valid: plot.valid,
                                };
                            });
                        $scope.validPlots = plotUtils.hasValidPlots($scope.detector);
                    };

                    // select the initial stage
                    function selectInitialStage() {
                        let initialStage;

                        if ($scope.isNewRule) {
                            // select 'signal' selection stage as initial stage
                            initialStage = $scope.stageKeys.signal;
                        } else {
                            initialStage = $scope.stageKeys.activate;
                        }

                        $scope.selectStage(initialStage);
                    }

                    function init() {
                        $scope.selectedFunc = null;
                        // assume that when modal open, plots don't change.
                        // That may no longer be true if we allow changing plots while keeping the modal open.
                        $scope.setPlotOptions();
                        $scope.categories = detectorUtils.getAllCategories();
                        // we need to store index of dynamic threshold function in a variable to check against in the markup
                        // the code below is meant to handle any functions order so it gets the right index
                        $scope.categories.some(function (category, index) {
                            if (
                                (category.functions || []).some(function (fn) {
                                    return (fn.function || {}).type === 'dynamic';
                                })
                            ) {
                                $scope.legacyDynamicCategory = index;
                                return true;
                            } else {
                                return false;
                            }
                        });

                        // determine what is currently selected
                        // assuming that they're all using signalfx right now
                        $scope.selection = {};
                        if ($scope.rule.type === 'Function') {
                            const currentPath = $scope.rule.path;
                            const currentFunction = $scope.rule['function'];

                            $scope.categories.some(function (category, index) {
                                if (category.path.name === currentPath) {
                                    $scope.selection.category = index;
                                    return category.functions.some(function (func, index) {
                                        if (func.function.name === currentFunction) {
                                            $scope.selection.func = index;
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    });
                                }
                                return false;
                            });
                            updateSelection(true);
                        } else if (detectorUtils.hasThresholdMode($scope.rule)) {
                            if (detectorUtils.hasDynamicThreshold($scope.rule)) {
                                // if there's any signal as threshold
                                // assume dynamic is the last category
                                $scope.selection = {
                                    category: $scope.categories.length - 1,
                                    func: 0,
                                };
                                if (
                                    $scope.compoundConditionsEnabled &&
                                    !detectorUtils.hasCompoundConditions($scope.rule)
                                ) {
                                    $scope.rule.conditions =
                                        transformDynamicRuleToCompoundConditions();
                                }
                            } else {
                                // assume static is the first one
                                $scope.selection = {
                                    category: 0,
                                    func: 0,
                                };
                            }
                            updateSelection(true);
                        }
                        $scope.categoryShown = $scope.selection.category || 0;

                        selectInitialStage();

                        initDetectorType();

                        if (isApmDetector()) {
                            initApmDetector();
                        }
                        if ($scope.apm2Enabled && $scope.isNewRule) {
                            initPreviousDetectorTypeCachedPlots();
                        }
                    }

                    $scope.initializeWithRule = function (rule, preSelectedSignal) {
                        $scope.rule = rule;
                        $scope.preSelectedSignal = preSelectedSignal;
                        init();
                        setWatchOnRule();
                        if (rule.type !== 'Function' && preSelectedSignal) {
                            if (
                                $scope.compoundConditionsEnabled &&
                                (detectorUtils.hasCompoundConditions(rule) ||
                                    $scope.selectedFunc.function.type === 'dynamic')
                            ) {
                                $scope.isValid =
                                    detectorUtils.validateCompoundConditions(
                                        $scope.rule,
                                        $scope.plots
                                    ) === undefined;
                            } else {
                                $scope.isValid =
                                    detectorUtils.validateLegacyCondition(
                                        $scope.rule,
                                        $scope.plots
                                    ) === undefined;
                            }
                        }
                    };

                    function setJobInfo(prop, value) {
                        if (!prop) {
                            const inputs = ($scope.selectedFunc || {}).inputs || {};
                            prop = Object.keys(inputs).filter(function (field) {
                                const val = inputs[field];
                                return val && val.dataType && val.dataType.type === 'Stream';
                            })[0];
                            if (prop && $scope.rule.inputs && $scope.rule.inputs[prop]) {
                                value = $scope.rule.inputs[prop];
                            } else {
                                return;
                            }
                        }
                        $scope.estimatingResolution = true;
                        getJobResolutionForPlot(value)
                            .then(function (response) {
                                if (!$scope.jobInfo) {
                                    $scope.jobInfo = response;
                                } else {
                                    delete $scope.jobInfo.success;
                                    angular.extend($scope.jobInfo, response);
                                }
                                $scope.jobInfo.lastPlot = value;
                                // adjust durations to match resolution, as needed
                                setDurations();
                                assignSensitivityModel();
                            })
                            .finally(function () {
                                $scope.estimatingResolution = false;
                            });
                    }

                    let functionInputValidationTimeout = null;
                    let functionInputValidationRequestCount = 0;

                    function transformDynamicRuleToCompoundConditions() {
                        // if dynamic threshold is selected and compound conditions is enabled
                        // but rule doesn't have compound conditions (meaning it's a detector that was saved previously),
                        // transform the current dynamic threshold rule to compound condition format.
                        // Go through all the fields that are possible in a rule,
                        // if the rule contains the field, put into the new condition
                        // and put the new condition object into the conditions field in $scope.rule
                        const fields = [
                            'thresholdMode',
                            'triggerMode',
                            'above',
                            'below',
                            'duration',
                            'percentOfDuration',
                        ];
                        const newCondition = {};
                        fields.map((field) => {
                            if ($scope.rule[field]) {
                                newCondition[field] = $scope.rule[field];
                                delete $scope.rule[field];
                            }
                        });
                        newCondition.targetPlot = $scope.rule.targetPlot;
                        return [newCondition];
                    }

                    function updateValidity() {
                        $timeout.cancel(functionInputValidationTimeout);
                        $scope.functionInputError = null;
                        $scope.isValid = false;
                        let newValid;
                        if ($scope.selectedFunc.inputs) {
                            newValid = Object.keys($scope.selectedFunc.inputs).every(function (
                                field
                            ) {
                                // If it is optional and it doesn't have a validation value, assume it is not included
                                if (
                                    $scope.selectedFunc.inputs[field].optional &&
                                    angular.isUndefined(inputValid[field])
                                ) {
                                    return true;
                                }
                                return inputValid[field];
                            });
                        }
                        const compoundConditionsExists = detectorUtils.hasCompoundConditions(
                            $scope.rule
                        );
                        if (newValid || compoundConditionsExists) {
                            if (
                                !$scope.rule ||
                                ($scope.rule.type !== 'Function' && !compoundConditionsExists) ||
                                angular.isUndefined($scope.selection.category) ||
                                (!$scope.jobInfo && !compoundConditionsExists)
                            ) {
                                $scope.isValid = true;
                                return;
                            }

                            if (
                                compoundConditionsExists &&
                                detectorUtils.validateCompoundConditions($scope.rule, $scope.plots)
                            ) {
                                return;
                            }
                            functionInputValidationTimeout = $timeout(function () {
                                functionInputValidationRequestCount++;
                                const currentRequest = functionInputValidationRequestCount;
                                const rule = angular.copy($scope.rule);
                                // at the time of creation or save, could have been invalid
                                // mark it as not invalid for validation
                                // also set dummy rule name so there's a detect block
                                rule.invalid = false;
                                if (!rule.name) {
                                    rule.name = 'Rule at ' + Date.now();
                                }
                                detectorUtils
                                    .validateFunctionInput(
                                        $scope.plots,
                                        rule,
                                        $scope.selectedFunc.inputs
                                    )
                                    .then(
                                        function () {
                                            if (
                                                currentRequest ===
                                                functionInputValidationRequestCount
                                            ) {
                                                $scope.isValid = true;
                                            }
                                        },
                                        function (e) {
                                            if (
                                                currentRequest ===
                                                    functionInputValidationRequestCount &&
                                                (e.error || (e.keys && e.keys.length))
                                            ) {
                                                $scope.functionInputError = e;
                                                $log.error('Failed function validation.', e);
                                            }
                                        }
                                    );
                            }, 500);
                        }
                    }

                    // In UX where signal is pre-selected before alert settings,
                    // we want to capture the selected signal and find the job resolution
                    // in some cases the rule stream needs to be set as well because the settings view may not be active
                    // to capture change
                    $scope.setPreSelectedSignal = function (plotLetter) {
                        $scope.preSelectedSignal = plotLetter;
                        if ($scope.rule) {
                            if ($scope.rule.inputs) {
                                const streamName = detectorUtils.getFunctionStreamName($scope.rule);
                                if (streamName) {
                                    $scope.rule.inputs[streamName] = plotLetter;
                                }
                            } else if ($scope.rule.targetPlot) {
                                $scope.rule.targetPlot = plotLetter;
                            }
                        }
                        setJobInfo();
                    };

                    $scope.updateFuncInputValue = function (prop, value, valid) {
                        $scope.rule.inputs[prop] = value;
                        inputValid[prop] = valid;

                        // validate it right away
                        updateValidity();

                        if ($scope.selectedFunc.inputs[prop].dataType.type === 'Stream') {
                            setJobInfo(prop, value);
                        }

                        setWatchOnRule();

                        if (isApmDetector() && prop === 'pctile' && valid) {
                            $scope.selectedApmPercentile = value;
                        }
                    };

                    // Used to remove the validation and rule for optional properties when they are not enabled
                    // See the auto_resolve_after prop for an example of an optional property
                    $scope.removeFuncInputValue = function (prop) {
                        delete $scope.rule.inputs[prop];
                        delete inputValid[prop];

                        updateValidity();
                    };

                    $scope.signalResolution = function (plot) {
                        function errorHandler(e) {
                            $log.error(
                                'Failed fetching resolution for plot ' +
                                    plot +
                                    ', defaulting to 1 second ',
                                e
                            );
                            return { resolution: 1000 };
                        }
                        return $scope
                            .getSignalResolutionPromise(plot)
                            .then(function (response) {
                                $log.info('Got resolution for plot ', plot, response);
                                const jobInfo = angular.copy(response);
                                jobInfo.success = true;
                                return jobInfo;
                            }, errorHandler)
                            .catch(errorHandler);
                    };

                    function getJobResolutionForPlot(plot) {
                        if (angular.isUndefined($scope.selection.category)) {
                            return $q.when({ resolution: 1000 });
                        } else {
                            // estimate for plot
                            return $scope.signalResolution(plot);
                        }
                    }

                    function isMatch(object, attrs, scaledResolution, resolutionConstraint) {
                        const keys = Object.keys(attrs);
                        const length = keys.length;
                        if (!object) return !length;
                        const obj = Object(object);
                        for (let i = 0; i < length; i++) {
                            const key = keys[i];
                            let attrValueEquals = angular.equals(attrs[key], obj[key]);
                            if (
                                !attrValueEquals &&
                                resolutionConstraint[key] &&
                                scaledResolution[key]
                            ) {
                                attrValueEquals = angular.equals(scaledResolution[key], obj[key]);
                            }
                            if (!attrValueEquals) return false;
                        }
                        return true;
                    }

                    $scope.toggleExpand = function () {
                        $scope.expand = !$scope.expand;
                    };

                    $scope.functionTrackBy = function (func, prop) {
                        return func.function.name + '.' + prop;
                    };

                    function setPreviewRule() {
                        if ($scope.isValid && isPreflight()) {
                            $scope.$broadcast('rule publish preview', $scope.rule, true);
                        } else {
                            $scope.$broadcast('rule publish preview');
                        }
                    }

                    let publishPreviewDebounce;
                    function publishPreview(immediate) {
                        $timeout.cancel(publishPreviewDebounce);
                        publishPreviewDebounce = $timeout(
                            function () {
                                setPreviewRule();
                            },
                            immediate ? 0 : 500
                        );
                    }

                    let timeoutVar = null;
                    function needsPreflight(nvalIn, ovalIn) {
                        const nval = _.omit(nvalIn, RULE_FIELDS);
                        const oval = _.omit(ovalIn, RULE_FIELDS);
                        if (angular.equals(nval, oval)) {
                            return;
                        }
                        if (
                            angular.isUndefined($scope.selection.category) ||
                            $scope.estimatingResolution
                        ) {
                            // we don't have the job info yet for a function, or we are in hover mode
                            formatReadableRuleString();
                            return;
                        }
                        // we need to time this out because input validity is set as a result
                        // of input validation callbacks - controlled by the directive link
                        // the rule watch is triggered sooner than input validity setting - ending up
                        // previewing based on last validity status
                        $timeout.cancel(timeoutVar);
                        timeoutVar = $timeout(function () {
                            timeoutVar = null;
                            if (!$scope.rule) {
                                $scope.isValid = false;
                            } else if ($scope.rule.type !== 'Function') {
                                if (
                                    !$scope.compoundConditionsEnabled ||
                                    $scope.selectedFunc.function.type !== 'dynamic'
                                ) {
                                    $scope.isValid =
                                        detectorUtils.validateLegacyCondition(
                                            $scope.rule,
                                            $scope.plots
                                        ) === undefined;
                                }
                            }
                            $scope.readable = null;
                            if ($scope.isValid) {
                                // publish immediately if rule is set or removed
                                publishPreview(!oval || !nval);
                            }
                            formatReadableRuleString();
                        }, 0);
                    }

                    // picking a new plot may produce the same resolution, we keep track of lastPlot stored in the jobInfo
                    // we can get this same info from the scope.rule change but at that point the resolution may not be ready yet
                    $scope.$watchGroup(
                        ['selection.category', 'jobInfo.lastPlot', 'open', 'isValid'],
                        needsPreflight
                    );

                    // we need to watch on both rule and category index
                    // because rule won't trigger when you 'select' and then 'deselect' the same category,
                    // no change in rule data
                    let watchOnRule = null;

                    function setWatchOnRule() {
                        if (!watchOnRule) {
                            if (
                                $scope.compoundConditionsEnabled &&
                                $scope.selectedFunc &&
                                $scope.selectedFunc.function.type === 'dynamic'
                            ) {
                                watchOnRule = $scope.$watch(
                                    'rule.conditions',
                                    updateValidity,
                                    true
                                );
                            } else if (typeof $scope.selection.category !== 'undefined') {
                                watchOnRule = $scope.$watch('rule', needsPreflight, true);
                            }
                        }
                    }

                    function clearWatchOnRule() {
                        if (watchOnRule) {
                            watchOnRule();
                            watchOnRule = null;
                        }
                    }

                    $scope.$watch(
                        'rule.inputs',
                        function (newVal, oldVal) {
                            assignSensitivityModel();
                            if (!angular.equals(newVal, oldVal)) {
                                $scope.customMessagesNeedsWarning = $scope.rule.isCustomizedMessage;
                            }
                        },
                        true
                    );

                    $scope.$watch(
                        'preSelectedPlot',
                        function () {
                            formatReadableRuleString();
                            assignSensitivityModel();
                        },
                        true
                    );

                    function formatReadableRuleString() {
                        if (!$scope.rule) {
                            // for new undefined rule that's being closed
                            return;
                        }
                        if ($scope.rule.type !== 'Function') {
                            $scope.readable = detectorUtils.getAutoDetectorRuleDescription(
                                $scope.rule,
                                $scope.plots,
                                true
                            );
                        } else if ($scope.rule.type === 'Function') {
                            $scope.readable = detectorUtils.getFunctionRuleSummary(
                                $scope.rule,
                                $scope.plots
                            );
                        }
                    }

                    $scope.formatReadableRuleString = formatReadableRuleString;

                    $scope.sensitivityChanges = function () {
                        $scope.expand = $scope.expand || $scope.sensitivityModel.value === 'custom';

                        $scope.selectedFunc.sensitivity.options.some(function (sen) {
                            if (sen.name === $scope.sensitivityModel.value) {
                                angular.extend($scope.rule.inputs, sen.inputs);
                                angular.extend($scope.rule.inputs, sen.scaledResolution || {});
                                return true;
                            } else {
                                return false;
                            }
                        });
                    };

                    $scope.plots = $scope.detector.sf_uiModel.allPlots;
                    $scope.numPlots = $scope.plots.length;

                    $scope.initRange = $scope.detector.sf_uiModel.chartconfig;
                    if (!$scope.detector.sf_id) {
                        $scope.initRange.range = -24 * 60 * 60 * 1000; // 1 day
                        $scope.initRange.rangeEnd = 0;
                        angular.extend($scope.detector.sf_uiModel.chartconfig, $scope.initRange);
                        $scope.wizardTitle = 'New Alert Rule';
                    } else {
                        $scope.wizardTitle = ($scope.isNewRule ? 'New' : 'Edit') + ' Alert Rule';
                    }

                    function triggerPreflight() {
                        $scope.$broadcast('preflight trigger');
                    }

                    // time picker changes are applied to the model
                    // wizard does not use url parameters
                    $scope.$on('timePickerChanged', function (ev, timeobj) {
                        const timeRange = timeToRange(timeobj);
                        if (!_.isMatch($scope.detector.sf_uiModel.chartconfig, timeRange)) {
                            angular.extend($scope.detector.sf_uiModel.chartconfig, timeRange);
                            triggerPreflight();
                            $scope.$broadcast('setGlobalTimeRanges');
                        }
                    });
                    $scope.$on('set time from preflight', function (ev, timeRange) {
                        const timepickerObj = timepickerUtils.chartConfigToTimePickerObj(timeRange);

                        // this will end up call back to apply the chart config and retriggering preflight
                        $scope.$broadcast('initializeTimePicker', timepickerObj);
                    });

                    $scope.$on('setCalendarWindowCycleLength', function (evt, cycleName) {
                        const chartConfigTime = $scope.detector.sf_uiModel.chartconfig;
                        const optimizedCalendarWindow = detectorUtils.getOptimizedCalendarWindow(
                            cycleName,
                            chartConfigTime
                        );

                        if (optimizedCalendarWindow) {
                            $scope.detector.sf_uiModel.chartconfig.range =
                                optimizedCalendarWindow.optimalRangeInMs;
                            // Optimize the current chart window
                            $scope.$broadcast(
                                'setTimePicker',
                                optimizedCalendarWindow.optimalRange
                            );
                        }
                    });

                    $scope.$on('toggle compound conditions', (ev, compoundSelected) => {
                        $scope.compoundSelected = compoundSelected;
                        if ($scope.compoundSelected) {
                            $scope.categoryClick($scope.categories.length - 1, ev);
                        }
                    });

                    $scope.passChartAndEventInformation = function (result) {
                        $scope.$broadcast('pass chart and event information', result);
                    };

                    $scope.fetchChartData = function () {
                        $scope.$broadcast('get events and metadata with timestamp');
                    };

                    // switch to data/events tabs
                    $scope.$on(CHART_DISPLAY_EVENTS.SELECT_TAB, function (evt, tabId) {
                        if ($scope.advancedMode) {
                            const currentStage = $scope.stages[$scope.selectedStage].key;
                            // always pick settings are preferred stage, unless we are already in signal picker
                            // in which case prefer signal stage if tabId is 'data'
                            let preferredStage = 'settings';
                            if (tabId === 'data' && currentStage === 'signal') {
                                preferredStage = 'signal';
                            }
                            $scope.selectStage($scope.stageKeys[preferredStage]);
                            // issue broadcast on a timeout since broadcast is not async and select stage would not have digested
                            // in the dom
                            $timeout(function () {
                                $scope.$broadcast('go to tab', tabId, preferredStage);
                            }, 0);
                        }
                    });

                    $scope.$on(
                        CHART_DISPLAY_EVENTS.CHART_WINDOW_MISALIGNED,
                        function (evt, chartWindowMisaligned, identifier) {
                            if (identifier === 'main') {
                                $scope.misalignedResolution = chartWindowMisaligned;
                            }
                        }
                    );

                    let teamsToLink = [];
                    let teamsToUnlink = [];
                    $scope.$on('team notification removed', ($event, team) => {
                        teamsToLink = teamsToLink.filter((t) => t.id !== team.id);
                        teamsToUnlink.push(team);
                    });

                    $scope.$on('team notification added', ($event, team) => {
                        teamsToUnlink = teamsToUnlink.filter((t) => t.id !== team.id);
                        teamsToLink.push(team);
                    });

                    // boot strap ui with pre selected signal if coming from an existing rule
                    $scope.hasUnsavedChanges = false;
                    if (!$scope.isNewRule) {
                        let preSelectedSignal;
                        if (detectorUtils.hasCompoundConditions($scope.rule)) {
                            const uniqueTargetPlots = _.uniq(
                                $scope.rule.conditions.map((c) => c.targetPlot)
                            );
                            $scope.compoundSelected = uniqueTargetPlots.length !== 1;
                            if (uniqueTargetPlots.length === 1) {
                                preSelectedSignal = uniqueTargetPlots[0];
                                $scope.preSelectedPlot = $scope.detector.sf_uiModel.allPlots.filter(
                                    (plot) =>
                                        plotUtils.getLetterFromUniqueKey(plot.uniqueKey) ===
                                        preSelectedSignal
                                )[0];
                            }
                        } else {
                            preSelectedSignal =
                                $scope.rule.targetPlot ||
                                detectorUtils.getFunctionStream($scope.rule);
                            $scope.preSelectedPlot = $scope.detector.sf_uiModel.allPlots.filter(
                                (plot) =>
                                    plotUtils.getLetterFromUniqueKey(plot.uniqueKey) ===
                                    preSelectedSignal
                            )[0];
                        }
                        // initialize shared controller function settings with pre selected signal for this previously saved rule
                        $scope.initializeWithRule($scope.rule, preSelectedSignal);
                        $scope.generateSignalSummary();
                        $scope.formatReadableRuleString();
                        $timeout(function () {
                            const watchHandle = $scope.$watch(
                                'rule',
                                function (nval, oval) {
                                    $scope.hasUnsavedChanges =
                                        nval && oval && !angular.equals(nval, oval);
                                    if ($scope.hasUnsavedChanges) {
                                        watchHandle(); // no need to keep tracking since this dirty state is final, in the wizard
                                    }
                                },
                                true
                            );
                        }, 500);
                    } else {
                        // initialize shared controller function settings but no pre selected signal because this is a new rule
                        $scope.initializeWithRule($scope.rule, null);
                        $scope.hasUnsavedChanges = true;
                        const existingPlot = $scope.detector.sf_uiModel.allPlots.filter(
                            (plot) => plot.type === 'plot' && !plot.invisible && !plot.transient
                        )[0];
                        if (existingPlot) {
                            // came from a chart, creating a new detector
                            $scope.onSignalSelect(existingPlot);
                        }
                    }

                    // set plot names and options in shared controller
                    $scope.setPlotOptions();
                    $scope.hideLegend = true;

                    // APM related functions

                    $scope.onSelectDetectorType = (type) => {
                        if (type === $scope.selectedDetectorType) return;

                        if ($scope.selectedDetectorType) {
                            // if user switches between types, we update the plots
                            // from cache (the previous selections/settings between switching type)
                            updatePlotsForSwitchingTypes();
                        }

                        // set detector type
                        $scope.selectedDetectorType = type;

                        // if user selects APM Detector, we need to initialize the APM Detector
                        if (isApmDetector()) {
                            initApmDetector();
                        }

                        if (!$scope.selectedApmMetricType) {
                            $scope.selectedApmMetricType = APM_METRIC_TYPE.SERVICE_ERRORS;
                        }

                        updateYAxisLabel();
                    };

                    $scope.onSelectApmMetricType = (metricType) => {
                        if (metricType === $scope.selectedApmMetricType) return;
                        $scope.selectedApmMetricType = metricType;
                        $scope.isValid = false;

                        updateYAxisLabel();
                    };

                    $scope.onSelectApmEnvironment = (environment) => {
                        $scope.rule.apmEnvironmentSelection = environment;
                    };

                    $scope.onSelectApmServiceEndpoint = (selections, initialized) => {
                        if (initialized) {
                            // once a service/endpoint is selected/changed by user
                            // then we should not allow the user to switch to infra type
                            $scope.isReadOnlyType = true;
                        }
                        $scope.rule.apmServiceEndpointSelections = selections;
                        $scope.selectedServiceEndpoints = selections;
                    };

                    $scope.onSelectApmBusinessWorkflow = (selections, initialized) => {
                        if (initialized) {
                            // once a business workflow is selected/changed by user
                            // then we should not allow the user to switch to infra type
                            $scope.isReadOnlyType = true;
                        }
                        $scope.rule.apmBusinessWorkflowSelections = selections;
                        $scope.selectedBusinessWorkflows = selections;
                    };

                    $scope.onSelectApmFilters = (filters) => {
                        $scope.rule.apmFilters = filters;
                    };

                    function isApmDetector() {
                        return $scope.selectedDetectorType === DETECTOR_TYPES.APM_V2;
                    }

                    function getDetectorType() {
                        const rules = $scope.detector?.sf_uiModel?.rules;
                        if (!rules) return;

                        const hasValidRuleWithPath = rules.some((r) => !r.invalid && r.path);
                        if (hasValidRuleWithPath) {
                            if ($scope.apm2Enabled && detectorUtils.hasValidApmV2Rule(rules)) {
                                return DETECTOR_TYPES.APM_V2;
                            } else {
                                return DETECTOR_TYPES.INFRASTRUCTURE;
                            }
                        }

                        // this check accounts for the fact, that an infra detector might only have static/compound rules
                        // notice that if this detector has at least one infra function rule, it would be covered by the checks above
                        if (
                            rules.some((rule) =>
                                detectorUtils.hasThresholdOrCompoundConditions(rule)
                            )
                        ) {
                            return DETECTOR_TYPES.INFRASTRUCTURE;
                        }

                        // for infra detector, we dont delete the plots (when the rule is removed)
                        // if all the rules are deleted and the 'New rule' button is clicked
                        // it's possible to have left over plot, we determine it is a infra detector
                        if (
                            !$scope.selectedDetectorType &&
                            detectorUtils.getNumMetricPlots($scope.detector.sf_uiModel) > 0
                        ) {
                            return DETECTOR_TYPES.INFRASTRUCTURE;
                        }
                    }

                    function initDetectorType() {
                        const detectorType = safeLookup($scope, 'detectorType');
                        if (detectorType) {
                            $scope.selectedDetectorType = detectorType;
                        } else if ($scope.detectorTypeRecommendationFailed) {
                            $scope.selectedDetectorType = null;
                        } else {
                            $scope.selectedDetectorType = getDetectorType();
                        }
                    }

                    function initApmDetector() {
                        initApmMetricType();
                        initSignalType();
                        initSignalTypeSelections();
                        initEnvironmentSelection();
                        initApmFilters();
                    }

                    // init methods for apm metric type

                    function initApmMetricType() {
                        if ($scope.isNewRule) {
                            initApmMetricTypeForNewRule();
                        } else {
                            initApmMetricTypeFromSavedRule();
                        }

                        updateYAxisLabel();
                    }

                    function initApmMetricTypeForNewRule() {
                        // If a detector is being created from a given context, apmDetectorOptions will be
                        // passed and used to preselect properties of the detector. In all other cases
                        // we just use the default value (errors)
                        const apmMetricType = safeLookup($scope, 'apmDetectorOptions.metricType');
                        $scope.selectedApmMetricType =
                            apmMetricType || APM_METRIC_TYPE.SERVICE_ERRORS;
                    }

                    function initApmMetricTypeFromSavedRule() {
                        const rules = safeLookup($scope, 'detector.sf_uiModel.rules');
                        const validRulesWithPath = rules.filter((r) => !r.invalid && r.path);
                        // for saved rule, we determine the apm metric type by checking the rule paths
                        $scope.selectedApmMetricType = APM_METRIC_TYPE.SERVICE_ERRORS;
                        if (validRulesWithPath && validRulesWithPath.length !== 0) {
                            const path = validRulesWithPath[0].path;
                            $scope.selectedApmMetricType =
                                detectorUtils.getApmMetricTypeFromPath(path);
                        }
                    }

                    function initSignalType() {
                        if (!$scope.selectedApmMetricType) {
                            $scope.signalTypeEntity = signalTypeService.SERVICE_ENDPOINT;
                        }

                        if (
                            signalTypeService.SERVICE_ENDPOINT['apmMetricGroup'].includes(
                                $scope.selectedApmMetricType
                            )
                        ) {
                            $scope.signalTypeEntity = signalTypeService.SERVICE_ENDPOINT;
                        } else if (
                            isApm2WorkflowsEnabled &&
                            signalTypeService.WORKFLOW['apmMetricGroup'].includes(
                                $scope.selectedApmMetricType
                            )
                        ) {
                            $scope.signalTypeEntity = signalTypeService.WORKFLOW;
                        }
                    }

                    function initSignalTypeSelections() {
                        if (
                            $scope.signalTypeEntity.name === signalTypeService.SERVICE_ENDPOINT.name
                        ) {
                            if ($scope.isNewRule) {
                                initServiceEndpointSelectionsForNewRule();
                            } else {
                                initServiceEndpointSelectionsFromSavedRule();
                            }
                        } else if (
                            isApm2WorkflowsEnabled &&
                            $scope.signalTypeEntity.name === signalTypeService.WORKFLOW.name
                        ) {
                            if ($scope.isNewRule) {
                                initBusinessWorkflowSelectionsForNewRule();
                            } else {
                                initBusinessWorkflowSelectionsFromSavedRule();
                            }
                        }
                    }

                    // init methods for service/endpoints selection

                    function initServiceEndpointSelectionsForNewRule() {
                        const selectedServiceEndpoint = safeLookup(
                            $scope,
                            'apmDetectorOptions.serviceEndpointSelection'
                        );
                        if (selectedServiceEndpoint) {
                            $scope.selectedServiceEndpoints = [selectedServiceEndpoint];
                        }
                    }

                    function initServiceEndpointSelectionsFromSavedRule() {
                        const savedSelections = safeLookup(
                            $scope,
                            'rule.apmServiceEndpointSelections'
                        );
                        if (!savedSelections) return;

                        $scope.selectedServiceEndpoints = savedSelections.map((savedSelection) => {
                            const selection = new ServiceEndpointSelection(
                                savedSelection._service,
                                savedSelection._endpoints
                            );
                            selection.plotIds = savedSelection.plotIds;
                            return selection;
                        });
                    }

                    // init methods for business workflows selection

                    function initBusinessWorkflowSelectionsForNewRule() {
                        const selectedBusinessWorkflow = safeLookup(
                            $scope,
                            'apmDetectorOptions.businessWorkflowSelection'
                        );
                        if (selectedBusinessWorkflow) {
                            $scope.selectedBusinessWorkflows = [selectedBusinessWorkflow];
                        }
                    }

                    function initBusinessWorkflowSelectionsFromSavedRule() {
                        const savedSelections = safeLookup(
                            $scope,
                            'rule.apmBusinessWorkflowSelections'
                        );
                        if (!savedSelections) return;

                        $scope.selectedBusinessWorkflows = savedSelections.map((savedSelection) => {
                            const selection = new BusinessWorkflowSelection(
                                savedSelection._resource
                            );
                            selection.plotIds = savedSelection.plotIds;
                            return selection;
                        });
                    }

                    // init method for environment selection
                    function initEnvironmentSelection() {
                        if ($scope.isNewRule) {
                            $scope.selectedEnvironment = safeLookup(
                                $scope,
                                'apmDetectorOptions.environmentSelection'
                            );

                            // Jakub, 03/31/2020: there's a very problematic structure in place wrt passing apmEnvironmentSelection up
                            // and down the chain of components. As per guidelines, onSelectApmEnvironment should also set
                            // $scope.selectedEnvironment, instead apmSignalEditor does it in place when users changes dropdown
                            // selection. However, apmSignalEditor also relies on the fact, that $scope.selectedEnvironment never
                            // changes, and once we start setting it, apmSignalEditor does not work correctly (specifically its
                            // $onChanges). For now I'm ducktaping this, pending a proper refactoring.
                            // This fixes a bug when selectedEnvironment is carried over from a dashboard, we're creating a detector
                            // from. In that case onSelectApmEnvironment() might never be called, and so
                            // rule.apmEnvironmentSelection might never be set.
                            $scope.rule.apmEnvironmentSelection = $scope.selectedEnvironment;
                        } else {
                            $scope.selectedEnvironment = safeLookup(
                                $scope,
                                'rule.apmEnvironmentSelection'
                            );
                        }
                    }

                    // init method for filters selection
                    function initApmFilters() {
                        if ($scope.isNewRule) {
                            $scope.selectedApmFilters =
                                safeLookup($scope, 'apmDetectorOptions.filters') || [];
                        } else {
                            $scope.selectedApmFilters = safeLookup($scope, 'rule.apmFilters') || [];
                        }
                    }

                    /**
                     * save the plots in previousDetectorTypeCachedPlots
                     * in case user switches the detector type, we can show the original plots back
                     */
                    function initPreviousDetectorTypeCachedPlots() {
                        previousDetectorTypeCachedPlots = angular.copy(
                            $scope.detector.sf_uiModel.allPlots
                        );

                        if (isApmDetector()) {
                            // for apm detector, we dont want/need the plots from detector
                            // because the plots will be created in apmSignalEditor.js (based on the serviceEndpointSelection)
                            $scope.detector.sf_uiModel.allPlots = [];
                        }
                    }

                    /**
                     * update the uiModel plots using the previousDetectorTypeCachedPlots
                     * and then update previousDetectorTypeCachedPlots to the plots (before switching)
                     * this method is used by detector when switching detector types
                     */
                    function updatePlotsForSwitchingTypes() {
                        const uiModel = $scope.detector.sf_uiModel;
                        const previousPlots = angular.copy(uiModel.allPlots);

                        if (previousDetectorTypeCachedPlots.length === 0) {
                            uiModel.allPlots = [];
                        } else {
                            uiModel.allPlots = previousDetectorTypeCachedPlots;
                        }

                        previousDetectorTypeCachedPlots = previousPlots;
                    }

                    function hasCompletedSignalTypeSelection(metricType) {
                        if (
                            signalTypeService.SERVICE_ENDPOINT.apmMetricGroup.includes(metricType)
                        ) {
                            return (
                                safeLookup($scope, 'rule.apmServiceEndpointSelections') || []
                            ).some((s) => s._service !== '' && s._endpoints.length !== 0);
                        } else if (signalTypeService.WORKFLOW.apmMetricGroup.includes(metricType)) {
                            return (
                                safeLookup($scope, 'rule.apmBusinessWorkflowSelections') || []
                            ).some((s) => s._resource !== '');
                        }
                    }

                    function updateYAxisLabel() {
                        const errorMetricTypes = isApm2WorkflowsEnabled
                            ? [APM_METRIC_TYPE.SERVICE_ERRORS, APM_METRIC_TYPE.WORKFLOW_ERROR_RATE]
                            : [APM_METRIC_TYPE.SERVICE_ERRORS];
                        const isApmErrorDetector = errorMetricTypes.includes(
                            $scope.selectedApmMetricType
                        );

                        $scope.detector.sf_uiModel.chartconfig.yAxisConfigurations[0].label =
                            isApmDetector() && isApmErrorDetector ? 'Error Rate (%)' : null;
                    }
                },
            },
        };
    },
]);
