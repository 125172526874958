type ChartDataType =
    | 'TimeSeriesChart'
    | 'SingleValue'
    | 'List'
    | 'Text'
    | 'Heatmap'
    | 'Event'
    | 'Vega'
    | 'Map'
    | 'SystemMap'
    | 'TableChart'
    | 'LogsChart'
    | 'LogsTimeSeriesChart'
    | 'RequestBasedSloChart'
    | 'WindowsBasedSloChart';

type DataSource = 'metrics' | 'logs' | 'events' | 'none';

type Chart = {
    options: {
        type: ChartDataType;
    };
};

const chartTypeToDataSourceMapping: Record<ChartDataType, DataSource> = {
    TimeSeriesChart: 'metrics',
    SingleValue: 'metrics',
    List: 'metrics',
    Text: 'none',
    Heatmap: 'metrics',
    Vega: 'metrics',
    Map: 'metrics',
    SystemMap: 'metrics',
    TableChart: 'metrics',
    RequestBasedSloChart: 'metrics',
    WindowsBasedSloChart: 'metrics',
    LogsChart: 'logs',
    LogsTimeSeriesChart: 'logs',
    Event: 'events',
};

export function getDataSources(charts: Chart[]): Set<string> {
    return charts.reduce((sources, chart) => {
        const dataSource = chartTypeToDataSourceMapping[chart.options.type];
        if (dataSource !== 'none') sources.add(dataSource);

        return sources;
    }, new Set<string>());
}
