import { sanitizeTerm } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import curlTemplateUrl from './curl.tpl.html';
import detectorSaveTooltipTemplateUrl from '../../tooltips/detectorSaveTooltip.tpl.html';
import { ngRoute } from '../../../../app/routing/ngRoute';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { NO_CAPABILITIES_TOOLTIP } from '../../../common/data/rbac/common';
import { NO_PERMISSIONS_TOOLTIP } from '../../../common/data/util/writepermissionsPermissionsChecker';

angular.module('signalview.detectorV2').controller('v2DetectorController', [
    '$scope',
    'v2DetectorAPIWrapper',
    '$http',
    '$location',
    'ALERT_SEVERITY_LEVELS',
    '$interval',
    '$timeout',
    'sfxModal',
    'alertMessageService',
    'alertTypeService',
    'hasCapability',
    'writepermissionsPermissionsChecker',
    'detectorUtils',
    'CHART_DISPLAY_EVENTS',
    'userAnalytics',
    'featureEnabled',
    'confirmService',
    'urlOverridesService',
    'API_URL',
    function (
        $scope,
        v2DetectorAPIWrapper,
        $http,
        $location,
        ALERT_SEVERITY_LEVELS,
        $interval,
        $timeout,
        sfxModal,
        alertMessageService,
        alertTypeService,
        hasCapability,
        writepermissionsPermissionsChecker,
        detectorUtils,
        CHART_DISPLAY_EVENTS,
        userAnalytics,
        featureEnabled,
        confirmService,
        urlOverridesService,
        API_URL
    ) {
        const originalDetector = v2DetectorAPIWrapper($scope.detector);

        const ALERTS_COUNT_EXPIRATION = 10 * 1000;

        const DEFAULT_POLICY_FIELDS = {
            severity: ALERT_SEVERITY_LEVELS[0],
            notifications: [],
            description: '',
        };

        let teamsToLink = [];
        let teamsToUnlink = [];

        let ruleAlertCountInterval;

        $scope.newRules = {};

        const signalflow_tab = {
            id: 'signalflow',
            name: 'Signalflow',
        };
        const rules_tab = {
            id: 'rules',
            name: 'Alert Rules',
        };
        const options_tab = {
            id: 'options',
            name: 'Options',
        };
        const data_table_tab = {
            id: 'data',
            name: 'Data Table',
        };
        const events_tab = {
            id: 'events',
            name: 'Events',
        };

        $scope.sectionTabs = [rules_tab, signalflow_tab, options_tab, data_table_tab, events_tab];

        $scope.errors = false;
        $scope.detectorSaveTooltipTemplateUrl = detectorSaveTooltipTemplateUrl;

        $scope.setErrors = function (errors) {
            $scope.errors = errors;
        };

        async function updateLocalPermissionState() {
            $scope.hasDeleteDetectorCapability = await hasCapability(Capability.DELETE_DETECTOR);
            $scope.hasUpdateDetectorCapability = await hasCapability(Capability.UPDATE_DETECTOR);
            $scope.hasWritePermission =
                await writepermissionsPermissionsChecker.hasWritePermissions($scope.detector);
        }

        updateLocalPermissionState();

        $scope.getDisableTooltipText = () => {
            if (!$scope.hasUpdateDetectorCapability) {
                return NO_CAPABILITIES_TOOLTIP;
            } else if (!$scope.hasWritePermission) {
                return NO_PERMISSIONS_TOOLTIP;
            }
        };

        $scope.hasUnsavedChanges = true;
        $scope.signalsCollapsed = !!originalDetector.getSignalFlow();

        $scope.setSignalFlowTab = function () {
            $scope.$broadcast('go to tab', signalflow_tab.id);
        };

        $scope.$on(CHART_DISPLAY_EVENTS.SELECT_TAB, function (evt, tabId) {
            $scope.$broadcast('go to tab', tabId);
        });

        $scope.$on(CHART_DISPLAY_EVENTS.SELECT_PREVIOUS_TAB, function () {
            $scope.$broadcast('go to previous tab');
        });

        $scope.$on('tab selected', function (ev, tabId) {
            $scope.sharedChartState.currentTabId = tabId;
            if (tabId === data_table_tab.id) {
                $scope.$broadcast(CHART_DISPLAY_EVENTS.LEGEND_TAB_SELECTED, 'data');
            } else if (tabId === events_tab.id) {
                $scope.$broadcast(CHART_DISPLAY_EVENTS.LEGEND_TAB_SELECTED, 'event');
            }
        });

        // When coming from a Chart - we know we are going to get a signalflow error and want to have the user fix that right away
        if (ngRoute.params.fromChart && !$scope.toSelectTab) {
            $scope.toSelectTab = signalflow_tab;
        }

        $scope.pinAfterLoad = function (time) {
            $scope.$broadcast(
                'chart pin after load',
                time,
                $scope.sharedChartState.currentTabId === events_tab.id
            );
        };

        $scope.$on('team notification removed', ($event, team) => {
            teamsToLink = teamsToLink.filter((t) => t.id !== team.id);
            teamsToUnlink.push(team);
        });

        $scope.$on('team notification added', ($event, team) => {
            teamsToUnlink = teamsToUnlink.filter((t) => t.id !== team.id);
            teamsToLink.push(team);
        });

        $scope.needsDelegatedOverflow = function () {
            return (
                $scope.sharedChartState.currentTabId === signalflow_tab.id ||
                $scope.sharedChartState.currentTabId === data_table_tab.id ||
                $scope.sharedChartState.currentTabId === events_tab.id ||
                $scope.sharedChartState.currentTabId === options_tab.id
            );
        };

        function getErrors() {
            const errors = [];
            if (!originalDetector.getName()) {
                errors.push('A detector name is required.');
            }
            if (originalDetector.getRules().length === 0) {
                errors.push('At least one rule must be defined.');
            }

            return errors;
        }

        $scope.rulePreview = {
            selectedRule: -1,
        };

        $scope.toggleSelectedRule = function toggleSelectedRule(index) {
            userAnalytics.event('click', 'detector-rule-visible-click');

            if ($scope.rulePreview.selectedRule === index) {
                $scope.rulePreview.selectedRule = -1;
            } else {
                $scope.rulePreview.selectedRule = index;
            }

            if ($scope.rulePreview.selectedRule > -1) {
                const rule = $scope.detector.rules[$scope.rulePreview.selectedRule];
                if (!rule.invalid) {
                    $scope.detectorPreviewConfig.detectLabel = rule.detectLabel;
                    $scope.$broadcast('rule publish preview', rule, true);
                }
            } else {
                $scope.detectorPreviewConfig.detectLabel = undefined;
                $scope.$broadcast('rule publish preview');
            }
        };

        $scope.save = function () {
            const isFirstSave = originalDetector.isEphemeral();
            $scope.updateOriginal();

            const errors = getErrors();

            if (errors.length > 0) {
                alertMessageService({
                    title: 'Error',
                    messages: errors,
                });
                return;
            }

            $scope.showModal = true;

            originalDetector.updatePublishLabelOptions($scope.model.sf_uiModel);

            return originalDetector
                .save()
                .then((detectorResponse) => {
                    const fromChartId = urlOverridesService.getFromChart();
                    if (featureEnabled('linkingDetectors') && fromChartId) {
                        // this process is deliberately not part of the promise chain, as there is no reason
                        // to block future processing on either success or failure of the linking.
                        const detector = detectorResponse.data;
                        detectorUtils
                            .linkDetectorToUnconvertableChart(
                                detector.id,
                                detector.name,
                                fromChartId
                            )
                            .then(function (success) {
                                if (!success) {
                                    confirmService.confirm({
                                        title: 'Detector Linking',
                                        text: [
                                            'This detector could not be linked to its source chart, possibly due to a change in the underlying chart permissions.',
                                        ],
                                        yesText: 'Ok',
                                        noText: '',
                                    });
                                }
                            });
                    }
                    return detectorResponse;
                })
                .then(
                    function (resp) {
                        if (isFirstSave) {
                            // wipe out all url parameters
                            // (only detectorSignalFlowEditor left, which forces rendering SignalFlow editor)
                            $location.url(
                                '/detector/v2/' + resp.data.id + '/edit?detectorSignalFlowEditor=1'
                            );
                        } else {
                            ngRoute.reload();
                        }
                    },
                    function (errs) {
                        if (errs.data) {
                            alertMessageService({
                                title: 'Error',
                                messages: [
                                    'Unable to save detector!',
                                    errs.data.code + ' : ' + errs.data.message,
                                ],
                            });
                        } else {
                            alertMessageService({
                                title: 'Error',
                                messages: [
                                    'An unexpected error occurred when saving this detector',
                                ],
                            });
                        }
                    }
                )
                .finally(function () {
                    $scope.showModal = false;
                });
        };

        $scope.timezone = {
            callback: (toSave) => {
                $scope.model.sf_timezone = toSave;
                userAnalytics.event('timezone-changed', 'v2-detector-options' + toSave);
            },
        };
        // Populate the drop down
        $scope.timezone.query = $scope.model.sf_timezone || '';

        const time = originalDetector.getTime();
        if (time) {
            if (time.type === 'relative') {
                $scope.model.sf_uiModel.chartconfig.range = time.range;
            } else if (time.type === 'absolute') {
                $scope.model.sf_uiModel.chartconfig.absoluteStart = time.start;
                $scope.model.sf_uiModel.chartconfig.absoluteEnd = time.end;
            }
        }

        $scope.signalFlow = originalDetector.getSignalFlow() || '';
        $scope.detectLabelToCount = {};

        $scope.addPolicy = function (label) {
            const newPolicy = angular.copy(DEFAULT_POLICY_FIELDS);
            newPolicy.detectLabel = label;
            $scope.model.policies.push(newPolicy);
            $scope.newRules[label] = true;
        };

        function updateLoadingState(labels, isLoading = true) {
            labels.forEach((singleLabel) => {
                if ($scope.detectLabelToCount[singleLabel]) {
                    $scope.detectLabelToCount[singleLabel] = {
                        ...$scope.detectLabelToCount[singleLabel],
                        isLoading,
                    };
                } else {
                    $scope.detectLabelToCount[singleLabel] = { isLoading };
                }
            });
        }

        function fetchActiveAlertsCount(rule) {
            getAnomalousRuleCounts([rule.detectLabel], false);
        }

        function resetActiveAlertsCount(labels) {
            labels.forEach((singleLabel) => {
                if ($scope.detectLabelToCount[singleLabel]) {
                    $scope.detectLabelToCount[singleLabel] = {
                        ...$scope.detectLabelToCount[singleLabel],
                        outdated: true,
                    };
                }
            });
        }

        function getAnomalousRuleCounts(specificLabels = [], autoUpdate = true) {
            const id = originalDetector.getId();
            if (!id || $scope.knownLabels.length === 0) {
                $scope.detectLabelToCount = {};
                return;
            }

            const labelsToUpdate = specificLabels.length ? specificLabels : $scope.knownLabels;

            // there is a hard-coded limit on BE which is up to 10 aggregations per single request
            const limitedLabels = labelsToUpdate.slice(0, 10);

            updateLoadingState(limitedLabels);

            $http
                .post(API_URL + '/v2/eventtimeseries/_/aggregate', {
                    query:
                        'sf_detectorId:' +
                        id +
                        ' AND sf_detectLabel:(' +
                        sanitizeTerm(limitedLabels) +
                        ' AND (NOT sf_archived:true)' +
                        ')',
                    aggregateOnFields: ['sf_detectLabel', 'sf_anomalyState'],
                })
                .then((res) => res.data)
                .then(function (res) {
                    updateLoadingState(limitedLabels, false);

                    limitedLabels.forEach((name) => {
                        // if there's no alerts in API response general count is equal 0 w/o aggregations by detectLabel
                        const aggregation = res.aggregations?.find(
                            (aggregation) => aggregation.name === name
                        ) || { count: 0, name, aggregations: [] };

                        if (aggregation.aggregations) {
                            const label = aggregation.name;
                            const count = aggregation.aggregations.reduce(function (prev, current) {
                                if (alertTypeService.isClearingEvent(current.name)) return prev;
                                return prev + current.count;
                            }, 0);
                            $scope.detectLabelToCount[label] = {
                                count,
                                isLoading: false,
                                outdated: false,
                                timestamp: Date.now(),
                            };
                        }
                    });

                    if (!autoUpdate) {
                        $timeout(
                            () => resetActiveAlertsCount(limitedLabels),
                            ALERTS_COUNT_EXPIRATION
                        );
                    }
                });
        }

        function getUnknownPolicies() {
            const unknownPolicies = [];
            if ($scope.knownLabels) {
                $scope.model.policies.forEach(function (policy) {
                    if ($scope.knownLabels.indexOf(policy.detectLabel) === -1) {
                        unknownPolicies.push(policy);
                    }
                });
            }
            return unknownPolicies;
        }

        function updateKnownLabelState() {
            $scope.unknownPolicies = getUnknownPolicies();
        }

        $scope.fetchActiveAlertsCount = fetchActiveAlertsCount;

        $scope.$watch('model.policies.length', updateKnownLabelState);

        $scope.$watch('detector.authorizedWriters', updateLocalPermissionState);

        $scope.$watchCollection('knownLabels', function (newval) {
            $scope.knownLabels = newval;
            if (newval) {
                const policiesByEvent = {};
                //keep any policies with labels that match the new parsed labels, or have at least 1 notification
                $scope.model.policies = $scope.model.policies.filter(isNotDefaultPolicy);
                $scope.model.policies.forEach(function (policy) {
                    policiesByEvent[policy.detectLabel] = policy;
                });
                //add new default policy models for new labels that have appeared since last parse
                newval.forEach(function (detectLabel) {
                    if (!policiesByEvent[detectLabel]) {
                        $scope.addPolicy(detectLabel);
                    }
                });
                updateKnownLabelState();
            }
        });

        $scope.setKnownLabels = function (knownLabels) {
            $scope.knownLabels = knownLabels;

            $interval.cancel(ruleAlertCountInterval);

            getAnomalousRuleCounts();
            ruleAlertCountInterval = $interval(getAnomalousRuleCounts, 10000);
        };

        // We want to keep any policy that has at least one non-empty, non-default, field other than detectLabel
        function isNotDefaultPolicy(policy) {
            const fields = Object.keys(angular.copy(policy)); // copy to get rid of angular fields like $$hashKey
            return fields.some(function (field) {
                const value = policy[field];
                const isDefaultValue = angular.equals(value, DEFAULT_POLICY_FIELDS[field]);
                return !!value && !isDefaultValue && field !== 'detectLabel';
            });
        }

        $scope.getErrorCaretPosition = function (num) {
            let str = '';
            for (let x = 0; x < num; x++) {
                str += ' ';
            }
            return str + '^';
        };

        $scope.$watchCollection('model.sf_uiModel.chartconfig', function () {
            $scope.updateOriginal();
        });

        $scope.$watch('model.sf_detector', function () {
            $scope.updateOriginal();
        });

        $scope.reassignPolicies = function (target, former) {
            // find former rule
            const formerRule = $scope.unknownPolicies.find(function (policy) {
                return policy.detectLabel === former;
            });

            $scope.model.policies = $scope.model.policies
                .filter(function (policy) {
                    // filter out the policy that is being reassigned
                    return policy.detectLabel !== former;
                })
                .map(function (policy) {
                    // swap it with the actual target in policy list
                    return policy.detectLabel === target ? formerRule : policy;
                });

            // set the detect label of the former to the target
            formerRule.detectLabel = target;

            // update the known label state
            updateKnownLabelState();
        };

        function getTime(v1ChartConfig) {
            return detectorUtils.convertV1ToV2Time(v1ChartConfig);
        }

        $scope.updateSignalFlow = function (signalFlow) {
            $scope.signalFlow = signalFlow;
            originalDetector.setSignalFlow($scope.signalFlow);
        };

        $scope.updateOriginal = function () {
            originalDetector.setName($scope.model.sf_detector);
            originalDetector.setDescription($scope.model.sf_description);
            originalDetector.setSignalFlow($scope.signalFlow);
            const orphanedRuleLabels = getUnknownPolicies().map((policy) => policy.detectLabel);
            //eh...
            $scope.model.policies = $scope.model.policies
                .filter(function (rule) {
                    return orphanedRuleLabels.indexOf(rule.detectLabel) === -1;
                })
                .map(function (policy) {
                    if (policy.notifications) {
                        policy.notifications.forEach(function (notification) {
                            if (notification.credentialName) {
                                delete notification.credentialName;
                            }
                        });
                    }

                    return policy;
                });

            originalDetector.setRules($scope.model.policies);
            if ($scope.detector && $scope.detector.description) {
                originalDetector.setDescription($scope.detector.description);
            } else {
                originalDetector.setDescription($scope.model.sf_description);
            }
            originalDetector.setShowDataMarkers(
                ($scope.model.sf_uiModel &&
                    $scope.model.sf_uiModel.chartconfig &&
                    $scope.model.sf_uiModel.chartconfig.showDots) ||
                    false
            );
            originalDetector.setShowEventLines(
                ($scope.model.sf_uiModel &&
                    $scope.model.sf_uiModel.chartconfig &&
                    $scope.model.sf_uiModel.chartconfig.eventLines) ||
                    false
            );
            originalDetector.setDisableThrottle(
                ($scope.model.sf_uiModel &&
                    $scope.model.sf_uiModel.chartconfig &&
                    $scope.model.sf_uiModel.chartconfig.disableThrottle) ||
                    false
            );
            //is 0 auto?
            originalDetector.setMaxDelay($scope.model.sf_jobMaxDelay || 0);
            originalDetector.setTime(getTime($scope.model.sf_uiModel.chartconfig));
            originalDetector.setTimezone($scope.model.sf_timezone);
            return originalDetector;
        };

        $scope.showCurl = function () {
            $scope.updateOriginal();
            originalDetector.getCUrl().then(function (str) {
                sfxModal.open({
                    templateUrl: curlTemplateUrl,
                    size: 'md',
                    backdrop: true,
                    controller: [
                        '$scope',
                        'cUrlCmd',
                        function ($scope, cUrlCmd) {
                            $scope.cUrlCmd = cUrlCmd;
                        },
                    ],
                    resolve: {
                        cUrlCmd: function () {
                            return str;
                        },
                    },
                });
            });
        };

        $scope.$on('$destroy', function () {
            $interval.cancel(ruleAlertCountInterval);
        });
    },
]);
