import React, { useState } from 'react';
import { ChartEditModal } from './ChartEditModal';
import Button from '@splunk/react-ui/Button';
import Pencil from '@splunk/react-icons/Pencil';

export type ChartPageHeaderEditProps = {
    description?: string;
    hasWritePermission?: boolean;
    name?: string;
    update: (params: { name?: string; description?: string }) => void;
};

export const ChartPageHeaderEdit = ({
    description,
    hasWritePermission,
    name,
    update: rawUpdate,
}: ChartPageHeaderEditProps): React.JSX.Element => {
    const [open, setOpen] = useState(false);

    const handleRequestClose = (): void => {
        setOpen(false);
    };

    const update = ({ name, description }: { name?: string; description?: string }): void => {
        setOpen(false);
        rawUpdate({ name, description });
    };

    return (
        <>
            {hasWritePermission && (
                <Button
                    appearance="secondary"
                    icon={<Pencil />}
                    onClick={(): void => {
                        setOpen(!open);
                    }}
                />
            )}

            <ChartEditModal
                name={name}
                description={description}
                handleRequestClose={handleRequestClose}
                open={open}
                update={update}
            />
        </>
    );
};
