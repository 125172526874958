import React, { useState } from 'react';
import { PageHeaderWrapper } from '../../../../../app/header/PageHeaderWrapper';
import { PageHeaderProps } from '@splunk/olly-common';
import { ChartPageHeaderEdit, ChartPageHeaderEditProps } from './ChartPageHeaderEdit';
import styled from 'styled-components';
import { ChartPageHeaderActions, ChartPageHeaderActionsProps } from './ChartPageHeaderActions';
import {
    ResolutionPill,
    ResolutionPillProps,
} from '../../../charting/chartdisplay/tableChart/chartDirectives';

const StyledChartEditTitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledResolutionPillContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

type ChartPageHeaderWrapperProps = Pick<PageHeaderProps, 'description' | 'navigation'> &
    Pick<ChartPageHeaderActionsProps, 'onTrackClick'> & {
        actionsData: ChartPageHeaderActionsProps;
        editData: ChartPageHeaderEditProps;
        resolutionData: ResolutionPillProps;
    };

export const ChartPageHeaderWrapper = ({
    actionsData,
    description: rawDescription,
    editData,
    navigation: rawNavigation,
    onTrackClick,
    resolutionData,
}: ChartPageHeaderWrapperProps): React.JSX.Element => {
    const [navigation, setNavigation] = useState(rawNavigation);
    const [description, setDescription] = useState(rawDescription);

    const updateWrapper = ({
        name,
        description,
    }: {
        name?: string;
        description?: string;
    }): void => {
        setNavigation(
            (navigation ?? []).slice(0, -1).concat({ ...navigation.slice(-1)?.[0], label: name })
        );
        setDescription(description);
        editData?.update({ name, description });
    };

    return (
        <PageHeaderWrapper
            description={description}
            navigation={navigation}
            rightContent={
                <>
                    {actionsData && (
                        <ChartPageHeaderActions
                            allowSave={actionsData.allowSave}
                            canSaveToDashboard={actionsData.canSaveToDashboard}
                            canSaveToParent={actionsData.canSaveToParent}
                            close={actionsData.close}
                            hasCreateChartCapability={actionsData.hasCreateChartCapability}
                            onTrackClick={onTrackClick}
                            save={actionsData.save}
                            saveAs={actionsData.saveAs}
                            showSaveAsOnly={actionsData.showSaveAsOnly}
                        />
                    )}
                </>
            }
            titleContent={
                <StyledChartEditTitleContainer>
                    {editData && (
                        <ChartPageHeaderEdit
                            name={editData.name}
                            hasWritePermission={editData.hasWritePermission}
                            description={editData.description}
                            update={updateWrapper}
                        />
                    )}
                    {resolutionData && (
                        <StyledResolutionPillContainer>
                            <ResolutionPill
                                misalignedResolution={resolutionData.misalignedResolution}
                                resolution={resolutionData.resolution}
                            />
                        </StyledResolutionPillContainer>
                    )}
                </StyledChartEditTitleContainer>
            }
        />
    );
};
