angular.module('signalview.heatmap').service('heatmapDataService', [
    '$rootScope',
    'signalStream',
    '$log',
    '$interval',
    '_',
    'heatmapEventData',
    'INACTIVE_HOST_DURATIONS',
    'mustache',
    'plotToSignalflowV2',
    '$timeout',
    'chartDisplayUtils',
    'heatmapUtilsService',
    function (
        $rootScope,
        signalStream,
        $log,
        $interval,
        _,
        heatmapEventData,
        INACTIVE_HOST_DURATIONS,
        mustache,
        plotToSignalflowV2,
        $timeout,
        chartDisplayUtils,
        heatmapUtilsService
    ) {
        const STREAM_START_DATA_TIMEOUT = 1000 * 10;

        // Host many data points that are null before assuming host is dead
        const DEAD_HOST_PERIOD_LIMIT = 5;

        // minimum timerange lookback time
        const MIN_HEATMAP_HISTORY_LOOKBACK = -1 * 15 * 60000;

        const REFRESH_INTERVAL = 1000 * 60;
        const SPECIAL_EVENT_METRIC_PREFIX = '___SF_ALERT';

        function getEventData(heatmap, metadataIdToTsid) {
            const colorBy = heatmap.colorBy();
            let aggregation = heatmap.mode().requiredProperties;

            if (colorBy.alertAggregation) {
                aggregation = colorBy.alertAggregation;
            }

            let filters = heatmap.getDashboardFilters(true);
            if (colorBy.alertFilters) {
                filters = filters.concat(
                    colorBy.alertFilters.map((f) => {
                        return [f.property, f.propertyValue];
                    })
                );
            }

            return heatmapEventData.getAlertCounts(aggregation, filters).then(function (results) {
                const idHasData = {};

                const data = results.map(function (result) {
                    const id = heatmap.getId(result);
                    idHasData[id] = true;
                    return {
                        key: id,
                        value: result.value,
                    };
                });

                Object.keys(metadataIdToTsid)
                    .filter(function (id) {
                        return !idHasData[id];
                    })
                    .forEach(function (id) {
                        data.push({
                            key: id,
                            value: 0,
                        });
                    });

                return data;
            });
        }

        function create(heatmap) {
            const scope = $rootScope.$new();

            let metadataUpdates = [];

            function flushMetadataUpdates() {
                if (!metadataUpdates.length) return;
                scope.$emit('metadata', metadataUpdates);
                metadataUpdates = [];
            }

            const api = {};

            api.on = scope.$on.bind(scope);

            let stopped = false;
            let streamStartTimeout = null;
            let streamObject;
            let alertInterval;

            let missingDatapointCounter = {};
            let tsidToMetadata = {};
            let metadataIdToTsid = {};
            let latestEventData = null;
            let latestDeadHosts = [];

            function resetInternal() {
                metadataUpdates = [];
                missingDatapointCounter = {};
                tsidToMetadata = {};
                metadataIdToTsid = {};
            }

            function getThresholdingSignalFlow(varName) {
                const thresholdDefinition = heatmap.threshold();
                const groups = getThresholdGroupBy();

                if (!thresholdDefinition) {
                    return null;
                }

                const keyProperties = heatmap.mode().requiredProperties;

                //group against population for now
                const text = thresholdDefinition.signalflow(varName, groups, keyProperties);

                if (text) {
                    return text;
                } else {
                    return null;
                }
            }

            function loadEventData() {
                const colorBy = heatmap.colorBy();

                if (colorBy.id.indexOf(SPECIAL_EVENT_METRIC_PREFIX) !== 0) return;

                getEventData(heatmap, metadataIdToTsid).then(function (data) {
                    latestEventData = data;
                    emitEventData();
                });
            }

            function getThresholdGroupBy() {
                const groupBy = heatmap.groupBy() || [];
                return groupBy.slice(0, heatmap.groupByDepth());
            }

            const emitEventData = _.debounce(function () {
                latestEventData.forEach(function (datum) {
                    if (latestDeadHosts.indexOf(datum.key) !== -1) {
                        datum.value = undefined;
                    }
                });

                scope.$emit('data', latestEventData);
            });

            function startStreaming() {
                if (heatmap.mode().type !== 'elemental') return;

                function streamStartCallback(id) {
                    $log.debug('Infranav job id', id);
                    $log.info('Streaming has started');

                    $timeout.cancel(streamStartTimeout);
                    streamStartTimeout = $timeout(function () {
                        $log.warn('Stream start timeout');
                        scope.$emit('no data');
                    }, STREAM_START_DATA_TIMEOUT);

                    resetInternal();
                }

                function metaDataUpdated(metadata, tsid) {
                    if (stopped) return;

                    if (tsid in tsidToMetadata) {
                        $log.debug('Updating metadata for existing tsid ' + tsid);
                    }

                    tsidToMetadata[tsid] = metadata;

                    if (metadata.sf_streamLabel === 'heatmap data') {
                        if (!('id' in metadata)) {
                            metadata.id = heatmap.getId(metadata);
                        }
                        if (!('sf_idDisplayName' in metadata)) {
                            metadata.sf_idDisplayName = heatmap.getIdDisplayName(metadata);
                        }

                        if (metadata.id in metadataIdToTsid) {
                            $log.debug(
                                'Multiple TSIDs for given metadata id ' +
                                    metadata.id +
                                    ' (' +
                                    tsid +
                                    ', ' +
                                    metadataIdToTsid[metadata.id] +
                                    ')'
                            );
                        }

                        metadataIdToTsid[metadata.id] = tsid;
                        missingDatapointCounter[tsid] = 0;
                        metadataUpdates.push(angular.copy(metadata));
                    }
                }

                const emitData = _.debounce(function (data) {
                    latestDeadHosts = [];
                    data.forEach(function (datum) {
                        if (datum.value === undefined) {
                            latestDeadHosts.push(datum.key);
                        }
                    });

                    if (heatmap.colorBy().id.indexOf(SPECIAL_EVENT_METRIC_PREFIX) === 0) {
                        if (latestEventData) {
                            emitEventData();
                        }
                    } else {
                        scope.$emit('data', data);
                    }
                }, 100);

                const emitStats = _.debounce(function (data) {
                    if (heatmap.colorBy().id.indexOf(SPECIAL_EVENT_METRIC_PREFIX) === -1) {
                        scope.$emit('stats', data);
                    }
                }, 100);

                const emitLimitReached = _.debounce(function (data) {
                    scope.$emit('limit reached', data);
                }, 100);

                function callback(tsidToData) {
                    if (stopped) return;

                    //hack to get metadata, to be removed pending analytics
                    if (!alertInterval) {
                        setupAlertInterval();
                    }

                    const data = [];
                    let statHistogram = {};

                    Object.keys(tsidToData).forEach(function (tsid) {
                        const metadata = tsidToMetadata[tsid];
                        const datapoint = tsidToData[tsid];
                        let latestValue;

                        if (metadata.sf_streamLabel === 'outlier threshold') {
                            // we assume that the response data possesses at minimum the groupby data. order is important
                            const prunedKeys = getThresholdGroupBy();
                            latestValue = datapoint.value;
                            if (!prunedKeys.length) {
                                statHistogram = latestValue;
                            } else {
                                let positionPointer = statHistogram;

                                prunedKeys.forEach(function (key, i) {
                                    const tsidDatum = metadata[key];

                                    if (i === prunedKeys.length - 1) {
                                        positionPointer[tsidDatum] = latestValue;
                                    } else {
                                        if (!positionPointer[tsidDatum]) {
                                            positionPointer[tsidDatum] = {};
                                        }

                                        positionPointer = positionPointer[tsidDatum];
                                    }
                                });
                            }
                        } else if (metadata.sf_streamLabel === 'heatmap data') {
                            // For every null value reported, increment missing datapoint counter
                            if (datapoint.value === null) {
                                missingDatapointCounter[tsid]++;
                            } else if (missingDatapointCounter[tsid] !== 0) {
                                // if we find a value, reset the missing datapoint counter,
                                // we only care about consecutive nulls.
                                missingDatapointCounter[tsid] = 0;
                            }

                            latestValue = datapoint.value;
                            if (missingDatapointCounter[tsid] >= missingDatapointLimit) {
                                latestValue = undefined;
                            }

                            data.push({
                                key: metadata.id,
                                value: latestValue,
                            });
                        } else {
                            $log.error('Unrecognized datapoint from tsid ' + tsid);
                        }
                    });

                    if (data.length) {
                        $timeout.cancel(streamStartTimeout);
                        // Flush any pending metadata prior to emitting data
                        flushMetadataUpdates();
                        emitData(data);
                    }

                    if (angular.isNumber(statHistogram) || Object.keys(statHistogram).length) {
                        emitStats(statHistogram);
                    }
                }

                function getSignalflow(heatmap, colorBy) {
                    const filterBy = heatmap.filterBy() || [];

                    const varName = colorBy.job.varName;
                    let signalTextToStream = mustache.render(colorBy.job.template, {
                        filter: plotToSignalflowV2.filters(
                            filterBy.concat(
                                heatmapUtilsService.convertEntityMetricFiltersToPropertyFilters(
                                    colorBy.job.filters || []
                                )
                            )
                        ),
                    });

                    signalTextToStream += '\n' + varName + '.publish("heatmap data")';

                    const statText = getThresholdingSignalFlow(varName);
                    if (statText) {
                        signalTextToStream += '\n' + statText;
                    }

                    return signalTextToStream;
                }

                stopStreaming();

                const colorBy = heatmap.colorBy();
                if (!colorBy) {
                    $log.warn('Heatmap has no color by defined');
                    return;
                }

                $log.debug('Starting heatmap data service streaming.', colorBy);
                scope.$emit('loading');

                const signalFlowText = getSignalflow(heatmap, colorBy);

                function getHistoryRange() {
                    const duration = heatmap.inactiveHostDuration();

                    // We add the resolution at the end to ensure we prevent any datapoint
                    // delay issues from preventing dead hosts from being recognized due
                    // to datapoint alignment issues
                    if (duration === INACTIVE_HOST_DURATIONS.AUTO) {
                        return (
                            colorBy.job.resolution * DEAD_HOST_PERIOD_LIMIT + colorBy.job.resolution
                        );
                    } else {
                        return duration + 2 * colorBy.job.resolution;
                    }
                }

                function getMissingDatapointLimit(resolution) {
                    const duration = heatmap.inactiveHostDuration();

                    if (duration === INACTIVE_HOST_DURATIONS.AUTO) {
                        return DEAD_HOST_PERIOD_LIMIT;
                    } else {
                        return duration / resolution;
                    }
                }

                function updateResolution(resolution) {
                    missingDatapointLimit = getMissingDatapointLimit(resolution);
                }

                let missingDatapointLimit = getMissingDatapointLimit(colorBy.job.resolution);

                const options = {
                    bulk: true,
                    resolution: colorBy.job.resolution,
                    historyrange: null,
                    fallbackResolutionMs: 5 * 60 * 1000,
                    signalFlowText: signalFlowText,
                    ephemeral: true,
                    withDerivedMetadata: true,
                    offsetByMaxDelay: true,
                    resolutionAdjustable: false,
                    streamStartCallback: streamStartCallback,
                    // Metadata is always called before datapoints arrive
                    metaDataUpdated: metaDataUpdated,
                    // tsid to array of timestamp, value tuples
                    callback: callback,
                    onFeedback: function (feedback) {
                        if (feedback && feedback.length) {
                            const jobFetchCaps = {
                                numTimeSeriesLimitReached: 0,
                                messages: [],
                            };
                            feedback.forEach(function (feedbackItem) {
                                if (feedbackItem.messageCode === 'JOB_RUNNING_RESOLUTION') {
                                    updateResolution(feedbackItem.contents.resolutionMs);
                                }
                                if (feedbackItem.messageCode === 'FIND_LIMITED_RESULT_SET') {
                                    jobFetchCaps.numTimeSeriesLimitReached += 1;
                                    jobFetchCaps.messages.push(feedbackItem);
                                }
                            });
                            // picked up by olly to display analytics limit message for heatmaps
                            emitLimitReached(jobFetchCaps);
                        }
                    },
                };

                const customTimeRange = heatmap.customTimeRange();
                if (customTimeRange) {
                    // customTimeRange object has the format of time values in chart configuration
                    // so it can be easily converted by time functions in chartDisplayUtils.
                    options.stopTime = chartDisplayUtils.getEndDurationFromConfig(
                        false,
                        customTimeRange
                    );
                    if (heatmap.hideDeadHosts()) {
                        // Use "time slice" mode when no need to show dead hosts from the past
                        // The calculation was taken/simplified from chartDisplayUtils as
                        // backfillSliceCount * targetResolution = 2 * 10000 = 20000
                        options.historyrange = options.stopTime - 20000;
                    } else {
                        options.historyrange = chartDisplayUtils.getFetchDurationFromConfig(
                            false,
                            customTimeRange
                        );
                    }

                    // Ensure the start time is at least 15m prior to the current time. Otherwise there's
                    // a potential that MTS lag may be longer than the requested duration, and the streaming
                    // job may stall and not report data until real time has accounted for the lag.
                    if (options.historyrange > MIN_HEATMAP_HISTORY_LOOKBACK) {
                        $log.debug(
                            'Setting heatmap history range (' +
                                options.historyrange +
                                ') to minimum lookback time (' +
                                MIN_HEATMAP_HISTORY_LOOKBACK +
                                ')'
                        );
                        options.historyrange = MIN_HEATMAP_HISTORY_LOOKBACK;
                        // as an optimization, disable offset as we've already accounted for lag in this case
                        options.offsetByMaxDelay = false;
                    }
                } else {
                    options.historyrange = -1 * getHistoryRange();
                }

                options.useCache = true;

                $log.debug('Starting new stream');
                streamObject = signalStream.stream(options);
            }

            function setupAlertInterval() {
                if (stopped) return;

                loadEventData();

                alertInterval = $interval(function () {
                    if (stopped) return;
                    loadEventData();
                }, REFRESH_INTERVAL);
            }

            function cancelAlertInterval() {
                $interval.cancel(alertInterval);
                alertInterval = null;
            }

            api.destroy = function () {
                $log.debug('Destroying heatmap data service');
                stopped = true;
                cancelAlertInterval();

                if (streamObject) {
                    streamObject.stopStream();
                }
            };

            function stopStreaming() {
                $log.debug('Stopping heatmap data service streaming!');

                if (streamObject) {
                    streamObject.stopStream();
                }

                $timeout.cancel(streamStartTimeout);

                cancelAlertInterval();
            }
            function restart() {
                loadEventData();

                if (streamObject) {
                    $log.debug('Restarting heatmap data service streaming.');
                    scope.$emit('reset');
                } else {
                    $log.debug('Starting heatmap data service streaming.');
                }
                startStreaming();
            }

            const debouncedRestart = _.debounce(restart, 1000);

            api.init = function () {
                restart();
                heatmap.on('threshold updated', debouncedRestart);
                heatmap.on('groupByDepth updated', debouncedRestart);
                heatmap.on('colorBy updated', debouncedRestart);
                heatmap.on('filterBy updated', debouncedRestart);
                heatmap.on('customTimeRange updated', debouncedRestart);
                heatmap.on('inactiveHostDuration updated', debouncedRestart);
                heatmap.on('selection updated', loadEventData);
                // The heatmap needs to update when "hideDeadHosts" updates from "true" to "false"
                // to capture dead hosts from the past.
                heatmap.on('hideDeadHosts updated', function (e, value) {
                    if (value === false) {
                        debouncedRestart();
                    }
                });
            };

            return api;
        }

        return {
            create: create,
        };
    },
]);
