import { AngularInjector } from '../../../../common/AngularUtils';

RoleTokenServiceFactory.$inject = ['$http', '$log', 'API_URL'];
export default function RoleTokenServiceFactory($http, $log, API_URL) {
    const BASE_URL = `${API_URL}/v2/role`;

    this.getAllRoles = () => {
        return $http
            .get(BASE_URL)
            .then((response) => response.data)
            .catch((e) => {
                $log.error(e.message);
                throw e;
            });
    };
}

RoleTokenServiceFactory.useInstance = () =>
    AngularInjector.useInjectedClass(RoleTokenServiceFactory);
