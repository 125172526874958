import templateUrl from '../resolutionPill.tpl.html';
import { bindings } from '../resolutionPill';
import { samplingAndRenderingIcons } from '../samplingAndRenderingIcons';
import { archivedMetricsWarningIcon } from '../archivedMetricsWarningIcon';
import { singleLineAlertTicker } from '../singleLineAlertTicker/singleLineAlertTicker';
import { withNg2React } from '../../../../../common/util/Ng2React';

const ResolutionPillComponent = {
    templateUrl,
    bindings,
};

export type ResolutionPillProps = {
    alternateTooltipText?: string;
    misalignedResolution?: boolean;
    resolution?: string;
    rollupMessage?: string;
};

export const ResolutionPill = withNg2React<ResolutionPillProps>({
    name: 'resolution-pill',
    definition: ResolutionPillComponent,
});

export const SamplingAndRenderingIcon = withNg2React({
    name: 'sampling-and-rendering-icons',
    definition: samplingAndRenderingIcons,
});

export const ArchivedMetricsWarningIcon = withNg2React({
    name: 'archived-metrics-warning-icon',
    definition: archivedMetricsWarningIcon,
});

export const SingleLineAlertTicker = withNg2React({
    name: 'single-line-alert-ticker',
    definition: singleLineAlertTicker,
});
