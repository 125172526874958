import React, { useState } from 'react';
import { PageHeaderWrapper } from '../../../../../app/header/PageHeaderWrapper';
import { PageHeaderProps } from '@splunk/olly-common';
import { DetectorPageHeaderEdit, DetectorPageHeaderEditProps } from './DetectorPageHeaderEdit';
import styled from 'styled-components';
import {
    ResolutionPill,
    ResolutionPillProps,
} from '../../../charting/chartdisplay/tableChart/chartDirectives';

const StyledDetectorEditTitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledResolutionPillContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    width: 30px;
`;

const StyledDetectorPageHeaderDescription = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

type DetectorPageHeaderWrapperProps = Pick<PageHeaderProps, 'description' | 'navigation'> & {
    editData: DetectorPageHeaderEditProps;
    resolutionData: ResolutionPillProps;
};

export const DetectorPageHeaderWrapper = ({
    description: rawDescription,
    editData,
    navigation: rawNavigation,
    resolutionData,
}: DetectorPageHeaderWrapperProps): React.JSX.Element => {
    const [navigation, setNavigation] = useState(rawNavigation);
    const [description, setDescription] = useState(rawDescription);

    const updateWrapper = ({
        name,
        description,
    }: {
        name?: string;
        description?: string;
    }): void => {
        setNavigation(
            (navigation ?? []).slice(0, -1).concat({ ...navigation.slice(-1)?.[0], label: name })
        );
        setDescription(description);
        editData?.update({ name, description });
    };
    return (
        <PageHeaderWrapper
            navigation={navigation}
            description={
                <StyledDetectorPageHeaderDescription>
                    {description}
                </StyledDetectorPageHeaderDescription>
            }
            titleContent={
                <StyledDetectorEditTitleContainer>
                    {editData && (
                        <DetectorPageHeaderEdit
                            name={editData.name}
                            update={updateWrapper}
                            description={editData.description}
                        />
                    )}
                    {
                        // Always take up space even if not defined to avoid shoving around other elements
                    }
                    {
                        <StyledResolutionPillContainer>
                            <ResolutionPill
                                misalignedResolution={resolutionData.misalignedResolution}
                                resolution={resolutionData.resolution}
                                rollupMessage={resolutionData.rollupMessage}
                                alternateTooltipText={resolutionData.alternateTooltipText}
                            />
                        </StyledResolutionPillContainer>
                    }
                </StyledDetectorEditTitleContainer>
            }
        />
    );
};
