import React, { useEffect, useState } from 'react';
import BEMHelper from 'react-bem-helper';
import { ThemeProvider } from '@splunk/olly-common';
import { useLegacyThemeServiceKey } from '../../../../common/theme/ThemeProvider';
import RoleTokenServiceFactory from '../../../../legacy/common/data/apiv2/roleTokenService';
import PropTypes from 'prop-types';
import Select from '@splunk/react-ui/Select';

const className = new BEMHelper('default-sso-role-configuration');

// roles are case-insensitive
// find the matched role title in org roles to ensure it is selected in dropdown
// return undefined if rawRole is invalid or not found
function getStandardRoleTitle(rawRole, rolesList) {
    if (!rawRole) {
        return undefined;
    }
    const roleName = rawRole.toLowerCase();
    return rolesList.find((role) => roleName === role.title.toLowerCase())?.title;
}

export default function DefaultSsoRoleConfiguration({
    label,
    value,
    onChange,
    additionalInfo,
    newRbacCustomRoleEnabled,
}) {
    const roleService = RoleTokenServiceFactory.useInstance();
    const themeKey = useLegacyThemeServiceKey();

    const [roleOptions, setRoleOptions] = useState([]);
    const [defaultRole, setDefaultRole] = useState('');

    useEffect(() => {
        const fetchRoles = async () => {
            const orgRoles = await roleService.getAllRoles();
            // do not show custom roles (immutable: false) if feature flag is not enabled
            const availableRoles = newRbacCustomRoleEnabled
                ? orgRoles
                : orgRoles.filter((role) => role.immutable);
            // sort the roles based on role title for display
            availableRoles.sort((a, b) => a.title.localeCompare(b.title));
            setRoleOptions(availableRoles);
        };
        fetchRoles();
    }, [roleService, newRbacCustomRoleEnabled]);

    // set defaultRole to validated current value or undefined (dropdown displays placeholder text)
    useEffect(() => {
        setDefaultRole(getStandardRoleTitle(value, roleOptions));
    }, [value, roleOptions]);

    const handleSelectionChange = (e, { value }) => {
        setDefaultRole(value);
        onChange(value);
    };

    return (
        <ThemeProvider colorScheme={themeKey}>
            <div {...className()}>
                <label>
                    <span {...className('input-label')}>{label}</span>
                </label>
                <div {...className('additional-information')}>{additionalInfo}</div>
                <Select
                    {...className('dropdown-select')}
                    placeholder="Select a role..."
                    value={defaultRole}
                    onChange={handleSelectionChange}
                >
                    {roleOptions.map((role) => (
                        <Select.Option key={role.id} label={role.title} value={role.title} />
                    ))}
                </Select>
            </div>
        </ThemeProvider>
    );
}

DefaultSsoRoleConfiguration.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    additionalInfo: PropTypes.string,
    newRbacCustomRoleEnabled: PropTypes.bool,
};
