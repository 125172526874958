import React, { useEffect, useState } from 'react';
import { react2angular } from 'react2angular';
import TabBar from '@splunk/react-ui/TabBar';
import styled from 'styled-components';
import { ThemeProvider } from '@splunk/olly-common';
import { useLegacyThemeServiceKey } from '../../../common/theme/ThemeProvider';

const Routes = {
    ALERTS: 'alerts',
    DETECTORS: 'detectors',
    SLO: 'slo',
    DETECTOR_MUTING: 'muting',
};

const NAV_ITEMS = [
    {
        id: Routes.ALERTS,
        label: 'Active alerts',
        path: `/${Routes.ALERTS}/:orgId`,
        track: 'alert-tab-active-alerts',
    },
    {
        id: Routes.DETECTORS,
        label: 'Detectors',
        path: `/${Routes.DETECTORS}/:orgId`,
        track: 'alert-tab-detectors',
    },
    {
        id: Routes.SLO,
        label: 'Service level objectives',
        path: `/${Routes.SLO}`,
        track: 'alert-tab-slo',
    },
    {
        id: Routes.DETECTOR_MUTING,
        label: 'Muting rules',
        path: `/${Routes.DETECTOR_MUTING}/:orgId`,
        track: 'alert-tab-muting',
    },
];

const NavBar = styled(TabBar)`
    padding-left: 8px;
`;

const AlertTabs = ({ selected, currentUser, userAnalytics }) => {
    const colorScheme = useLegacyThemeServiceKey();
    const [orgId, setOrgId] = useState('');

    const handleClick = (event, { selectedTabId }) => {
        const { track } = NAV_ITEMS.find(({ id }) => id === selectedTabId);
        userAnalytics.event('click', track);
    };

    useEffect(() => {
        currentUser.orgId().then(setOrgId);
    }, [currentUser, setOrgId]);

    return (
        <ThemeProvider colorScheme={colorScheme}>
            <NavBar activeTabId={selected} onChange={handleClick}>
                {NAV_ITEMS.map((item) => (
                    <TabBar.Tab
                        key={item.id}
                        tabId={item.id}
                        label={item.label}
                        to={`#${item.path.replace(':orgId', orgId)}`}
                    />
                ))}
            </NavBar>
        </ThemeProvider>
    );
};

/** filters name is bound as a way to force react component rerender whenever filters are updated and url params changes */
angular
    .module('signalview.alerts')
    .component(
        'alertTabs',
        react2angular(AlertTabs, ['selected', 'filters'], ['currentUser', 'userAnalytics'])
    );
