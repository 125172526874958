angular.module('signalview.detector.wizard').directive('variableSuggestionFilter', [
    '$timeout',
    '$document',
    function ($timeout, $document) {
        return {
            scope: {
                getTabSuggestion: '<',
            },
            link: function ($scope, $element) {
                const inputElement = $element[0];
                const inputType = inputElement.dataset.suggestId;
                const REGEX_FIND_WORD = /{+[^\S\n]*([^\s}]*)/;
                const REGEX_HELPER_FUNCTION = /{{#([^\s}]*)/;
                const helperFunctions = ['#if', '#notEmpty', '#each', '#unless'];

                let findWordDelay;
                function findWordDebounced() {
                    $timeout.cancel(findWordDelay);
                    findWordDelay = $timeout(emitFilterText);
                }

                function emitFilterText() {
                    const filterText = findWordToFilter();

                    if (helperFunctions.includes(filterText)) {
                        $scope.$emit('filter text', '', filterText);
                    } else {
                        $scope.$emit('filter text', filterText);
                    }
                }

                function findWordToFilter() {
                    const text = inputElement.value;
                    const selectionStart = findFirstBracket(text, inputElement.selectionStart);

                    if (selectionStart === -1 || text.charAt(selectionStart) !== '{') {
                        return '';
                    }

                    const word = text.substring(selectionStart);
                    const matchedText = word.match(REGEX_FIND_WORD);
                    if (matchedText) {
                        return matchedText[1];
                    }
                }

                function findFirstBracket(text, selectionStart) {
                    if (text.charAt(selectionStart) === '}') {
                        selectionStart = selectionStart - 1;
                    }

                    if (selectionStart >= 0) {
                        while (text.charAt(selectionStart) !== '{') {
                            if (selectionStart === 0 || text.charAt(selectionStart) === '}') {
                                return -1;
                            }
                            selectionStart -= 1;
                        }

                        while (selectionStart === 0 || text.charAt(selectionStart) === '{') {
                            selectionStart -= 1;
                        }

                        return selectionStart + 1;
                    } else {
                        return -1;
                    }
                }

                function findLengthOfWordAfterHelper(text, selectionStart) {
                    const word = text.substring(selectionStart);
                    const matches = word.match(/[^\S\n]*[^\s}]*[^\S\n]*}*/);
                    if (matches && matches.length) {
                        return matches[0].length;
                    }
                }

                function findLengthOfReplacingWord(text, selectionStart) {
                    const word = text.substring(selectionStart);
                    const matches = word.match(/{{2,}[^\S\n]*[^\s}]*[^\S\n]*}*/);
                    if (matches && matches.length) {
                        return matches[0].length;
                    }
                    return 0;
                }

                function findFirstWordAfterHelper(text, selectionStart) {
                    while (
                        text.charAt(selectionStart) !== ' ' &&
                        text.charAt(selectionStart) !== ''
                    ) {
                        if (text.charAt(selectionStart) === '}') {
                            return selectionStart;
                        }
                        selectionStart += 1;
                    }
                    selectionStart += 1;

                    return selectionStart;
                }

                function replaceTextWithSuggestion(suggestion) {
                    const text = findWordToFilter();

                    if (helperFunctions.includes(text)) {
                        let firstIndex = findFirstBracket(
                            inputElement.value,
                            inputElement.selectionStart
                        );
                        firstIndex = findFirstWordAfterHelper(inputElement.value, firstIndex);
                        const wordLength = findLengthOfWordAfterHelper(
                            inputElement.value,
                            firstIndex
                        );
                        const wordToAdd = suggestion.match(REGEX_FIND_WORD)[1];
                        addWordToText(wordToAdd + '}}', wordLength, firstIndex);
                    } else if (text) {
                        const firstIndex = findFirstBracket(
                            inputElement.value,
                            inputElement.selectionStart
                        );
                        const wordLength = findLengthOfReplacingWord(
                            inputElement.value,
                            firstIndex
                        );
                        addWordToText(suggestion, wordLength, firstIndex);
                    } else {
                        if ($document.selection) {
                            inputElement.focus();
                            const selectedText = $document.selection.createRange();
                            selectedText.text = suggestion;
                        } else if (
                            inputElement.selectionStart ||
                            inputElement.selectionStart === 0
                        ) {
                            const startPos = inputElement.selectionStart;
                            const endPos = inputElement.selectionEnd;
                            const scrollTop = inputElement.scrollTop;
                            inputElement.value =
                                inputElement.value.substring(0, startPos) +
                                suggestion +
                                inputElement.value.substring(endPos);
                            inputElement.selectionStart = startPos + suggestion.length;
                            inputElement.selectionEnd = startPos + suggestion.length;
                            inputElement.scrollTop = scrollTop;
                        } else {
                            inputElement.value += suggestion;
                        }
                    }

                    const suggestionMatch = suggestion.match(REGEX_FIND_WORD);
                    if (suggestionMatch) {
                        if (helperFunctions.includes(suggestionMatch[1])) {
                            const helperFunction = suggestion.match(REGEX_HELPER_FUNCTION)[1];
                            const selectionStart = inputElement.selectionStart;
                            addWordToText(`{{/${helperFunction}}}`, 0, selectionStart);
                            inputElement.selectionStart = selectionStart - 2;
                            inputElement.selectionEnd = inputElement.selectionStart;
                        }
                    }
                    inputElement.focus();

                    $scope.$emit('custom message updated', inputElement.value, inputType);
                    $scope.$emit('filter text', '');
                }

                $scope.$on('suggestions added', function (e, suggestion, type) {
                    if (inputType !== type) {
                        return;
                    }

                    replaceTextWithSuggestion(suggestion);
                });

                function addWordToText(word, replacingLength, firstIndex) {
                    inputElement.value =
                        inputElement.value.substring(0, firstIndex) +
                        word +
                        inputElement.value.substring(firstIndex + replacingLength);
                    inputElement.selectionStart = firstIndex + word.length;
                    inputElement.selectionEnd = firstIndex + word.length;
                }
                $element.keyup(findWordDebounced);
                $element.click(findWordDebounced);
                $element.focus(findWordDebounced);
                $element.keydown((ev) => {
                    if (ev.keyCode === 9) {
                        const filterText = findWordToFilter();
                        if (filterText !== '') {
                            $scope.$apply();
                            const suggestion = $scope.getTabSuggestion(inputType);
                            if (suggestion !== null) {
                                ev.preventDefault();
                                ev.stopPropagation();
                                replaceTextWithSuggestion(suggestion);
                            }
                        }
                    }
                });
            },
        };
    },
]);
