angular.module('signalview.chartbuilder').directive('dropdownToggle2', [
    '$document',
    '$uibPosition',
    '$timeout',
    function ($document, $uibPosition, $timeout) {
        let openElement = null;
        return {
            restrict: 'CA',
            scope: {
                isOpen: '=?',
            },
            link: function (scope, element, attrs) {
                const popSource = element.closest('.dropdown');
                const popMenu = popSource.find('.dropdown-menu');
                let closeOnNextClick = false;
                const direction = attrs.direction || 'up';

                function closeMenu(event) {
                    const isChildOfMenu =
                        event && !closeOnNextClick ? popMenu.find(event.target).length > 0 : false;

                    const isDestroyedElm = event
                        ? angular.element(event.target).closest('.sf-ui').length === 0
                        : false;
                    const isClickedElementChildOfPopup = isChildOfMenu || isDestroyedElm;

                    if (!isClickedElementChildOfPopup) {
                        if (angular.isDefined(scope.isOpen)) {
                            scope.isOpen = false;
                        }

                        popMenu.css({
                            display: 'none',
                        });
                        element.parent().removeClass('open');

                        openElement = null;
                    }
                }

                function closeMenuApply(event) {
                    closeMenu(event);
                    scope.$apply();
                }

                const scrollParent = popSource.scrollParent();
                scrollParent.append(popMenu);
                popMenu.append(
                    '<input style="width:1px;height:1px;padding:0;border:none;position:absolute;top:-99999px;" class="sf-tab-catcher">'
                );

                if (angular.isDefined(attrs.alwaysClose)) {
                    closeOnNextClick = true;
                }

                function positionMenu(evt, scrollIntoView) {
                    if (evt && angular.element(evt.target).closest('.dropdown-menu').length !== 0) {
                        return;
                    }

                    const centeringOffset = popMenu.innerWidth() / 2 + popSource.innerWidth() / 2;
                    const popSourceOffset = $uibPosition.offset(popSource);
                    const scrollParentOffset = $uibPosition.offset(scrollParent);

                    const position = $uibPosition.offset(popSource);
                    position.left =
                        popSourceOffset.left - scrollParentOffset.left + scrollParent.scrollLeft();
                    position.top =
                        popSourceOffset.top - scrollParentOffset.top + scrollParent.scrollTop();
                    position.top =
                        position.top +
                        (direction === 'up'
                            ? -1 * popSource.innerHeight()
                            : popSource.innerHeight());
                    position.left = position.left + popSource.innerWidth() - centeringOffset;

                    const popupStyle = {
                        top: Math.max(0, position.top) + 'px',
                        left: Math.max(0, position.left) + 'px',
                        zIndex: '1070',
                    };

                    popMenu.css(popupStyle);
                    if (scrollIntoView) {
                        const scrollObj = popMenu[0];
                        if (scrollObj.scrollIntoViewIfNeeded) {
                            scrollObj.scrollIntoViewIfNeeded(true);
                        } else {
                            scrollObj.scrollIntoView(true);
                        }
                    }
                }

                function throttlePositionMenu(evt) {
                    $timeout.cancel(scope.repositionTimeout);
                    scope.repositionTimeout = $timeout(function () {
                        positionMenu(evt);
                    }, 250); // not sure if we still use this menu
                }

                function onKeyPress(e) {
                    if (
                        angular.element(e.target).hasClass('sf-tab-catcher') ||
                        (e.keyCode === 9 && popMenu.find(e.target).length === 0) ||
                        e.keyCode === 27
                    ) {
                        closeMenu();
                        scope.$emit('refocus-analytics');
                    }
                }

                function openPopup() {
                    element.parent().addClass('open');

                    window.setTimeout(function () {
                        // as this event can cause an element to lose focus, we have to defer
                        // positioning logic until the DOM updates
                        popMenu.css({
                            display: 'block',
                            top: 0,
                            left: 0,
                        });
                        positionMenu(null, true);
                    }, 0);
                    openElement = element;
                    //scope.openStateAttribute = true;
                    scope.isOpen = true;

                    //this is required due to async loading of templates in popups.  not sure if we should
                    //support this mechanism as it requires somewhat hacky async focusing
                    $timeout(function () {
                        angular.element(':focusable', popMenu).first().focus();
                    }, 0);

                    if (!scope.eventsBound) {
                        $document.bind('click', closeMenuApply);
                        $document.bind('mousewheel', throttlePositionMenu);
                        scrollParent.bind('keyup', onKeyPress);
                        scope.eventsBound = true;
                    }
                }

                if (angular.isDefined(scope.isOpen)) {
                    scope.$watch('isOpen', function (openState) {
                        if (openState) {
                            openPopup();
                        } else {
                            closeMenu();
                        }
                    });
                }

                scope.$on('$destroy', function () {
                    popMenu.remove();
                    $document.unbind('click', closeMenuApply);
                    $document.unbind('mousewheel', throttlePositionMenu);
                    scrollParent.unbind('keyup', onKeyPress);
                });

                popSource.bind('click', function (event) {
                    const elementWasOpen = element === openElement;

                    scope.isOpen = !elementWasOpen;

                    if (!!openElement) {
                        closeMenu();
                    }

                    if (!elementWasOpen) {
                        openPopup();
                    }
                    event.preventDefault();
                    event.stopPropagation();
                });

                if (scope.isOpen === true) {
                    openPopup();
                }
            },
        };
    },
]);
