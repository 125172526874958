import React, { useState } from 'react';
import Tooltip, { TooltipPropsBase } from '@splunk/react-ui/Tooltip';
import { ThemeProvider, useLegacyThemeServiceKey } from '../theme/ThemeProvider';
type TooltipProps = {
    component: React.ReactNode;
} & Pick<TooltipPropsBase, 'content' | 'defaultPlacement' | 'contentRelationship'>;

export const CommonTooltip: React.FC<TooltipProps> = ({
    content,
    component,
    defaultPlacement,
    contentRelationship,
}: TooltipProps) => {
    const themeKey = useLegacyThemeServiceKey();
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

    return (
        <ThemeProvider colorScheme={themeKey}>
            <Tooltip
                content={content}
                contentRelationship={contentRelationship}
                defaultPlacement={defaultPlacement}
                open={isTooltipOpen}
                onRequestOpen={(): void => setIsTooltipOpen(true)}
                onRequestClose={(event, data): void => {
                    if (data.reason !== 'toggleClick') setIsTooltipOpen(false);
                }}
            >
                {typeof component === 'string' ? <span tabIndex={0}>{component}</span> : component}
            </Tooltip>
        </ThemeProvider>
    );
};
