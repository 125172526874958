import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { NAVIGATOR_VIEW_TYPE_INSTANCE, DESTINATION_TYPE_NAVIGATOR } from './crossLinkUtils';

export default {
    bindings: {
        redirection: '<',
    },
    template: '<div></div>',
    controller: [
        // service injections
        '$location',
        '$log',
        '$scope',
        '$q',
        'dashboardV2Service',
        'timepickerUtils',
        'crossLinkUtils',
        'urlOverridesService',
        'featureEnabled',
        'dashboardVariablesService',
        function (
            $location,
            $log,
            $scope,
            $q,
            dashboardV2Service,
            timepickerUtils,
            crossLinkUtils,
            urlOverridesService,
            featureEnabled,
            dashboardVariablesService
        ) {
            const $ctrl = this;
            $ctrl.$onInit = initialize;

            function initialize() {
                const { destinationType } = $ctrl.redirection;
                if (destinationType === DESTINATION_TYPE_NAVIGATOR) {
                    handleNavigatorRedirect();
                } else {
                    handleDashboardRedirect();
                }
            }

            function handleDashboardRedirect() {
                const { fromDashboardId, toDashboardId } = $ctrl.redirection;

                let { fromDashboardGroupId, toDashboardGroupId } = $ctrl.redirection;

                const dashboardViewsEnabled = featureEnabled('dashboardViews');

                const promises = { toDashboard: dashboardV2Service.get(toDashboardId) };

                // fromDashboardId is only defined when clicking through a cross link
                // from another dashboard.
                if (fromDashboardId) {
                    promises.fromDashboard = dashboardV2Service
                        .get(fromDashboardId)
                        // probably best to not fail the whole redirect if the GET of the
                        // fromDashboard fails
                        .catch(() => $log.error('Failed to retrieve fromDashboard'));
                }

                $q.all(promises)
                    .then(({ toDashboard, fromDashboard }) => {
                        const queryParams = [getTimeQueryStr()];

                        toDashboardGroupId = dashboardViewsEnabled
                            ? toDashboardGroupId || toDashboard.groupId
                            : toDashboard.groupId;

                        if (dashboardViewsEnabled) {
                            queryParams.push(`groupId=${toDashboardGroupId}`);
                        }

                        const targetFilters = [];
                        const targetVariables = getDashboardVariables(toDashboard);

                        // only carry source filters when the fromDashboard and the toDashboard
                        // are within the same group
                        if (fromDashboard) {
                            fromDashboardGroupId = dashboardViewsEnabled
                                ? fromDashboardGroupId || fromDashboard.groupId
                                : fromDashboard.groupId;

                            if (toDashboardGroupId === fromDashboardGroupId) {
                                const sourceFilters =
                                    urlOverridesService.getSourceFilterOverrideList() || [];
                                targetFilters.push(...sourceFilters);
                            }
                        }

                        // Adds a source filter based on the clicked value if the cross link
                        // was of the form 'dimension:*' e.g. there is a cross link on 'host:*'
                        // and the user clicks host:i-whatever, then a filter for this host
                        // gets created.
                        addSelectedValueFilter(targetFilters);
                        queryParams.push(...getFilterQueryParams(targetFilters, targetVariables));

                        let url = `dashboard/${toDashboard.id}`;

                        if (queryParams.length) {
                            url += `?${queryParams.join('&')}`;
                        }

                        // set new url and replace so that it is possible to back out.
                        $location.url(url);
                        $location.replace();
                        $scope.$apply();
                    })
                    .catch((response) => {
                        // If response.status is missing, it is not related to dashboard fetching
                        if (response.status && response.status !== 401) {
                            $location.url(response.status === 404 ? '/404' : '/error');
                            $location.replace();
                            $scope.$apply();
                        }
                    });
            }

            function handleNavigatorRedirect() {
                const {
                    toNavigatorId,
                    toNavigatorView,
                    toNavigatorCode,
                    mapSelection,
                    breadcrumbFilters,
                    navigatorContext,
                    metricClass,
                    visualization,
                    navDashboardId,
                } = $ctrl.redirection;
                if (toNavigatorCode) {
                    const queryParams = [getTimeQueryStr()];

                    const navId = toNavigatorCode || toNavigatorId;

                    // if it's instance, then add as mapSelection
                    if (mapSelection && toNavigatorView === NAVIGATOR_VIEW_TYPE_INSTANCE) {
                        queryParams.push(`mapSelection=${mapSelection}`);
                    }

                    if (!mapSelection || navigatorContext === 'k8snodes') {
                        const targetFilters = [];
                        // in aggregate case, include any additional filters that were set
                        const sourceFilters =
                            urlOverridesService.getSourceFilterOverrideList() || [];
                        targetFilters.push(...sourceFilters);

                        // Adds a source filter based on the clicked value if the cross link
                        // was of the form 'dimension:*' e.g. there is a cross link on 'host:*'
                        // and the user clicks host:i-whatever, then a filter for this host
                        // gets created.
                        addSelectedValueFilter(targetFilters);
                        queryParams.push(...getFilterQueryParams(targetFilters, []));
                    }

                    if (navigatorContext) {
                        queryParams.push(`navigatorContext=${navigatorContext}`);
                    }
                    if (breadcrumbFilters) {
                        queryParams.push(
                            `breadcrumbFilters=${encodeURIComponent(breadcrumbFilters)}`
                        );
                    }
                    if (metricClass) {
                        queryParams.push(`metricClass=${metricClass}`);
                    }
                    if (visualization) {
                        queryParams.push(`visualization=${visualization}`);
                    }
                    if (navDashboardId) {
                        queryParams.push(`dashboardId=${navDashboardId}`);
                    }

                    let url = `infra/entity/${navId}`;

                    if (queryParams.length) {
                        url += `?${queryParams.join('&')}`;
                    }

                    // set new url and replace so that it is possible to back out.
                    $location.url(url);
                    $location.replace();
                } else {
                    // no matching navigator was found (eg: may have been deleted)
                    $location.url('/404');
                    $location.replace();
                }
                $scope.$apply();
            }

            function getDashboardVariables(dashboard) {
                const variables = safeLookup(dashboard, 'filters.variables') || [];
                const overrides = dashboardVariablesService.getVariablesUrlOverrideAsModel() || [];
                const applicableOverrides = {};

                // Prepopulate dashboard variables with saved values from the target
                // dashboard
                variables.forEach(
                    (variable) => (applicableOverrides[variable.property] = variable)
                );

                // Override saved variables with values found in url overrides. Overrides
                // on properties for which we find no dashboard variables are ignored
                // here.
                overrides.forEach((override) => {
                    const matchingVariable = variables.find(
                        (v) => v.property === override.property
                    );

                    if (matchingVariable) {
                        matchingVariable.value = override.value;
                        applicableOverrides[matchingVariable.property] = matchingVariable;
                    }
                });

                return applicableOverrides;
            }

            function addSelectedValueFilter(filters) {
                const selections = urlOverridesService.getCrossLinkSelection();

                if (!selections || selections.length === 0) {
                    return;
                }

                selections.forEach((selection) => {
                    const selectionAsFilter = {
                        NOT: false,
                        applyIfExists: false,
                        property: selection.propertyName,
                        value: selection.propertyValue,
                    };

                    const matchingFilter = filters.find(
                        (filter) => filter.property === selectionAsFilter.property
                    );

                    if (matchingFilter) {
                        angular.extend(matchingFilter, selectionAsFilter);
                    } else {
                        filters.push(selectionAsFilter);
                    }
                });
            }

            function getFilterQueryParams(filters, variables) {
                filters.forEach((filter) => {
                    if (variables[filter.property]) {
                        variables[filter.property].value = filter.value;
                    }
                });

                const filterQuery = filters
                    .filter((filter) => !variables[filter.property])
                    .map((filter) => {
                        const sourceFilterVal = `${filter.NOT ? '!' : ''}${
                            filter.property
                        }:${crossLinkUtils.stringifyParamValue(filter.value)}`;
                        return `sources[]=${encodeURIComponent(sourceFilterVal)}`;
                    });

                const variableQuery = Object.values(variables)
                    .filter((variable) => variable.value)
                    .map((variable) => {
                        const variableVal = `${variable.alias}=${
                            variable.property
                        }:${crossLinkUtils.stringifyParamValue(variable.value)}`;
                        return `variables[]=${encodeURIComponent(variableVal)}`;
                    });

                return filterQuery.concat(variableQuery);
            }

            function getTimeQueryStr() {
                const timePicker = urlOverridesService.getGlobalTimePicker();
                return timepickerUtils.getURLParamStringForTimePicker(timePicker);
            }
        },
    ],
};
