import React from 'react';
import AutoDetectComponent from '@splunk/olly-common/AutoDetectLabel';
import {
    DetectorOrigin,
    isOOTBDetectorCustomizationOrigin,
} from '@splunk/olly-common/AutoDetectLabel/AutoDetectLabel';
import { react2angular } from 'react2angular';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../../common/theme/ThemeProvider';

interface AutoDetectComponentProps {
    detectorOrigin: DetectorOrigin;
    alertTooltip?: boolean;
    styles?: React.CSSProperties;
}

export const AutoDetectLabel: React.FC<AutoDetectComponentProps> = ({
    detectorOrigin,
    alertTooltip,
    styles,
}) => {
    const themeKey = useLegacyThemeServiceKey();
    const tooltipBase = alertTooltip ? 'AutoDetect Detector event' : 'AutoDetect Detector';
    const tooltip = isOOTBDetectorCustomizationOrigin(detectorOrigin)
        ? `Customized ${tooltipBase}`
        : tooltipBase;
    return (
        <ThemeProvider colorScheme={themeKey}>
            <AutoDetectComponent
                detectorOrigin={detectorOrigin}
                styles={styles}
                tooltip={tooltip}
            />
        </ThemeProvider>
    );
};

export const autoDetectLabel = react2angular(AutoDetectLabel, [
    'detectorOrigin',
    'styles',
    'alertTooltip',
]);
