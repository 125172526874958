import React, { useState } from 'react';
import Button from '@splunk/react-ui/Button';
import Pencil from '@splunk/react-icons/Pencil';
import { DetectorEditModal } from './DetectorEditModal';

export type DetectorPageHeaderEditProps = {
    description?: string;
    hasWritePermission?: boolean;
    name?: string;
    update: (params: { name?: string; description?: string }) => void;
};

export const DetectorPageHeaderEdit = ({
    description,
    name,
    update: rawUpdate,
}: DetectorPageHeaderEditProps): React.JSX.Element => {
    const [open, setOpen] = useState(false);

    const handleRequestClose = (): void => {
        setOpen(false);
    };

    const update = ({ name, description }: { name?: string; description?: string }): void => {
        setOpen(false);
        rawUpdate({ name, description });
    };

    return (
        <>
            <Button
                appearance="secondary"
                icon={<Pencil />}
                onClick={(): void => {
                    setOpen(!open);
                }}
            />

            <DetectorEditModal
                name={name}
                description={description}
                handleRequestClose={handleRequestClose}
                open={open}
                update={update}
            />
        </>
    );
};
