import templateUrl from './dashboardChartWrapper.tpl.html';
import readOnlyTooltipTemplateUrl from '../../../common/tooltips/readOnlyTooltip.html';
import alertTooltipTemplate from '../../../common/tooltips/alertTooltipTemplate.html';
import { angularifyUri, angularifyUriQuery } from '../../../../common/urlparams/urlAngularify';
import { isChartDisplayTooType } from '../../charting/chart/chartVersionServiceModule';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

export default [
    '$location',
    '$rootScope',
    '$timeout',
    'chartbuilderUtil',
    'userAnalytics',
    'config',
    'chartUtils',
    'relatedDetectorService',
    'sharingService',
    'CHART_DISPLAY_EVENTS',
    'dashboardUtil',
    'programTextUtils',
    'newDashboardService',
    'chartVersionService',
    'urlOverridesService',
    'chartDisplayUtils',
    'featureEnabled',
    'STATIC_RESOURCE_ROOT',
    'chartV2Utils',
    'signalFlowInfoService',
    'clipboard',
    'SYSTEM_USER_ID',
    'dashboardChartServiceAPM2',
    'dashboardChartServiceProfiling',
    'dashboardChartServiceRUM',
    'SPLUNK_APM_PRODUCT_NAME',
    'SPLUNK_SYNTHETICS_PRODUCT_NAME',
    'SPLUNK_PROFILING_PRODUCT_NAME',
    'SPLUNK_RUM_PRODUCT_NAME',
    'themeService',
    'hasCapability',
    'ChartExportService',
    function (
        $location,
        $rootScope,
        $timeout,
        chartbuilderUtil,
        userAnalytics,
        config,
        chartUtils,
        relatedDetectorService,
        sharingService,
        CHART_DISPLAY_EVENTS,
        dashboardUtil,
        programTextUtils,
        newDashboardService,
        chartVersionService,
        urlOverridesService,
        chartDisplayUtils,
        featureEnabled,
        STATIC_RESOURCE_ROOT,
        chartV2Utils,
        signalFlowInfoService,
        clipboard,
        SYSTEM_USER_ID,
        dashboardChartServiceAPM2,
        dashboardChartServiceProfiling,
        dashboardChartServiceRUM,
        SPLUNK_APM_PRODUCT_NAME,
        SPLUNK_SYNTHETICS_PRODUCT_NAME,
        SPLUNK_PROFILING_PRODUCT_NAME,
        SPLUNK_RUM_PRODUCT_NAME,
        themeService,
        hasCapability,
        chartExportService
    ) {
        return {
            restrict: 'EA',
            scope: {
                configuration: '=options',
                onFullLoad: '&',
                onInitialize: '&',
                sharedChartState: '=',
                foldRow: '=',
                chart: '=',
                widget: '=',
                inView: '&?',
                chartUrlGenerator: ' =?',
                viewOnly: '=',
                filters: '=',
                chartModelModifier: '=?',
                filterAlias: '=',
                maxDelayOverride: '=?',
                getCurrentQuery: '=',
                dropdownState: '=',
                snapshot: '=?',
                saveSnapshot: '=?',
                editable: '=?',
                getFilterOverrides: '=?',
                eventOverlayParams: '<?',
                disableClick: '<?',
                isSidebarDashboard: '<?',
                allDashboardConfigs: '<?',
                dashboardIndex: '@',
            },
            require: ['^gridster', '^gridsterItem'],
            templateUrl,
            link: function ($scope, element) {
                $scope.STATIC_RESOURCE_ROOT = STATIC_RESOURCE_ROOT;
                $scope.getClipSize = () => clipboard.getClipSize();
                $scope.writePermissionsEnabled = featureEnabled('writepermissions');
                $scope.APM_PRODUCT_NAME = SPLUNK_APM_PRODUCT_NAME;
                $scope.SYNTHETICS_PRODUCT_NAME = SPLUNK_SYNTHETICS_PRODUCT_NAME;
                $scope.PROFILING_PRODUCT_NAME = SPLUNK_PROFILING_PRODUCT_NAME;
                $scope.RUM_PRODUCT_NAME = SPLUNK_RUM_PRODUCT_NAME;
                $scope.apm2Enabled = featureEnabled('apm2');
                $scope.syntheticsEnabled = featureEnabled('synthetics');
                $scope.profilingTroubleshootingEnabled =
                    featureEnabled('profilingUIFeatures') && featureEnabled('profilingMemoryUI');
                $scope.rumEnabled = featureEnabled('rum');
                $scope.dashboardCodeRefactorEnabled = featureEnabled('dashboardCodeRefactor');
                $scope.readOnlyTooltipTemplateUrl = readOnlyTooltipTemplateUrl;
                $scope.alertTooltipTemplate = alertTooltipTemplate;
                $scope.processedChart = $scope.chart;
                const isV2Chart = $scope.chart
                    ? chartVersionService.getVersion($scope.chart) === 2
                    : false;
                const isBuiltInChart =
                    $scope.chart.creator === SYSTEM_USER_ID &&
                    !config('superpower.serviceDiscoveryBuilder');
                $scope.isBuiltInChart = isBuiltInChart;
                $scope.isV2Chart = isV2Chart;
                $scope.dashboardViewsEnabled = featureEnabled('dashboardViews');

                if ($scope.allDashboardConfigs) {
                    $scope.allDashboardConfigs.then((configs) => {
                        $scope.numMirrors = configs.length;
                        $scope.numGroups = _.uniq(
                            configs.filter(Boolean).map((config) => config.groupId)
                        ).length;
                    });
                }

                // Redirects user to the chart builder and auto-adds a new plot to link
                // a specific detector (or a blank detector plot to be filled by the user)
                $scope.linkDetector = function (detector) {
                    // Empty detector id just enables the input field without prefilling it.
                    const detectorId = detector ? detector.sf_id || detector.id : 'new';

                    // Update the query parameter for linkDetector to let the chart controller
                    // know what it should do upon route load
                    urlOverridesService.setLinkDetector(detectorId);

                    // Redirect the browser to the chart builder
                    $location.path('/chart/' + $scope.chartId);
                };

                function onProgramInfo(programInfo) {
                    const backupChart = chartDisplayUtils.createConformingModelFromV2($scope.chart);
                    chartDisplayUtils.processKnownLabels(programInfo, backupChart, []);
                    return backupChart;
                }

                $scope.requisiteV2ChartDataReady = true;
                let replacedFilters = null;
                if (isV2Chart) {
                    //V2 Charts need to have the publish order ready to go for a synchronous conversion.  unfortunately this
                    //means the parent has to deal with this for now, as chartdisplay does not support async models
                    $scope.requisiteV2ChartDataReady = false;
                    // todo : this could be pretty heavy in terms of http calls, maybe make this lazy/bulk
                }
                $scope.chartId = null;
                if ($scope.chart) {
                    $scope.chartId = $scope.chart.id || $scope.chart.sf_id;
                }

                hasCapability(Capability.CREATE_DETECTOR).then(
                    (hasCreateDetectorCapability) =>
                        ($scope.hasCreateDetectorCapability = hasCreateDetectorCapability)
                );

                $scope.hasCreateChartCapability = false;
                hasCapability(Capability.CREATE_CHART).then(
                    (hasCreateChartCapability) =>
                        ($scope.hasCreateChartCapability = hasCreateChartCapability)
                );

                hasCapability(Capability.CREATE_SHAREABLE_SNAPSHOT).then(
                    (hasCreateShareableSnapshotCapability) =>
                        ($scope.hasCreateShareableSnapshotCapability =
                            hasCreateShareableSnapshotCapability)
                );

                $scope.dropdownState = {};
                $scope.chartsImmutable = true;
                $scope.hideLegend = true;
                $scope.legendHelper = {};
                $scope.jobFeedback = [];
                $scope.$on(CHART_DISPLAY_EVENTS.DERIVED_STREAM_INITIATED, function (evobj, jobId) {
                    $scope.viewJobId = jobId;
                });
                $scope.currentModel = $scope.processedChart;
                $scope.defaultChartLink =
                    $scope.currentModel.chartLinks?.length &&
                    $scope.currentModel.chartLinks.find((link) => link.default);
                $scope.isTextOrEvent = function () {
                    return (
                        $scope.processedChart &&
                        $scope.processedChart.sf_uiModel &&
                        ($scope.processedChart.sf_uiModel.chartMode === 'text' ||
                            $scope.processedChart.sf_uiModel.chartMode === 'event')
                    );
                };

                $scope.hideAlertSubmenu = function (index) {
                    if (document.getElementById(`alert-submenu-${index}`)) {
                        document
                            .getElementById(`alert-submenu-${index}`)
                            .classList.remove('subMenuDisplay');
                    }
                };

                $scope.$on(CHART_DISPLAY_EVENTS.CHART_DISPLAY_INITIALIZED, function () {
                    $scope.chartDisplayInitialized = true;
                });

                $scope.deleteMode = false;

                if ($scope.snapshot && $scope.snapshot.id) {
                    $scope.portletSnapshot = angular.copy($scope.snapshot);
                    $scope.portletSnapshot.sourceId = null;
                    $scope.portletSnapshot.chartId = $scope.chartId;
                } else {
                    $scope.portletSnapshot = {};
                }

                $scope.enterDeleteMode = function () {
                    $scope.deleteMode = true;
                };

                $scope.cancelDelete = function () {
                    $scope.deleteMode = false;
                };

                $scope.showAdditionalInfo = function () {
                    $scope.$broadcast('show chart info');
                };

                $scope.isChartDisplayTooType = function (candidateChart) {
                    if (!candidateChart || (isV2Chart && !$scope.requisiteV2ChartDataReady)) {
                        return undefined;
                    }

                    return isChartDisplayTooType(candidateChart);
                };

                $scope.removeWidget = function () {
                    $scope.$emit('removeGridsterItem', {
                        widget: $scope.widget,
                        chartId: $scope.chartId,
                    });
                    userAnalytics.event('chart', 'remove');
                };

                if (!$scope.chartUrlGenerator) {
                    $scope.chartUrlGenerator = function () {
                        return '';
                    };
                }

                $scope.chartTypeSupportsAlerts = function (chartMode) {
                    return chartMode !== 'logs' && chartMode !== 'logsTimeSeries';
                };

                $scope.setLinkUrl = function (linkUrl) {
                    $scope.linkUrl = linkUrl;
                };

                $scope.getOpenUrl = function (chartMode) {
                    if (chartMode === 'logs' || chartMode === 'logsTimeSeries') {
                        if ($scope.linkUrl) {
                            return angularifyUri($scope.linkUrl);
                        }
                    }

                    if (!$scope.processedChart) {
                        return null;
                    }

                    if ($scope.portletSnapshot.id) {
                        return angularifyUri(
                            '#/temp/chart/' +
                                $scope.snapshot.id +
                                '?chartId=' +
                                $scope.chartId +
                                '&' +
                                $scope.chartUrlGenerator()
                        );
                    } else if (isBuiltInChart) {
                        return angularifyUri(
                            '#/chart/' +
                                'new?fromChart=' +
                                $scope.chartId +
                                '&' +
                                $scope.chartUrlGenerator()
                        );
                    } else {
                        return angularifyUri(
                            '#/chart/' + $scope.chartId + '?' + $scope.chartUrlGenerator()
                        );
                    }
                };

                $scope.getOpenTitle = function (chartMode) {
                    if (chartMode === 'logs' || chartMode === 'logsTimeSeries') {
                        return 'View in Log Observer';
                    }
                    return 'Open';
                };

                let initAlertPromise = null;
                $scope.mouseEnterBell = function () {
                    if (!initAlertPromise) {
                        initAlertPromise = $scope.initAlert();
                    }
                };

                function getOverrideFilter(filterAlias) {
                    const overrides = chartUtils.getChartOverrides(filterAlias);
                    return overrides.map(function (override) {
                        return (
                            (override.not ? '!' : '') + override.key + ':"' + override.value + '"'
                        );
                    });
                }

                function getMetricsAndFiltersInChart(chart) {
                    const metricsAndFiltersInChart = chartUtils.getMetricsAndFiltersInChart(chart);
                    metricsAndFiltersInChart.filters = metricsAndFiltersInChart.filters.filter(
                        function (filter) {
                            return getOverrideFilter($scope.filterAlias).indexOf(filter) === -1;
                        }
                    );
                    return metricsAndFiltersInChart;
                }

                $scope.initAlert = function () {
                    const chart = $scope.processedChart;
                    chart.alert = null;

                    const mandf = getMetricsAndFiltersInChart(chart);

                    const metricSelectors = mandf.metrics;
                    const filterSelectors = getOverrideFilter($scope.filterAlias).concat(
                        mandf.filters
                    );

                    return relatedDetectorService
                        .getDetectorsForContext(
                            metricSelectors,
                            filterSelectors,
                            $scope.getCurrentQuery,
                            chart.sf_creator === SYSTEM_USER_ID ? null : chart
                        )
                        .then(
                            function (alertSet) {
                                // Processed chart ref may change during the HTTP call, so we can't
                                // used local chart var for this assignment.
                                $scope.processedChart.alert = alertSet;
                            },
                            function () {
                                // failure
                                initAlertPromise = null;
                                $scope.processedChart.alert = null;
                            }
                        );
                };

                let isFetchingV2Info = false;

                $scope.updateInitializationState = function () {
                    if ($scope.initReady) {
                        return;
                    }
                    const isVisible = $scope.widget.row <= $scope.foldRow || $scope.inView();

                    if (isVisible && isV2Chart && !isFetchingV2Info) {
                        isFetchingV2Info = true;
                        // If we've already bound alerts to this chart object, we need to
                        // transfer them to the new object so that we don't have to fetch
                        // the data again (or worse, have it be wiped out).
                        const prevAlerts = $scope.processedChart && $scope.processedChart.alert;
                        signalFlowInfoService
                            .addRequest($scope.chart.programText)
                            .then(function (data) {
                                // cloning plots as they will be mutated during the plot conversion process.
                                const plots = angular.copy(data.plots);
                                const v1 = chartV2Utils.convertPlots(plots, $scope.chart);
                                if (v1) {
                                    $scope.processedChart = v1;
                                    $scope.convertible = true;
                                } else {
                                    $scope.processedChart = onProgramInfo(data.programInfo);
                                }
                                $scope.processedChart.$isOriginallyV2 = true;
                                $scope.processedChart.alert = prevAlerts;
                                $scope.currentModel = $scope.processedChart;
                                $scope.requisiteV2ChartDataReady = true;
                                $scope.updateInitializationState();
                            });
                    }
                    const isReady = $scope.requisiteV2ChartDataReady && isVisible;

                    // returns undefined because it signals to the one-time binding in
                    // the template that the answer is not ready yet.
                    $scope.initReady = isReady ? true : undefined;
                };

                $scope.share = function () {
                    sharingService.shareChart($scope.chart, $scope.$parent.model);
                };

                $scope.newDashboardService = newDashboardService;

                function cleanChart() {
                    // Copy over vital fields
                    let chart;
                    if (chartVersionService.getVersion($scope.chart) !== 2) {
                        //v1 charts have a lot to clean
                        chart = dashboardUtil.cleanChart($scope.processedChart, true);
                        programTextUtils.refreshProgramText(chart);
                        chartbuilderUtil.prepareChartSave(chart);
                    } else {
                        //v2 charts just need to lose their id
                        chart = angular.copy($scope.chart);
                        delete chart.id;
                        delete chart.sf_id;
                        delete chart.alert;
                    }
                    return chart;
                }

                $scope.download = function () {
                    const chartObj = $scope.chart || $scope.processedChart;
                    chartExportService.downloadAsImage(element, chartObj);
                };

                $scope.exportAsCsv = function () {
                    $scope.$broadcast('export current chart');
                };

                $scope.exportEventAsJson = function () {
                    $scope.$broadcast('export event chart');
                };

                $scope.copy = function () {
                    let chart = cleanChart();
                    if (chartVersionService.getVersion($scope.chart) !== 2) {
                        chart = chartbuilderUtil.getSaveableModel(chart, null);
                        chart.id = $scope.chart.sf_id || $scope.chart.id;
                    }
                    delete chart.sf_chartIndex;

                    if (featureEnabled('ollyGlobalFiltersEnabled')) {
                        clipboard.addClip(chart, {
                            sourceElement: element[0],
                            themeKey: themeService.getColorScheme(),
                        });
                    } else {
                        $rootScope.$broadcast('clipboard:copyInProgress', {
                            source: element,
                            chart: chart,
                        });
                    }
                };

                $scope.setTroubleshootIndexParams = function () {
                    $timeout(() => {
                        $scope.troubleshootIndexParams = angularifyUriQuery(
                            dashboardChartServiceAPM2.getTroubleshootIndexParams(
                                $scope.chart,
                                $scope.filterAlias
                            )
                        );
                    }, 0);
                };

                $scope.setProfilingIndexParams = function () {
                    $timeout(() => {
                        $scope.profilingIndexParams = angularifyUriQuery(
                            dashboardChartServiceProfiling.getProfilingIndexParams(
                                $scope.chart,
                                $scope.filterAlias
                            )
                        );
                    }, 0);
                };

                $scope.setRUMIndexParams = function () {
                    $timeout(() => {
                        $scope.rumIndexParams = angularifyUriQuery(
                            dashboardChartServiceRUM.getRUMIndexParams(
                                $scope.chart,
                                $scope.filterAlias
                            )
                        );
                    }, 0);
                };

                function checkChartForProperty(prop) {
                    if (chartVersionService.getVersion($scope.chart) === 2) {
                        if (!replacedFilters) {
                            return false;
                        } else {
                            return replacedFilters.indexOf(prop) !== -1;
                        }
                    } else {
                        let hasKey = false;
                        $scope.chart.sf_uiModel.allPlots.forEach(function (plot) {
                            if (!plot.transient) {
                                plot.queryItems.forEach(function (filter) {
                                    if (filter.property === prop && !filter.NOT) {
                                        hasKey = true;
                                    }
                                });
                            }
                        });
                        return hasKey;
                    }
                }

                $scope.$on('variableHovered', function (payload, prop) {
                    if (prop !== null) {
                        $scope.chartReplaceFilterStr = checkChartForProperty(prop)
                            ? 'Override applied'
                            : null;
                    } else {
                        $scope.chartReplaceFilterStr = null;
                    }
                });

                $scope.$on(
                    CHART_DISPLAY_EVENTS.REPLACED_FILTER_REPORT,
                    function (payload, filters) {
                        replacedFilters = filters;
                    }
                );

                $scope.alertState = null;
                $scope.$on('alert state updated', function (evt, smokeyState) {
                    $scope.alertState = smokeyState.alertState;
                    $scope.alertStateClass = smokeyState.alertState
                        ? smokeyState.alertState.toLowerCase()
                        : '';
                    $scope.detectorAlertStates = $scope.detectorAlertStates =
                        chartUtils.getAlertMenuState(smokeyState.activeAlerts);
                });

                $scope.$on('dashboardScrolled', $scope.updateInitializationState);

                $scope.updateInitializationState();

                $scope.$on('React:$routeUpdate', function () {
                    initAlertPromise = null;
                    $scope.processedChart.alert = null;
                });

                /* executing event handler which ensures any open data table is closed when opening the dropdown menu".
                 */
                $scope.onDropdownMenuOpen = () => {
                    $rootScope.$broadcast(CHART_DISPLAY_EVENTS.CHART_CLOSE_TOOLTIPS);
                };
            },
        };
    },
];
