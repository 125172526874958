// Provides url overrides set/get.
// Objective as a service is to avoid param name dependencies
// across calling functions for overrides in dashboards, charts, etc.

import { DETECTOR_ORIGINS_TYPES, DETECTOR_ORIGINS_TYPES_AMOUNT } from '../consts';
import URL_PARAMETER_CONSTANTS from './URL_PARAMETER_CONSTANTS';

export default [
    '$location',
    '$log',
    '$timeout',
    'sourceFilterService',
    'TimeParser',
    function ($location, $log, $timeout, sourceFilterService, TimeParser) {
        const validDensities = [0.5, 1, 2, 4, 8];

        const RELATIVE_TIME_STRING = 'relative';
        const ABSOLUTE_TIME_STRING = 'absolute';
        const NON_FILTER_OVERRIDES = ['groupId', 'configId'];

        const api = {};

        /* Fully generic url params */
        api.hasHighlightDetectorPlot = defineGenericExists(
            URL_PARAMETER_CONSTANTS.highlightDetectorPlot
        );
        api.getHighlightDetectorPlot = defineGenericGetter(
            URL_PARAMETER_CONSTANTS.highlightDetectorPlot
        );
        api.clearHighlightDetectorPlot = defineGenericClear(
            URL_PARAMETER_CONSTANTS.highlightDetectorPlot
        );
        api.setHighlightDetectorPlot = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.highlightDetectorPlot,
            api.clearHighlightDetectorPlot
        );

        api.hasLinkDetector = defineGenericExists(URL_PARAMETER_CONSTANTS.linkDetector);
        api.getLinkDetector = defineGenericGetter(URL_PARAMETER_CONSTANTS.linkDetector);
        api.clearLinkDetector = defineGenericClear(URL_PARAMETER_CONSTANTS.linkDetector);
        api.setLinkDetector = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.linkDetector,
            api.clearLinkDetector
        );

        api.getTraceId = defineGenericGetter(URL_PARAMETER_CONSTANTS.traceId);
        api.clearTraceId = defineGenericClear(URL_PARAMETER_CONSTANTS.traceId);
        api.setTraceId = defineGenericSetter(URL_PARAMETER_CONSTANTS.traceId, api.clearTraceId);

        api.getActiveOn = defineGenericNumberGetter(URL_PARAMETER_CONSTANTS.activeOn, 'int');
        api.clearActiveOn = defineGenericClear(URL_PARAMETER_CONSTANTS.activeOn);
        api.setActiveOn = defineGenericSetter(URL_PARAMETER_CONSTANTS.activeOn, api.clearActiveOn);

        api.getIncludeInactive = defineGenericBoolGetter(URL_PARAMETER_CONSTANTS.includeInactive);
        api.clearIncludeInactive = defineGenericClear(URL_PARAMETER_CONSTANTS.includeInactive);
        api.setIncludeInactive = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.includeInactive,
            api.clearIncludeInactive
        );

        api.clearColorBy = defineGenericClear(URL_PARAMETER_CONSTANTS.colorBy);
        api.setColorBy = defineGenericSetter(URL_PARAMETER_CONSTANTS.colorBy, api.clearColorBy);
        api.getColorBy = defineGenericGetter(URL_PARAMETER_CONSTANTS.colorBy);

        api.clearEnvironment = defineGenericClear(URL_PARAMETER_CONSTANTS.environment);
        api.setEnvironment = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.environment,
            api.clearEnvironment
        );
        api.getEnvironment = defineGenericGetter(URL_PARAMETER_CONSTANTS.environment);

        api.getCreatedOn = defineGenericNumberGetter(URL_PARAMETER_CONSTANTS.createdOn, 'int');
        api.clearCreatedOn = defineGenericClear(URL_PARAMETER_CONSTANTS.createdOn);
        api.setCreatedOn = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.createdOn,
            api.clearCreatedOn
        );

        api.getFilterByTeam = defineGenericGetter(URL_PARAMETER_CONSTANTS.filterByTeam);
        api.clearFilterByTeam = defineGenericClear(URL_PARAMETER_CONSTANTS.filterByTeam);
        api.setFilterByTeam = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.filterByTeam,
            api.clearFilterByTeam
        );

        api.getMapSelection = defineGenericGetter(URL_PARAMETER_CONSTANTS.mapSelection);
        api.clearMapSelection = defineGenericClear(URL_PARAMETER_CONSTANTS.mapSelection);
        api.setMapSelection = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.mapSelection,
            api.clearMapSelection
        );

        api.getOutlierStrategy = defineGenericGetter(URL_PARAMETER_CONSTANTS.outlierStrategy);
        api.clearOutlierStrategy = defineGenericClear(URL_PARAMETER_CONSTANTS.outlierStrategy);
        api.setOutlierStrategy = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.outlierStrategy,
            api.clearOutlierStrategy
        );

        api.getOutlierDepth = defineGenericNumberGetter(
            URL_PARAMETER_CONSTANTS.outlierDepth,
            'int'
        );
        api.clearOutlierDepth = defineGenericClear(URL_PARAMETER_CONSTANTS.outlierDepth);
        api.setOutlierDepth = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.outlierDepth,
            api.clearOutlierDepth
        );

        api.getQueryParam = defineGenericGetter(URL_PARAMETER_CONSTANTS.query);
        api.clearQueryParam = defineGenericClear(URL_PARAMETER_CONSTANTS.query);
        api.setQueryParam = defineGenericSetter(URL_PARAMETER_CONSTANTS.query, api.clearQueryParam);

        api.getResolutionAdjustable = getResolutionAdjustable;
        api.clearResolutionAdjustable = defineGenericClear(
            URL_PARAMETER_CONSTANTS.resolutionAdjustable
        );
        api.setResolutionAdjustable = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.resolutionAdjustable,
            api.clearResolutionAdjustable
        );

        api.getTab = defineGenericGetter(URL_PARAMETER_CONSTANTS.tab);
        api.clearTab = defineGenericClear(URL_PARAMETER_CONSTANTS.tab);
        api.setTab = defineGenericSetter(URL_PARAMETER_CONSTANTS.tab, api.clearTab);

        api.getViewTime = defineGenericNumberGetter(URL_PARAMETER_CONSTANTS.viewTime, 'int');
        api.clearViewTime = defineGenericClear(URL_PARAMETER_CONSTANTS.viewTime);
        api.setViewTime = defineGenericSetter(URL_PARAMETER_CONSTANTS.viewTime, api.clearViewTime);

        api.clearCrossLinkSelection = defineGenericClear(URL_PARAMETER_CONSTANTS.viewTime);

        api.getAccessToken = defineGenericGetter(URL_PARAMETER_CONSTANTS.accessToken);
        api.clearAccessToken = defineGenericClear(URL_PARAMETER_CONSTANTS.accessToken);

        api.getForceAbsolute = defineGenericNumberGetter(
            URL_PARAMETER_CONSTANTS.forceAbsolute,
            'int'
        );
        api.getTargetOrg = defineGenericGetter(URL_PARAMETER_CONSTANTS.targetOrg);

        api.getDuration = defineGenericGetter(URL_PARAMETER_CONSTANTS.duration);
        api.clearDuration = defineGenericClear(URL_PARAMETER_CONSTANTS.duration);
        api.setDuration = defineGenericSetter(URL_PARAMETER_CONSTANTS.duration, api.clearDuration);

        api.getService = defineGenericGetter(URL_PARAMETER_CONSTANTS.service);
        api.clearService = defineGenericClear(URL_PARAMETER_CONSTANTS.service);
        api.setService = defineGenericSetter(URL_PARAMETER_CONSTANTS.service, api.clearService);

        api.getServices = defineGenericGetter(URL_PARAMETER_CONSTANTS.services);
        api.clearServices = defineGenericClear(URL_PARAMETER_CONSTANTS.services);
        api.setServices = defineGenericSetter(URL_PARAMETER_CONSTANTS.services, api.clearServices);

        api.getOperations = defineGenericGetter(URL_PARAMETER_CONSTANTS.operations);
        api.clearOperations = defineGenericClear(URL_PARAMETER_CONSTANTS.operations);
        api.setOperations = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.operations,
            api.clearOperations
        );

        api.getServiceEndpoints = getServiceEndpoints;
        api.clearServiceEndpoints = defineGenericClear(URL_PARAMETER_CONSTANTS.serviceEndpoints);
        api.setServiceEndpoints = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.serviceEndpoints,
            api.clearServiceEndpoints
        );

        api.getDetectorOrigins = getDetectorOrigins;
        api.clearDetectorOrigins = defineGenericClear(URL_PARAMETER_CONSTANTS.detectorOrigins);
        api.setDetectorOrigins = setDetectorOrigins;

        api.getMuted = defineGenericGetter(URL_PARAMETER_CONSTANTS.muted);
        api.clearMuted = defineGenericClear(URL_PARAMETER_CONSTANTS.muted);
        api.setMuted = defineGenericSetter(URL_PARAMETER_CONSTANTS.muted, api.clearMuted);

        api.getBusinessWorkflows = getBusinessWorkflows;
        api.clearBusinessWorkflows = defineGenericClear(URL_PARAMETER_CONSTANTS.businessWorkflows);
        api.setBusinessWorkflows = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.businessWorkflows,
            api.clearBusinessWorkflows
        );

        api.getTags = getTags;
        api.clearTags = defineGenericClear(URL_PARAMETER_CONSTANTS.tags);
        api.setTags = setTags;

        api.getStatus = defineGenericGetter(URL_PARAMETER_CONSTANTS.status);
        api.clearStatus = defineGenericClear(URL_PARAMETER_CONSTANTS.status);
        api.setStatus = defineGenericSetter(URL_PARAMETER_CONSTANTS.status, api.clearStatus);

        api.getSort = defineGenericGetter(URL_PARAMETER_CONSTANTS.sort);
        api.clearSort = defineGenericClear(URL_PARAMETER_CONSTANTS.sort);
        api.setSort = defineGenericSetter(URL_PARAMETER_CONSTANTS.sort, api.clearSort);

        api.getSelectedSpan = defineGenericGetter(URL_PARAMETER_CONSTANTS.selectedSpan);
        api.clearSelectedSpan = defineGenericClear(URL_PARAMETER_CONSTANTS.selectedSpan);
        api.setSelectedSpan = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.selectedSpan,
            api.clearSelectedSpan
        );

        api.getSpanCriteria = getSpanCriteria;
        api.clearSpanCriteria = defineGenericClear(URL_PARAMETER_CONSTANTS.spanCriteria);
        api.setSpanCriteria = setSpanCriteria;

        api.getSpanMatch = defineGenericGetter(URL_PARAMETER_CONSTANTS.spanMatch);
        api.clearSpanMatch = defineGenericClear(URL_PARAMETER_CONSTANTS.spanMatch);
        api.setSpanMatch = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.spanMatch,
            api.clearSpanMatch
        );

        api.isInfoSidebarHidden = defineGenericBoolGetter(URL_PARAMETER_CONSTANTS.hideInfoSidebar);
        api.hideInfoSidebar = defineGenericSetter(URL_PARAMETER_CONSTANTS.hideInfoSidebar);

        api.getFromChart = defineGenericGetter(URL_PARAMETER_CONSTANTS.fromChart);
        api.setFromChart = defineGenericSetter(URL_PARAMETER_CONSTANTS.fromChart);
        api.clearFromChart = defineGenericClear(URL_PARAMETER_CONSTANTS.fromChart);

        api.getActiveModal = defineGenericGetter(URL_PARAMETER_CONSTANTS.activeModal);
        api.setActiveModal = defineGenericSetter(URL_PARAMETER_CONSTANTS.activeModal);
        api.clearActiveModal = defineGenericClear(URL_PARAMETER_CONSTANTS.activeModal);

        api.getTokenExpiry = defineGenericGetter(URL_PARAMETER_CONSTANTS.tokenExpiry);
        api.clearTokenExpiry = defineGenericClear(URL_PARAMETER_CONSTANTS.tokenExpiry);
        api.setTokenExpiry = defineGenericSetter(
            URL_PARAMETER_CONSTANTS.tokenExpiry,
            api.clearTokenExpiry
        );

        api.useReplaceForLastChange = useReplaceForLastChange;
        api.getSearchParam = getSearchParam;
        api.setSearchParam = setSearchParam;
        api.doesParamExist = doesParamExist;
        api.clearParams = clearParams;
        api.getCrossLinkSelection = getCrossLinkSelection;
        api.getGlobalTimePicker = getGlobalTimePicker;
        api.getGlobalTimeAbsolute = getGlobalTimeAbsolute;
        api.clearTimePicker = clearTimePicker;
        api.setSourceOverride = setSourceOverride;
        api.setSourceOverrideFromDashboard = setSourceOverrideFromDashboard;
        api.setSourceFilterOverrideListFromLegacy = setSourceFilterOverrideListFromLegacy;
        api.setSourceFilterOverrideList = setSourceFilterOverrideList;
        api.getSourceOverride = getSourceOverride;
        api.getSourceFilterOverrideList = getSourceFilterOverrideList;
        api.setInfoSidebarSources = setInfoSidebarSources;
        api.getInfoSidebarSources = getInfoSidebarSources;
        api.clearInfoSidebarSources = clearInfoSidebarSources;
        api.setInfoSidebarSourcesList = setInfoSidebarSourcesList;
        api.clearSourceOverride = clearSourceOverride;
        api.getPointDensity = getPointDensity;
        api.getGroupBy = getGroupBy;
        api.setGroupBy = setGroupBy;
        api.getGroupBySelection = getGroupBySelection;
        api.setGroupBySelection = setGroupBySelection;
        api.clearGroupBy = clearGroupBy;
        api.clearGroupBySelection = clearGroupBySelection;
        api.setCatalogSelection = setCatalogSelection;
        api.getCatalogSelection = getCatalogSelection;
        api.getTopicFilter = getTopicFilter;
        api.setTopicFilter = setTopicFilter;
        api.clearTopicFilter = clearTopicFilter;
        api.clearCatalogSelection = clearCatalogSelection;
        api.setGlobalTimePicker = setGlobalTimePicker;
        api.clearAll = clearAll;
        api.clearAllNonLocationUrlParams = clearAllNonLocationUrlParams;
        api.clearPointDensity = clearPointDensity;
        api.setPointDensity = setPointDensity;
        api.setSelectedEventOverlays = setSelectedEventOverlays;
        api.getSelectedEventOverlays = getSelectedEventOverlays;
        api.unflattenPropertyValue = unflattenPropertyValue;
        return api;

        function toCrossLinkSelectionObject(str) {
            const [propertyName, ...propertyValueParts] = str.split(':');

            return {
                propertyName,
                propertyValue: propertyValueParts.join(':'),
            };
        }

        function getCrossLinkSelection() {
            const crossLinkSelection = getSearchParam(URL_PARAMETER_CONSTANTS.crossLinkSelection);

            if (!crossLinkSelection || crossLinkSelection.length === 0) {
                return null;
            }

            if (angular.isArray(crossLinkSelection)) {
                return crossLinkSelection.map(toCrossLinkSelectionObject);
            } else {
                return [toCrossLinkSelectionObject(crossLinkSelection)];
            }
        }

        // get global time picker params
        // no validation of time values
        function getGlobalTimePicker() {
            const startTimeRelative = getSearchParam(URL_PARAMETER_CONSTANTS.startTime.relative);
            const startTimeAbsolute = getSearchParam(URL_PARAMETER_CONSTANTS.startTime.absolute);

            if (!startTimeRelative && !startTimeAbsolute) {
                return null;
            }

            const isRelative = angular.isDefined(startTimeRelative);
            if (isRelative) {
                const endTimeRelative = getSearchParam(URL_PARAMETER_CONSTANTS.endTime.relative);
                return {
                    start: startTimeRelative,
                    end: endTimeRelative,
                    relative: true,
                };
            } else {
                const endTimeAbsolute = getSearchParam(URL_PARAMETER_CONSTANTS.endTime.absolute);
                return {
                    start: parseInt(startTimeAbsolute),
                    end: parseInt(endTimeAbsolute),
                    relative: false,
                };
            }
        }

        // clear time picker params
        function clearTimePicker() {
            clearParams([
                URL_PARAMETER_CONSTANTS.startTime.relative,
                URL_PARAMETER_CONSTANTS.startTime.absolute,
                URL_PARAMETER_CONSTANTS.endTime.relative,
                URL_PARAMETER_CONSTANTS.endTime.absolute,
            ]);
        }

        function getGlobalTimeAbsolute() {
            const globalTime = getGlobalTimePicker();
            if (globalTime) {
                let start, end;
                if (globalTime.relative) {
                    start = new TimeParser(globalTime.start).getUTC();
                    end = new TimeParser(globalTime.end, true).getUTC();
                } else {
                    start = globalTime.start;
                    end = globalTime.end;
                }
                return {
                    start,
                    end,
                    relative: globalTime.relative,
                };
            }
            return null;
        }

        /**
         * set source override
         */
        function setSourceOverride(sourceList) {
            setSearchParam(
                URL_PARAMETER_CONSTANTS.sourceOverride,
                sourceList,
                api.clearSourceOverride
            );
        }

        function setInfoSidebarSources(sourceList) {
            setSearchParam(
                URL_PARAMETER_CONSTANTS.infoSidebarSources,
                sourceList,
                api.clearInfoSidebarSources
            );
        }

        function setSourceOverrideFromDashboard(sourceList) {
            if (sourceList && sourceList.length) {
                const sourceFiltersAsStrings = getSourceFiltersAsString(sourceList);
                setSearchParam(URL_PARAMETER_CONSTANTS.sourceOverride, sourceFiltersAsStrings);
            } else {
                api.clearSourceOverride();
            }
        }

        /*
         * used to handle legacy sets(source filter model from a dashboard as saved filter)
         * set source filters from a list of the following format:
         * {
         *   NOT : boolean
         *   property: propertyName,
         *   value: property value string | list of property value strings,
         * }
         */
        function setSourceFilterOverrideListFromLegacy(overrides) {
            let calledOverrides;
            if (overrides === '') {
                calledOverrides = [];
            } else if (angular.isArray(overrides)) {
                calledOverrides = overrides.map(legacyFilterTransformer);
            } else {
                calledOverrides = legacyFilterTransformer(overrides);
            }
            api.setSourceFilterOverrideList(calledOverrides);
        }

        /**
         * set source filters from a list of
         * {
         *   applyIfExists: boolean,
         *   NOT: boolean,
         *   property: propertyName,
         *   propertyValue: property value string | list of property value strings,
         * }
         */
        function setSourceFilterOverrideList(overrides) {
            const flattenedList = flattenSourceOverrides(overrides);
            api.setSourceOverride(flattenedList);
        }

        function setInfoSidebarSourcesList(sourcesList) {
            const flattenedList = flattenSourceOverrides(sourcesList);
            api.setInfoSidebarSources(flattenedList);
        }

        function flattenSourceOverrides(overrides) {
            return overrides.map(encodeSourceOverride);
        }
        /**
         * get source override
         * @Deprecated
         */
        function getSourceOverride() {
            let params = getSearchParam(URL_PARAMETER_CONSTANTS.sourceOverride);

            if (!params) {
                params = null;
            }

            return params;
        }

        function getInfoSidebarSources() {
            const params = getSearchParam(URL_PARAMETER_CONSTANTS.infoSidebarSources);
            if (!params) {
                return null;
            } else {
                return mapSourceOverridesToFilters(params);
            }
        }

        /**
         * returns list of source filter overrides of the form
         * {
         *   property: propertyName,
         *   propertyValue: property value string | list of property value strings,
         * }
         */
        function getSourceFilterOverrideList() {
            let overrides = api.getSourceOverride();
            if (overrides) {
                overrides = mapSourceOverridesToFilters(overrides);
            }
            return overrides;
        }

        function mapSourceOverridesToFilters(overrides) {
            return overrides
                .map((override) => decodeSourceOverride(override, true))
                .filter((f) => f)
                .map(function (override) {
                    // add all expected filter properties to filter if it does not exist for data
                    // consistency and valid filter comparisons between an old and new state
                    sourceFilterService.assignIconClassToFilter(override);
                    sourceFilterService.assignTypeToFilter(override);
                    return override;
                });
        }

        // clear source override
        function clearSourceOverride() {
            clearParams([URL_PARAMETER_CONSTANTS.sourceOverride]);
        }

        function clearInfoSidebarSources() {
            clearParams([URL_PARAMETER_CONSTANTS.infoSidebarSources]);
        }

        // get point density
        function getPointDensity() {
            const density = getSearchParam(URL_PARAMETER_CONSTANTS.pointDensity);
            if (density && validDensities.indexOf(parseFloat(density)) > -1) {
                return parseFloat(density);
            } else {
                return null;
            }
        }

        // clear point density
        function clearPointDensity() {
            clearParams([URL_PARAMETER_CONSTANTS.pointDensity]);
        }

        //set point density
        function setPointDensity(pointDensity) {
            if (pointDensity) {
                setSearchParam(URL_PARAMETER_CONSTANTS.pointDensity, pointDensity);
            } else {
                api.clearPointDensity();
            }
        }

        // set selected event overlays
        function setSelectedEventOverlays(selectedEventOverlays) {
            if (selectedEventOverlays && selectedEventOverlays.length) {
                const selectedEventOverlaysAsStrings = selectedEventOverlays.map((eventOverlay) => {
                    let eventOverlayString;
                    if (eventOverlay.overlayId) {
                        eventOverlayString = eventOverlay.overlayId;
                    } else {
                        eventOverlayString =
                            eventOverlay.eventSignal.eventSearchText +
                            ':' +
                            eventOverlay.eventSignal.eventType;
                        if (eventOverlay.sources && eventOverlay.sources.length) {
                            eventOverlayString =
                                eventOverlayString +
                                ':' +
                                getSourceFiltersAsString(eventOverlay.sources).join(',');
                        }
                    }
                    return eventOverlayString;
                });

                setSearchParam(
                    URL_PARAMETER_CONSTANTS.selectedEventOverlays,
                    selectedEventOverlaysAsStrings
                );
            } else {
                clearParams([URL_PARAMETER_CONSTANTS.selectedEventOverlays]);
            }
        }

        function getSelectedEventOverlays() {
            let params = getSearchParam(URL_PARAMETER_CONSTANTS.selectedEventOverlays);
            if (params) {
                if (angular.isString(params)) {
                    params = [params];
                }
                return params.map((param) => {
                    if (param.indexOf(':') === -1) {
                        return {
                            overlayId: param,
                        };
                    }
                    const eventOverlay = param.split(':');
                    const searchText = eventOverlay[0];
                    const type = eventOverlay[1];
                    let sourcesArray = [];
                    if (eventOverlay.length > 2) {
                        const sourceFiltersStrings = eventOverlay.splice(2).join(':');
                        sourcesArray = sourceFiltersStrings
                            .split(',')
                            .map(getSourceFiltersFromString);
                    }
                    return {
                        eventSignal: {
                            eventSearchText: searchText,
                            eventType: type,
                        },
                        sources: sourcesArray,
                    };
                });
            } else {
                return [];
            }
        }

        function getGroupBy() {
            const param = getSearchParam(URL_PARAMETER_CONSTANTS.groupBy);
            return param ? param.split(',') : null;
        }

        function setGroupBy(groups) {
            if (groups && groups.length) {
                setSearchParam(URL_PARAMETER_CONSTANTS.groupBy, groups.join(','));
            } else {
                api.clearGroupBy();
            }
        }

        function getGroupBySelection() {
            const param = getSearchParam(URL_PARAMETER_CONSTANTS.groupBySelection);
            // Empty string is a valid group value
            return param !== undefined ? param.split(',') : null;
        }

        function setGroupBySelection(groups) {
            if (groups) {
                setSearchParam(URL_PARAMETER_CONSTANTS.groupBySelection, groups.join(','));
            } else {
                api.clearGroupBySelection();
            }
        }

        function clearGroupBy() {
            clearParams([URL_PARAMETER_CONSTANTS.groupBy]);
        }

        function clearGroupBySelection() {
            clearParams([URL_PARAMETER_CONSTANTS.groupBySelection]);
        }

        function setCatalogSelection(sel) {
            if (sel.sf_section) {
                clearParams([URL_PARAMETER_CONSTANTS.selectedId]);
                setSearchParam(URL_PARAMETER_CONSTANTS.selectedKeyValue, 'sf_section:' + sel.value);
            } else if (sel.sf_id) {
                clearParams([URL_PARAMETER_CONSTANTS.selectedKeyValue]);
                setSearchParam(URL_PARAMETER_CONSTANTS.selectedId, sel.sf_type + ':' + sel.sf_id);
            } else if (sel.key && !sel.value) {
                clearParams([URL_PARAMETER_CONSTANTS.selectedId]);
                if (sel.dimension) {
                    if (sel.key === 'host') {
                        setPreviewParams('sf_key', 'host', 'list');
                    } else {
                        setPreviewParams('sf_key', sel.key, null);
                    }
                } else {
                    setSearchParam(URL_PARAMETER_CONSTANTS.selectedKeyValue, sel.key + ':*');
                }
            } else if (sel.type && !sel.value) {
                clearParams([URL_PARAMETER_CONSTANTS.selectedId]);
                setSearchParam(URL_PARAMETER_CONSTANTS.selectedKeyValue, 'sf_type:' + sel.type);
            } else {
                clearParams([URL_PARAMETER_CONSTANTS.selectedId]);
                setPreviewParams(sel.key, sel.value, sel.key === 'host' ? 'dashboard' : null);
            }
            if ($location.hash()) $location.hash('');
        }

        function getCatalogSelection() {
            const keyValue = getSearchParam(URL_PARAMETER_CONSTANTS.selectedKeyValue);
            const id = getSearchParam(URL_PARAMETER_CONSTANTS.selectedId);

            if (id) {
                const idsrc = id.split(':');
                const type = idsrc[0];
                const synthid = idsrc[1];

                return {
                    sf_id: synthid,
                    sf_type: type,
                };
            } else if (keyValue) {
                const src = keyValue.split(':');
                const key = src[0];
                const value = src.slice(1).join(':');

                return {
                    key: key,
                    value: value,
                };
            } else {
                //err
                return null;
            }
        }

        function getTopicFilter() {
            const key = getSearchParam(URL_PARAMETER_CONSTANTS.topicFilterKey);
            const type = getSearchParam(URL_PARAMETER_CONSTANTS.topicFilterType);

            if (key) {
                return { key };
            } else if (type) {
                return { type };
            } else {
                return null;
            }
        }

        function setTopicFilter(value, bucketType) {
            if (bucketType === 'key') {
                setSearchParam(URL_PARAMETER_CONSTANTS.topicFilterKey, value);
                clearParams([URL_PARAMETER_CONSTANTS.topicFilterType]);
            } else if (bucketType === 'type') {
                clearParams([URL_PARAMETER_CONSTANTS.topicFilterKey]);
                setSearchParam(URL_PARAMETER_CONSTANTS.topicFilterType, value);
            } else {
                api.clearTopicFilter();
            }
        }

        function clearTopicFilter() {
            clearParams([URL_PARAMETER_CONSTANTS.topicFilterKey]);
            clearParams([URL_PARAMETER_CONSTANTS.topicFilterType]);
        }

        function clearCatalogSelection() {
            clearParams([URL_PARAMETER_CONSTANTS.selectedKeyValue]);
            clearParams([URL_PARAMETER_CONSTANTS.selectedId]);
        }

        function setPreviewParams(key, value, type) {
            setSearchParam(URL_PARAMETER_CONSTANTS.selectedKeyValue, key + ':' + value);
            setSearchParam('view', type);

            clearParams([
                'dashboard',
                URL_PARAMETER_CONSTANTS.startTime.relative,
                URL_PARAMETER_CONSTANTS.startTime.absolute,
                URL_PARAMETER_CONSTANTS.endTime.relative,
                URL_PARAMETER_CONSTANTS.endTime.absolute,
            ]);
        }

        // resolutionAdjustable related methods

        // Preserve old generic bool getter behavior for api existing param
        function getResolutionAdjustable(paramName) {
            const getter = defineGenericGetter(paramName);
            return () => {
                return getter() !== 'false';
            };
        }

        // serviceEndpoints related methods

        function getServiceEndpoints() {
            const searchParams = getSearchParam(URL_PARAMETER_CONSTANTS.serviceEndpoints);
            if (!searchParams) return [];

            return searchParams.map((searchParam) => {
                if (typeof searchParam !== 'string') return searchParam;
                const [service, endpointStr] = searchParam.split(':');
                const endpoints = endpointStr ? endpointStr.split(',') : [];
                return { service, endpoints };
            });
        }

        // detectorOrigins related methods
        function getDetectorOrigins() {
            const searchParams = getSearchParam(URL_PARAMETER_CONSTANTS.detectorOrigins);
            if (searchParams && typeof searchParams === 'string') return searchParams.split(',');
            else if (searchParams && Array.isArray(searchParams)) return searchParams;
            else return DETECTOR_ORIGINS_TYPES;
        }

        function setDetectorOrigins(detectorOrigins) {
            if (detectorOrigins && detectorOrigins.length < DETECTOR_ORIGINS_TYPES_AMOUNT) {
                setSearchParam(URL_PARAMETER_CONSTANTS.detectorOrigins, detectorOrigins.join(','));
            } else {
                api.clearDetectorOrigins();
            }
        }

        // businessWorkflows related methods

        function getBusinessWorkflows() {
            const searchParams = getSearchParam(URL_PARAMETER_CONSTANTS.businessWorkflows);
            if (!searchParams) return [];

            return searchParams.map((searchParam) => {
                if (typeof searchParam !== 'string') return searchParam;
                return { resource: searchParam };
            });
        }

        // tags related methods

        function getTags() {
            const searchParams = getSearchParam(URL_PARAMETER_CONSTANTS.tags);
            if (!searchParams) return [];

            return searchParams.map((searchParam) => {
                if (typeof searchParam !== 'string') return searchParam;
                try {
                    return angular.fromJson(searchParam);
                } catch (e) {
                    // it's a fallback way to parse the tag
                    // if the user has saved links (before the comma fix), it would still work.
                    const index = searchParam.indexOf(':');
                    if (index !== -1) {
                        const key = searchParam.substring(0, index).trim();
                        const values = searchParam
                            .substring(index + 1)
                            .trim()
                            .split(',');
                        return { key, values };
                    }
                }
            });
        }

        function setTags(tags) {
            if (angular.isArray(tags) && tags.length > 0) {
                const tagParams = tags.map((tag) =>
                    angular.toJson({ key: tag.key, values: tag.values })
                );
                setSearchParam(URL_PARAMETER_CONSTANTS.tags, tagParams);
            } else {
                api.clearTags();
            }
        }

        function getSpanCriteria() {
            const paramValues = getSearchParam(URL_PARAMETER_CONSTANTS.spanCriteria);

            if (!paramValues) {
                return [];
            }

            return (
                paramValues
                    .map((value) => {
                        try {
                            return angular.fromJson(value);
                        } catch (e) {
                            $log.error(`Unable to parse spanCriteria: ${value}`);
                        }
                    })
                    // filter out any potential undefineds that would be the results of
                    // failures to parse JSON
                    .filter((v) => v)
            );
        }

        function setSpanCriteria(spanCriteria) {
            clearParams([URL_PARAMETER_CONSTANTS.spanCriteria]);

            setSearchParam(
                URL_PARAMETER_CONSTANTS.spanCriteria,
                spanCriteria.map((c) => angular.toJson(c))
            );
        }

        // Functions with direct access to $location service.

        // set global time picker params
        function setGlobalTimePicker(startTime, endTime, isRelativeTime) {
            // need a start time, end time is optional for relative times, defaults to 'Now'
            if (!startTime || !(isRelativeTime || endTime)) {
                return;
            }

            const params = angular.copy($location.search());
            const timeWindowString = isRelativeTime ? RELATIVE_TIME_STRING : ABSOLUTE_TIME_STRING;
            const opposingTimeWindowString = getOtherTimeWindowString(timeWindowString);

            const startTimeParam = URL_PARAMETER_CONSTANTS.startTime[timeWindowString];
            const opposingStartTimeParam =
                URL_PARAMETER_CONSTANTS.startTime[opposingTimeWindowString];
            params[startTimeParam] = startTime;
            if (!!params[opposingStartTimeParam]) {
                delete params[opposingStartTimeParam];
            }

            if (endTime) {
                const endTimeParam = URL_PARAMETER_CONSTANTS.endTime[timeWindowString];
                const opposingEndTimeParam =
                    URL_PARAMETER_CONSTANTS.endTime[opposingTimeWindowString];
                params[endTimeParam] = endTime;
                if (!!params[opposingEndTimeParam]) {
                    delete params[opposingEndTimeParam];
                }
            } else {
                // There is no end time, so we should make sure we don't leave old values around
                delete params[URL_PARAMETER_CONSTANTS.endTime[RELATIVE_TIME_STRING]];
                delete params[URL_PARAMETER_CONSTANTS.endTime[ABSOLUTE_TIME_STRING]];
            }

            $location.search(params);
        }

        function useReplaceForLastChange() {
            $location.replace();
        }

        /**
         * Clears ALL query params.
         *
         * WARNING: api can wreak havoc in a dashboard views context; DO NOT use in a
         * dashboard or charting context.
         */
        function clearAll() {
            $location.search({});
        }

        /**
         * api should only clear filter related url params. If they appear, groupId and configId should
         * be left alone.
         */
        function clearAllNonLocationUrlParams() {
            const currentParams = $location.search();
            const paramKeys = Object.keys(currentParams);

            paramKeys.forEach((key) => {
                if (!NON_FILTER_OVERRIDES.includes(key)) {
                    delete currentParams[key];
                }
            });

            $location.search(currentParams);
        }

        // clear params
        function clearParams(URL_PARAMETER_CONSTANTS) {
            const params = $location.search();
            URL_PARAMETER_CONSTANTS.forEach(function (paramName) {
                delete params[paramName];
            });
            $location.search(params);
        }

        function getSearchParam(name) {
            const paramValue = angular.copy($location.search()[name]);

            // there is apparently no difference between the url coding used when an
            // array value is supplied versus when e.g. a string is stored even though
            // it is stored in $location as an array when supplying an array of length
            // 1. In order to prevent unpredictable return values, generic url params
            // that are named as arrays are arrayified before returning.
            if (paramValue && name.endsWith('[]') && !angular.isArray(paramValue)) {
                return [paramValue];
            } else {
                return paramValue;
            }
        }

        function setSearchParam(paramName, val, clearFn) {
            // check that val is truthy and if val is array, ensure non-empty
            if (val && (!angular.isArray(val) || val.length)) {
                $timeout(function () {
                    $location.search(paramName, val);
                });
            } else if (clearFn) {
                clearFn();
            } else {
                $timeout(function () {
                    $location.search(paramName, null);
                });
            }
        }

        function doesParamExist(paramName) {
            return paramName in $location.search();
        }

        /* function factories */

        function defineGenericGetter(paramName) {
            return () => {
                return getSearchParam(paramName);
            };
        }

        function defineGenericExists(paramName) {
            return () => {
                return doesParamExist(paramName);
            };
        }

        function defineGenericNumberGetter(paramName, numType) {
            const getter = defineGenericGetter(paramName);
            const stringToNumber = numType === 'int' ? parseInt : parseFloat;
            return () => {
                const value = stringToNumber(getter(), 10);
                return isNaN(value) ? null : value;
            };
        }

        function defineGenericBoolGetter(paramName) {
            const getter = defineGenericGetter(paramName);
            return () => {
                return !!getter();
            };
        }

        function defineGenericSetter(paramName, clearFn) {
            return (val) => {
                setSearchParam(paramName, val, clearFn);
            };
        }

        function defineGenericClear(paramName) {
            return () => {
                clearParams([paramName]);
            };
        }

        function getOtherTimeWindowString(relativeTimeString) {
            if (relativeTimeString === RELATIVE_TIME_STRING) {
                return ABSOLUTE_TIME_STRING;
            } else {
                return RELATIVE_TIME_STRING;
            }
        }

        function legacyFilterTransformer(legacyOverride) {
            // sanity check to ensure we don't erroneously convert correct overrides
            if (legacyOverride.property && legacyOverride.propertyValue) {
                return legacyOverride;
            }
            return {
                NOT: legacyOverride.NOT,
                applyIfExists: legacyOverride.applyIfExists,
                property: legacyOverride.property,
                propertyValue: legacyOverride.value,
            };
        }

        function getSourceFiltersAsString(sourceFilters) {
            return sourceFilters.map((sourceFilter) => {
                let value = sourceFilter.value;
                if (angular.isArray(value)) {
                    value = angular.toJson(value);
                }
                const negate = sourceFilter.NOT ? '!' : '';
                return negate + sourceFilter.property + ':' + value;
            });
        }

        function getSourceFiltersFromString(sourceFiltersString) {
            const kv = sourceFiltersString.split(':');
            const property = kv[0].replace(/^!/, '');
            const not = kv[0].match(/^!/);

            return {
                property: property,
                value: unflattenPropertyValue(kv.splice(1).join(':')),
                NOT: !!not,
            };
        }
    },
];

export function unflattenPropertyValue(value) {
    let unflattened;
    if (value.charAt(0) === '{' || value.charAt(0) === '[') {
        try {
            unflattened = JSON.parse(value);
        } catch (e) {
            // not json
            unflattened = value;
        }
    } else {
        unflattened = value;
    }
    if (angular.isDefined(unflattened) && !angular.isArray(unflattened)) {
        return unflattened.toString();
    } else {
        return unflattened;
    }
}

function decodeSourceOverride(encodedString, supportLegacy = false) {
    const propertyAndValue = encodedString.split(/:(.*)/) || [];
    let property = propertyAndValue[0] || '';
    let value = propertyAndValue[1] || '';
    const onlyApplyIfExists = property.match(/^~/) !== null;
    if (onlyApplyIfExists) {
        property = property.replace(/^~/, '');
    }
    const isNotFilter = property.match(/^!/) !== null;
    if (isNotFilter) {
        property = property.replace(/^!/, '');
    }
    try {
        value = JSON.parse(value);
    } catch (e) {
        // not json
    }
    if (property !== undefined && value !== undefined) {
        if (typeof value === 'number') {
            value = `${value}`;
        }

        const sourceOverride = {
            property: property,
            propertyValue: value,
            applyIfExists: onlyApplyIfExists,
            NOT: isNotFilter,
        };

        if (supportLegacy) {
            sourceOverride.value = value;
        }

        return sourceOverride;
    } else {
        return null;
    }
}

function encodeSourceOverride(override) {
    let value = override.propertyValue;
    if (angular.isArray(value)) {
        value = angular.toJson(value);
    }
    const applyIfExistsPrefix = override.applyIfExists ? '~' : '';
    const notPrefix = override.NOT ? '!' : '';
    return applyIfExistsPrefix + notPrefix + override.property + ':' + value;
}

// Parsers for Olly
export const sourceOverrideParsers = {
    param: URL_PARAMETER_CONSTANTS.sourceOverride,
    encode: ({ property, values, applyIfExists, NOT }) => {
        return property && values?.length
            ? encodeSourceOverride({ property, applyIfExists, NOT, propertyValue: values })
            : undefined;
    },
    decode: (str) => {
        const override = decodeSourceOverride(str, true);

        if (!override?.property || !override?.propertyValue?.length) {
            return;
        }

        const { property, applyIfExists, NOT, propertyValue } = override;
        const values = !(propertyValue instanceof Array) ? [propertyValue] : propertyValue;

        return { property, values, applyIfExists, NOT, type: 'Property' };
    },
};
