import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular.module('signalview.detector').service('detectorV2Converter', [
    function () {
        const v2ModelToV1Model = (v2Model, sf_uiModel) => {
            return {
                sf_type: 'Detector',
                sf_authorizedTeamWriters: safeLookup(v2Model, 'authorizedWriters.teams') || [],
                sf_authorizedUserWriters: safeLookup(v2Model, 'authorizedWriters.users') || [],
                sf_createdOnMs: v2Model.created,
                sf_creator: v2Model.creator,
                sf_description: v2Model.description,
                sf_id: v2Model.id,
                sf_labelResolutions: v2Model.labelResolutions || {},
                sf_updatedOnMs: v2Model.lastUpdated,
                sf_updatedBy: v2Model.lastUpdatedBy,
                sf_jobMaxDelay: v2Model.maxDelay,
                sf_jobMinDelay: v2Model.minDelay,
                sf_detector: v2Model.name,
                sf_overMTSLimit: v2Model.overMTSLimit,
                sf_packageSpecifications: v2Model.packageSpecifications,
                sf_programText: v2Model.programText,
                sf_timezone: v2Model.timezone || '',
                sf_signalflowVersion: 2,
                sf_modelVersion: 2,
                $isOriginallyV2: true,
                sf_teams: v2Model.teams,
                sf_tags: v2Model.tags,
                sf_sourceSelectors: v2Model.sourceSelectors || [],
                sf_sourceSelectorEquivalentFilters: v2Model.sourceSelectorsEquivalentFilters || [],
                detectorOrigin: v2Model.detectorOrigin,
                sf_uiModel,
            };
        };

        return {
            v2ModelToV1Model,
        };
    },
]);
