import React from 'react';
import Link from '@splunk/react-ui/Link';
import QuestionCircle from '@splunk/react-icons/QuestionCircle';
import { TooltipPropsBase } from '@splunk/react-ui/Tooltip';
import { CommonTooltip } from '../CommonTooltip';

type TooltipProps = {
    docLink?: string;
} & Pick<TooltipPropsBase, 'content' | 'defaultPlacement'>;

export const QuestionTooltip: React.FC<TooltipProps> = ({
    content,
    docLink,
    defaultPlacement,
}: TooltipProps) => {
    return (
        <CommonTooltip
            content={
                <span>
                    {content}
                    {docLink && (
                        <Link to={docLink} openInNewContext>
                            Learn more
                        </Link>
                    )}
                </span>
            }
            contentRelationship="label"
            defaultPlacement={defaultPlacement}
            component={<QuestionCircle tabIndex={0} />}
        ></CommonTooltip>
    );
};
