import React, { useState } from 'react';
import Dropdown from '@splunk/react-ui/Dropdown';
import Menu from '@splunk/react-ui/Menu';
import SlidingPanels from '@splunk/react-ui/SlidingPanels';
import Button from '@splunk/react-ui/Button';
import DotsThreeVertical from '@splunk/react-icons/DotsThreeVertical';
import styled from 'styled-components';
import ChevronLeft from '@splunk/react-icons/ChevronLeft';
import Tooltip from '@splunk/react-ui/Tooltip';
import { variables } from '@splunk/themes';

const { Divider, Heading, Item } = Menu;

const StyledPanelContainer = styled.div`
    width: 220px;
`;

const StyledDeleteLabel = styled.span`
    color: ${variables.accentColorNegative};
`;

const StyledBackwardsContainer = styled.div`
    display: flex;
    align-items: center;
`;

const MIRROR_DELETE_TOOLTIP =
    'Change the permissions of this dashboard to inherit from a different group to remove this mirror';

export type DashboardNavHeaderActionsProps = {
    actions: {
        addDashboardToGroup: () => void;
        allowBuiltinMirrors: boolean;
        canBeMirrored: boolean;
        canEditGroup: boolean;
        canMakeEditable: boolean;
        canMakeReadOnly: boolean;
        canRemoveMirrors: boolean;
        cloneDashboard: () => void;
        dashboardViewsEnabled: boolean;
        deleteDashboard: () => void;
        enableCustomDashboardAsHomePage: boolean;
        groupIsServiceDiscovery: boolean;
        export: () => void;
        hasCharts: boolean;
        hasCreateDashboardGroupCapability: boolean;
        hasCreateShareableSnapshotCapability: boolean;
        isDeleteDisabled: boolean;
        isFullscreen: boolean;
        isMirror: boolean;
        isSnapshot: boolean;
        isWorkspace: boolean;
        makeDashboardEditable: () => void;
        makeDashboardReadOnly: () => void;
        mirrorsDisableReasonTooltipText: string;
        removeDashboardFromGroup: () => void;
        saveTempDashboard: () => void;
        setAsHomepage: () => void;
        share: () => void;
        showActionMenu: boolean;
        showDashboardCrossLinks: () => void;
        showDashboardInfo: () => void;
        showEditPermissionsModal: () => void;
        showEditVariablesModal: () => void;
        showEventOverlayModal: () => void;
        showRenameModal: () => void;
        showPermissionsLink: boolean;
        viewFullscreen: () => void;
    };
    groupActions: {
        deleteDashboardGroup: () => void;
        deleteDashboardGroupEnabled: boolean;
        exportEnabled: () => boolean;
        exportGroup: () => void;
        isTeamsLinksDisabled: boolean;
        showPageInfo: () => void;
        showPagePermissions: () => boolean;
        showPagePermissionsLink: boolean;
        showDeleteDashboardGroup: boolean;
        showTeamsLinksModal: () => void;
    };
    hasDeleteDashboardGroupCapability: boolean;
    isExportEnabled: boolean;
    onTrackClick: (value: string) => void;
};

export const DashboardNavHeaderActions = ({
    actions: {
        addDashboardToGroup,
        allowBuiltinMirrors,
        canBeMirrored,
        canEditGroup,
        canMakeEditable,
        canMakeReadOnly,
        canRemoveMirrors,
        cloneDashboard,
        dashboardViewsEnabled,
        deleteDashboard,
        enableCustomDashboardAsHomePage,
        export: exportDashboard,
        groupIsServiceDiscovery,
        hasCharts,
        hasCreateDashboardGroupCapability,
        hasCreateShareableSnapshotCapability,
        isDeleteDisabled,
        isFullscreen,
        isMirror,
        isSnapshot,
        isWorkspace,
        makeDashboardEditable,
        makeDashboardReadOnly,
        mirrorsDisableReasonTooltipText,
        removeDashboardFromGroup,
        saveTempDashboard,
        setAsHomepage,
        share,
        showActionMenu,
        showDashboardCrossLinks,
        showDashboardInfo,
        showEditPermissionsModal,
        showEditVariablesModal,
        showEventOverlayModal,
        showRenameModal,
        showPermissionsLink,
        viewFullscreen,
    },
    groupActions: {
        deleteDashboardGroup,
        deleteDashboardGroupEnabled,
        exportGroup,
        isTeamsLinksDisabled,
        showDeleteDashboardGroup,
        showPageInfo,
        showPagePermissions,
        showPagePermissionsLink,
        showTeamsLinksModal,
    },
    hasDeleteDashboardGroupCapability,
    isExportEnabled,
    onTrackClick,
}: DashboardNavHeaderActionsProps): React.JSX.Element => {
    const [transition, setTransition] = useState<'forward' | 'backward'>('forward');
    const [activePanelId, setActivePanelId] = useState('dashboardActions');

    const goForwardToMoreDashboardActions = (event: React.MouseEvent): void => {
        event.stopPropagation();
        setActivePanelId('moreDashboardActions');
        setTransition('forward');
    };

    const goForwardToDashboardGroup = (event: React.MouseEvent): void => {
        event.stopPropagation();
        setActivePanelId('dashboardGroupActions');
        setTransition('forward');
    };

    const goBackward = (event: React.MouseEvent): void => {
        event.stopPropagation();
        setActivePanelId('dashboardActions');
        setTransition('backward');
    };

    const Toggle = (
        <div>
            <Button appearance="subtle" icon={<DotsThreeVertical />} />
        </div>
    );

    return (
        <Dropdown toggle={Toggle}>
            <StyledPanelContainer>
                <SlidingPanels activePanelId={activePanelId} transition={transition}>
                    <SlidingPanels.Panel key="dashboardActions" panelId="dashboardActions">
                        <Menu focusMode="roving">
                            <Heading>Dashboard actions</Heading>
                            {hasCreateDashboardGroupCapability && (
                                <Item
                                    disabled={!hasCharts}
                                    onClick={(): void => {
                                        hasCharts &&
                                            (isSnapshot ? saveTempDashboard() : cloneDashboard());
                                        onTrackClick('dashboard-save-as');
                                    }}
                                >
                                    Save as
                                </Item>
                            )}
                            {dashboardViewsEnabled && hasCreateDashboardGroupCapability && (
                                <Item
                                    disabled={
                                        isWorkspace ||
                                        (groupIsServiceDiscovery && !allowBuiltinMirrors) ||
                                        !canBeMirrored
                                    }
                                    onClick={(): void => {
                                        !isWorkspace &&
                                            (!groupIsServiceDiscovery ||
                                                (groupIsServiceDiscovery && allowBuiltinMirrors)) &&
                                            canBeMirrored &&
                                            addDashboardToGroup();
                                        onTrackClick('dashboard-views-add-to-group-open-modal');
                                    }}
                                >
                                    <Tooltip
                                        content={mirrorsDisableReasonTooltipText}
                                        openDelay={'secondary'}
                                    >
                                        Add a mirror
                                    </Tooltip>
                                </Item>
                            )}
                            {hasCreateShareableSnapshotCapability && (
                                <Item
                                    disabled={!hasCharts}
                                    onClick={(): void => {
                                        hasCharts && share();
                                        onTrackClick('dashboard-dashboard-share');
                                    }}
                                >
                                    Share
                                </Item>
                            )}
                            {isExportEnabled && (
                                <Item
                                    onClick={(): void => {
                                        exportDashboard();
                                        onTrackClick('dashboard-export');
                                    }}
                                >
                                    Export
                                </Item>
                            )}
                            {showActionMenu && (
                                <Item
                                    onClick={(): void => {
                                        showRenameModal();
                                        onTrackClick('dashboard-rename');
                                    }}
                                >
                                    Rename
                                </Item>
                            )}
                            {!isFullscreen && (
                                <Item
                                    onClick={(): void => {
                                        viewFullscreen();
                                        onTrackClick('dashboard-enter-fullscreen');
                                    }}
                                >
                                    View fullscreen
                                </Item>
                            )}
                            {isMirror && (
                                <Item
                                    disabled={!canEditGroup || !canRemoveMirrors}
                                    onClick={(): void => {
                                        canEditGroup &&
                                            canRemoveMirrors &&
                                            removeDashboardFromGroup();
                                        onTrackClick(
                                            'dashboard-views-remove-from-group-open-modal'
                                        );
                                    }}
                                >
                                    <Tooltip
                                        content={
                                            !canRemoveMirrors ? MIRROR_DELETE_TOOLTIP : undefined
                                        }
                                    >
                                        <StyledDeleteLabel>Remove this mirror</StyledDeleteLabel>
                                    </Tooltip>
                                </Item>
                            )}
                            {!isMirror && hasDeleteDashboardGroupCapability && (
                                <Item
                                    disabled={isDeleteDisabled}
                                    onClick={(): void => {
                                        !isDeleteDisabled && deleteDashboard();
                                        onTrackClick('dashboard-delete');
                                    }}
                                >
                                    <StyledDeleteLabel>Delete</StyledDeleteLabel>
                                </Item>
                            )}
                            <Divider />
                            <Item hasSubmenu onClick={goForwardToMoreDashboardActions}>
                                More dashboard actions
                            </Item>

                            <Divider />
                            <Item hasSubmenu onClick={goForwardToDashboardGroup}>
                                Dashboard group actions
                            </Item>
                        </Menu>
                    </SlidingPanels.Panel>

                    <SlidingPanels.Panel key="moreDashboardActions" panelId="moreDashboardActions">
                        <Menu>
                            <Item onClick={goBackward}>
                                <StyledBackwardsContainer>
                                    <ChevronLeft />
                                    Back
                                </StyledBackwardsContainer>
                            </Item>
                            <Heading>More dashboard actions</Heading>
                            {showActionMenu && (
                                <Item
                                    onClick={(): void => {
                                        showDashboardInfo();
                                        onTrackClick('dashboard-dashboard-info');
                                    }}
                                >
                                    Dashboard info
                                </Item>
                            )}
                            {showPermissionsLink && (
                                <Item
                                    onClick={(): void => {
                                        showEditPermissionsModal();
                                        onTrackClick('dashboard-permissions-open');
                                    }}
                                >
                                    Permissions
                                </Item>
                            )}
                            {canMakeReadOnly && (
                                <Item
                                    onClick={(): void => {
                                        makeDashboardReadOnly();
                                        onTrackClick('dashboard-make-read-only');
                                    }}
                                >
                                    Make read only
                                </Item>
                            )}
                            {canMakeEditable && (
                                <Item
                                    onClick={(): void => {
                                        makeDashboardEditable();
                                        onTrackClick('dashboard-allow-edits');
                                    }}
                                >
                                    Allow edits
                                </Item>
                            )}
                            {showActionMenu && (
                                <Item
                                    onClick={(): void => {
                                        showEditVariablesModal();
                                        onTrackClick('dashboard-variables-open');
                                    }}
                                >
                                    Dashboard variables
                                </Item>
                            )}
                            {showActionMenu && (
                                <Item
                                    onClick={(): void => {
                                        showEventOverlayModal();
                                        onTrackClick('dashboard-event-overlay-suggestions');
                                    }}
                                >
                                    Event overlay suggestions
                                </Item>
                            )}
                            {showActionMenu && (
                                <Item
                                    onClick={(): void => {
                                        showDashboardCrossLinks();
                                        onTrackClick('dashboard-event-overlay-suggestions');
                                    }}
                                >
                                    Data links
                                </Item>
                            )}
                            {enableCustomDashboardAsHomePage && (
                                <Item
                                    onClick={(): void => {
                                        setAsHomepage();
                                        onTrackClick('dashboard-set-homepage');
                                    }}
                                >
                                    Set as Home page
                                </Item>
                            )}
                        </Menu>
                    </SlidingPanels.Panel>

                    <SlidingPanels.Panel
                        key="dashboardGroupActions"
                        panelId="dashboardGroupActions"
                    >
                        <Menu>
                            <Item onClick={goBackward}>
                                <StyledBackwardsContainer>
                                    <ChevronLeft />
                                    Back
                                </StyledBackwardsContainer>
                            </Item>
                            <Divider />
                            <Heading>Dashboard group actions</Heading>
                            <Item
                                onClick={(): void => {
                                    showPageInfo();
                                    onTrackClick('dashboard-group-info');
                                }}
                            >
                                Info
                            </Item>
                            {showPagePermissionsLink && (
                                <Item
                                    onClick={(): void => {
                                        showPagePermissions();
                                        onTrackClick('dashboard-group-permissions');
                                    }}
                                >
                                    Permissions
                                </Item>
                            )}
                            <Item
                                disabled={isTeamsLinksDisabled}
                                onClick={(): void => {
                                    showTeamsLinksModal();
                                    onTrackClick('dashboard-group-link-to-team');
                                }}
                            >
                                Links to teams
                            </Item>
                            {isExportEnabled && (
                                <Item
                                    onClick={(): void => {
                                        exportGroup();
                                        onTrackClick('dashboard-group-export');
                                    }}
                                >
                                    Export
                                </Item>
                            )}
                            {showDeleteDashboardGroup && hasDeleteDashboardGroupCapability && (
                                <Item
                                    disabled={!deleteDashboardGroupEnabled}
                                    onClick={(): void => {
                                        deleteDashboardGroup();
                                        onTrackClick('delete-dashboard-group');
                                    }}
                                >
                                    <StyledDeleteLabel>Delete</StyledDeleteLabel>
                                </Item>
                            )}
                        </Menu>
                    </SlidingPanels.Panel>
                </SlidingPanels>
            </StyledPanelContainer>
        </Dropdown>
    );
};
