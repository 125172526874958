import templateUrl from './apmSignalEditor.tpl.html';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular.module('signalview.detector.signal').component('apmSignalEditor', {
    bindings: {
        detector: '=',
        environmentInfo: '<',
        isNewRule: '<',
        rule: '<',
        selectedDetectorType: '<',
        selectedEnvironment: '<',
        selectedFilters: '<',
        selectedMetricType: '<',
        selectedPercentile: '<',
        selectedServiceEndpoints: '<',
        selectedBusinessWorkflows: '<',
        onSelectEnvironment: '<',
        onSelectFilters: '<',
        onSelectMetricType: '<',
        onSelectServiceEndpoint: '<',
        onSelectBusinessWorkflow: '<',
    },
    templateUrl,
    controller: [
        '$scope',
        'APM_METRIC_TYPE',
        'DETECTOR_TYPES',
        'chartbuilderUtil',
        'featureEnabled',
        'filterFactory',
        'signalboostUtil',
        'ServiceEndpointSelection',
        'BusinessWorkflowSelection',
        'environmentResolver',
        'apmFilterService',
        'signalTypeService',
        'traceSuggestionService',
        function (
            $scope,
            APM_METRIC_TYPE,
            DETECTOR_TYPES,
            chartbuilderUtil,
            featureEnabled,
            filterFactory,
            signalboostUtil,
            ServiceEndpointSelection,
            BusinessWorkflowSelection,
            environmentResolver,
            apmFilterService,
            signalTypeService,
            traceSuggestionService
        ) {
            const $ctrl = this;
            // aggregations for plot creation
            const AGGREGATION_FUNCTIONS = {
                MAXIMUM: 'Maximum',
                COUNT: 'Count',
                SUM: 'Sum',
                PERCENTILE: 'Percentile',
            };

            const PERCENTILE_TO_NAME = {
                99: 'p99',
                90: 'p90',
                50: 'median',
            };

            // prefix of apm plot name
            const APM_PLOT_NAME_PREFIX = '[APM] ';
            // unit used in latency metrics
            const LATENCY_METRIC_UNIT = 'Nanosecond';

            const isApm2WorkflowsEnabled = featureEnabled('apm2Workflows');
            const isApmHistogramMetricsEnabled = featureEnabled('detectorsApmHistogramMetric');

            let shouldUseNonHistogramMetric = false;
            let firstInitDone = null;
            let environmentInfoLoaded = null;

            Object.defineProperties($ctrl, {
                isInitialized: { get: () => !!firstInitDone && !!environmentInfoLoaded },
            });

            // signal dropdown options
            $ctrl.metricTypeOptions = [
                signalTypeService.SERVICE_ENDPOINT.errorRateOption,
                signalTypeService.SERVICE_ENDPOINT.latencyOption,
            ];

            // set the default value
            $ctrl.selectedSignalTypeEntity = signalTypeService.SERVICE_ENDPOINT;

            // lifecycle bindings
            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;

            // callback functions
            $ctrl.getCurrentQuery = getCurrentQuery;
            $ctrl.onSelectMetricTypeOption = onSelectMetricTypeOption;
            $ctrl.selectEnvironment = selectEnvironment;
            $ctrl.getEnvironmentSuggestions = getEnvironmentSuggestions;
            // * service/endpoint
            $ctrl.onClickAddServiceButton = onClickAddServiceButton;
            $ctrl.onSelectServiceEndpointSelection = onSelectServiceEndpointSelection;
            $ctrl.onRemoveServiceEndpointSelection = onRemoveServiceEndpointSelection;
            $ctrl.showAddServiceButton = showAddServiceButton;
            // * business workflow
            $ctrl.onClickAddWorkflowButton = onClickAddBusinessWorkflowButton;
            $ctrl.onSelectBusinessWorkflowSelection = onSelectBusinessWorkflowSelection;
            $ctrl.onRemoveBusinessWorkflowSelection = onRemoveBusinessWorkflowSelection;
            $ctrl.showAddWorkflowButton = showAddBusinessWorkflowButton;

            $ctrl.customFilterPropertiesSuggestions = (...args) =>
                chartbuilderUtil
                    .getSourceSuggestions(...args)
                    .then((results) =>
                        results.filter((queryItem) => queryItem.property.indexOf('sf_') !== 0)
                    );

            // lifecycle methods

            async function $onInit() {
                firstInitDone = false;
                await initEnvironmentInfo();
                $ctrl.selectedSignalTypeEntity = signalTypeService.getSignalTypeEntityForMetricType(
                    $ctrl.selectedMetricType
                );
                initSelections();
                addDefaultSelectionIfEmpty();

                $scope.$watchCollection('$ctrl.selectedFilters', function (filters) {
                    resetPlotsInfo();
                    if ($ctrl.onSelectFilters) {
                        $ctrl.onSelectFilters(filters);
                    }
                });

                updateMetricTypeDropdownOptions($ctrl.selectedDetectorType);
                firstInitDone = true;
                shouldUseNonHistogramMetric =
                    !$ctrl.isNewRule && !getAllPlots().some((p) => p.metricType === 'HISTOGRAM');

                // make sure pre-populated environment value is passed to UI control depending on $ctrl.environment variable
                $ctrl.environment = $ctrl.selectedEnvironment;
            }

            function $onChanges(changesObj) {
                const {
                    rule: ruleChange,
                    selectedPercentile: selectedPercentileChange,
                    selectedMetricType: selectedMetricTypeChange,
                    selectedDetectorType: selectedDetectorTypeChange,
                } = changesObj;

                const latencyMetricTypes = isApm2WorkflowsEnabled
                    ? [APM_METRIC_TYPE.SERVICE_LATENCY, APM_METRIC_TYPE.WORKFLOW_LATENCY]
                    : [APM_METRIC_TYPE.SERVICE_LATENCY];
                const isLatencyType = latencyMetricTypes.includes($ctrl.selectedMetricType);
                const hasNewRule = ruleChange && ruleChange.currentValue;
                const hasPercentileChange =
                    selectedPercentileChange && selectedPercentileChange.currentValue;
                const hasMetricTypeChange =
                    selectedMetricTypeChange && selectedMetricTypeChange.currentValue;

                if (isLatencyType && (hasNewRule || hasPercentileChange || hasMetricTypeChange)) {
                    updateLatencyPlotsVisibility();
                }

                if (selectedDetectorTypeChange) {
                    if (!selectedDetectorTypeChange.isFirstChange()) {
                        initEnvironmentInfo(true);
                    }
                    updateMetricTypeDropdownOptions($ctrl.selectedDetectorType);
                }

                // set the signal type (service/endpoint or business workflow)
                $ctrl.selectedSignalTypeEntity = signalTypeService.getSignalTypeEntityForMetricType(
                    $ctrl.selectedMetricType
                );
            }

            // callback methods

            function getEnvironmentSuggestions(query) {
                if (query && query.length === 0) {
                    return [];
                }
                return traceSuggestionService.getEnvironmentSuggestions(query).then(function (res) {
                    return res;
                });
            }

            function onSelectMetricTypeOption(option) {
                const metricType = option.value;
                if (metricType === $ctrl.selectedMetricType) return;

                $ctrl.selectedMetricType = metricType;
                $ctrl.onSelectMetricType($ctrl.selectedMetricType);

                resetSelections(metricType);
                resetPlotsInfo();
                addDefaultSelectionIfEmpty();
            }

            function onClickAddServiceButton() {
                $ctrl.selectedServiceEndpoints.push(new ServiceEndpointSelection());
            }

            function onSelectServiceEndpointSelection(serviceEndpointSelection, index) {
                if (!serviceEndpointSelection) return;

                $ctrl.selectedServiceEndpoints[index].copyProperties(serviceEndpointSelection);

                if (!hasCompleteSelection($ctrl.selectedServiceEndpoints)) return;

                resetPlotsInfo();
                updateSelections(signalTypeService.SERVICE_ENDPOINT.name);
            }

            function onRemoveServiceEndpointSelection(serviceEndpointSelection, index) {
                if (!serviceEndpointSelection) return;

                $ctrl.selectedServiceEndpoints.splice(index, 1);
                resetPlotsInfo();
                addDefaultSelectionIfEmpty();
                updateSelections(signalTypeService.SERVICE_ENDPOINT.name);
            }

            function showAddServiceButton() {
                if (!$ctrl.isInitialized) {
                    return false;
                }

                const lastIndex = $ctrl.selectedServiceEndpoints.length - 1;
                return $ctrl.selectedServiceEndpoints[lastIndex].isComplete();
            }

            function onClickAddBusinessWorkflowButton() {
                $ctrl.selectedBusinessWorkflows.push(new BusinessWorkflowSelection());
            }

            function onSelectBusinessWorkflowSelection(businessWorkflowSelection, index) {
                if (!businessWorkflowSelection) return;

                $ctrl.selectedBusinessWorkflows[index].copyProperties(businessWorkflowSelection);

                if (!hasCompleteSelection($ctrl.selectedBusinessWorkflows)) return;

                resetPlotsInfo();
                updateSelections(signalTypeService.WORKFLOW.name);
            }

            function onRemoveBusinessWorkflowSelection(businessWorkflowSelection, index) {
                if (!businessWorkflowSelection) return;

                $ctrl.selectedBusinessWorkflows.splice(index, 1);
                resetPlotsInfo();
                addDefaultSelectionIfEmpty();
                updateSelections(signalTypeService.WORKFLOW.name);
            }

            function showAddBusinessWorkflowButton() {
                if (!$ctrl.isInitialized) {
                    return false;
                }

                const lastIndex = $ctrl.selectedBusinessWorkflows.length - 1;
                return $ctrl.selectedBusinessWorkflows[lastIndex].isComplete();
            }

            function selectEnvironment(environment) {
                $ctrl.selectedEnvironment = environment;
                $ctrl.environment = environment;
                $ctrl.onSelectEnvironment(environment);
                resetPlotsInfo();
            }

            function getCurrentQuery() {
                const metric = $ctrl.selectedSignalTypeEntity.histogramMetric;
                const queryString = `((((sf_type:MetricTimeSeries) AND (sf_metric:${metric}))))`;
                return signalboostUtil.inactiveEmitterFilter(queryString);
            }

            // private methods ------------------------------------

            // business workflow options only for APM_V2
            function updateMetricTypeDropdownOptions(detectorType) {
                const showWorkflowSignalOptions =
                    detectorType === DETECTOR_TYPES.APM_V2 && isApm2WorkflowsEnabled;
                $ctrl.metricTypeOptions = showWorkflowSignalOptions
                    ? [
                          signalTypeService.SERVICE_ENDPOINT.errorRateOption,
                          signalTypeService.SERVICE_ENDPOINT.latencyOption,
                          signalTypeService.WORKFLOW.errorRateOption,
                          signalTypeService.WORKFLOW.latencyOption,
                      ]
                    : [
                          signalTypeService.SERVICE_ENDPOINT.errorRateOption,
                          signalTypeService.SERVICE_ENDPOINT.latencyOption,
                      ];
            }

            // init methods

            async function initEnvironmentInfo(detectorTypeChanged = false) {
                environmentInfoLoaded = false;
                const environmentInfo = await environmentResolver.getEnvironmentInfo();

                $ctrl.hasMultipleEnvs = environmentInfo.environments.length > 1;
                $ctrl.envOptions = environmentInfo.environments;

                if (!$ctrl.selectedEnvironment || detectorTypeChanged) {
                    $ctrl.selectedEnvironment = environmentInfo.current;
                    $ctrl.onSelectEnvironment($ctrl.selectedEnvironment);
                }
                environmentInfoLoaded = true;
            }

            function initSelections() {
                if (!$ctrl.isNewRule) return;

                if (
                    $ctrl.selectedSignalTypeEntity.name === signalTypeService.SERVICE_ENDPOINT.name
                ) {
                    if ($ctrl.selectedServiceEndpoints) {
                        $ctrl.selectedServiceEndpoints.forEach((selection) =>
                            createPlots(selection)
                        );
                        updateSelections($ctrl.selectedSignalTypeEntity.name);
                    } else {
                        $ctrl.selectedServiceEndpoints = [];
                    }
                } else if (
                    isApm2WorkflowsEnabled &&
                    $ctrl.selectedSignalTypeEntity.name === signalTypeService.WORKFLOW.name
                ) {
                    if ($ctrl.selectedBusinessWorkflows) {
                        $ctrl.selectedBusinessWorkflows.forEach((selection) =>
                            createPlots(selection)
                        );
                        updateSelections($ctrl.selectedSignalTypeEntity.name);
                    } else {
                        $ctrl.selectedBusinessWorkflows = [];
                    }
                }
            }

            // signal type selections related methods

            // clear out selections for signal type not currently selected
            function resetSelections(metricType) {
                if (signalTypeService.SERVICE_ENDPOINT['apmMetricGroup'].includes(metricType)) {
                    $ctrl.selectedBusinessWorkflows = [];
                } else if (
                    isApm2WorkflowsEnabled &&
                    signalTypeService.WORKFLOW['apmMetricGroup'].includes(metricType)
                ) {
                    $ctrl.selectedServiceEndpoints = [];
                }
            }

            function addDefaultSelectionIfEmpty() {
                if (!$ctrl.selectedServiceEndpoints) {
                    $ctrl.selectedServiceEndpoints = [];
                }
                if ($ctrl.selectedServiceEndpoints.length === 0) {
                    $ctrl.selectedServiceEndpoints.push(new ServiceEndpointSelection());
                }
                if (!$ctrl.selectedBusinessWorkflows) {
                    $ctrl.selectedBusinessWorkflows = [];
                }
                if ($ctrl.selectedBusinessWorkflows.length === 0) {
                    $ctrl.selectedBusinessWorkflows.push(new BusinessWorkflowSelection());
                }
            }

            function getPlotNamePostFix(selection) {
                if (!selection || selection.isEmpty()) return '';

                return `- [${selection.getSelectionSummaryStr()}]`;
            }

            function hasCompleteSelection(selections) {
                return selections.length > 0 && !selections[0].isEmpty();
            }

            function updateSelections(signalTypeName) {
                if (signalTypeName === signalTypeService.SERVICE_ENDPOINT.name) {
                    $ctrl.onSelectServiceEndpoint(
                        $ctrl.selectedServiceEndpoints,
                        $ctrl.isInitialized
                    );
                } else if (
                    isApm2WorkflowsEnabled &&
                    signalTypeName === signalTypeService.WORKFLOW.name
                ) {
                    $ctrl.onSelectBusinessWorkflow(
                        $ctrl.selectedBusinessWorkflows,
                        $ctrl.isInitialized
                    );
                }
            }

            // plot related methods

            function getVisibleSpanMetrics(percentile) {
                const metrics = $ctrl.selectedSignalTypeEntity.percentileToMetrics[percentile];
                if (metrics) return metrics;

                return new Set([
                    $ctrl.selectedSignalTypeEntity.percentileToMetrics[50],
                    $ctrl.selectedSignalTypeEntity.percentileToMetrics[90],
                ]);
            }

            function updateLatencyPlotsVisibility() {
                const percentile = safeLookup($ctrl, 'rule.inputs.pctile');

                if (!shouldUseNonHistogramMetric && isApmHistogramMetricsEnabled) {
                    const defaultVisiblePercentiles = [50, 90];

                    getAllPlots()
                        .filter((p) => p.metricType === 'HISTOGRAM')
                        .forEach((p) => {
                            if (percentile) {
                                p.invisible =
                                    p.histogramMethods[0].fn.options.percentile !== percentile;
                            } else {
                                p.invisible = !defaultVisiblePercentiles.includes(
                                    p.histogramMethods[0].fn.options.percentile
                                );
                            }
                        });
                } else {
                    const visibleMetrics = getVisibleSpanMetrics(percentile);

                    getAllPlots().forEach(
                        (p) => (p.invisible = !visibleMetrics.has(p.seriesData.metric))
                    );
                }
            }

            function getAllPlots() {
                return $ctrl.detector.sf_uiModel.allPlots;
            }

            function clearPlotsInfo() {
                $ctrl.detector.sf_uiModel.allPlots = [];
            }

            function resetPlotsInfo() {
                clearPlotsInfo();

                if (
                    $ctrl.selectedSignalTypeEntity.name === signalTypeService.SERVICE_ENDPOINT.name
                ) {
                    $ctrl.selectedServiceEndpoints.forEach((s) => createPlots(s));
                } else if (
                    isApm2WorkflowsEnabled &&
                    $ctrl.selectedSignalTypeEntity.name === signalTypeService.WORKFLOW.name
                ) {
                    $ctrl.selectedBusinessWorkflows.forEach((w) => createPlots(w));
                }
            }

            function createPlots(selection) {
                if (!selection || !selection.isComplete()) {
                    return;
                }

                switch ($ctrl.selectedMetricType) {
                    case signalTypeService.SERVICE_ENDPOINT.errorRateOption.value:
                        createErrorRatePlots(
                            $ctrl.selectedMetricType,
                            signalTypeService.SERVICE_ENDPOINT,
                            selection
                        );
                        break;
                    case signalTypeService.SERVICE_ENDPOINT.latencyOption.value:
                        createLatencyPlots(
                            $ctrl.selectedMetricType,
                            signalTypeService.SERVICE_ENDPOINT,
                            selection
                        );
                        break;
                    case signalTypeService.WORKFLOW.errorRateOption.value:
                        createErrorRatePlots(
                            $ctrl.selectedMetricType,
                            signalTypeService.WORKFLOW,
                            selection
                        );
                        break;
                    case signalTypeService.WORKFLOW.latencyOption.value:
                        createLatencyPlots(
                            $ctrl.selectedMetricType,
                            signalTypeService.WORKFLOW,
                            selection
                        );
                        break;
                    default:
                        createErrorRatePlots(
                            $ctrl.selectedMetricType,
                            signalTypeService.SERVICE_ENDPOINT,
                            selection
                        );
                }
            }

            function addNewPlot() {
                return chartbuilderUtil.addNewPlot($ctrl.detector.sf_uiModel);
            }

            function createSpanPlot(name, metric, invisible = true) {
                const plot = addNewPlot();
                plot.name = APM_PLOT_NAME_PREFIX + name;
                plot.invisible = invisible;
                plot.seriesData = { metric: metric, regExStyle: null };

                return plot;
            }

            function createHistogramPlot(name, signalTypeEntity, invisible = true) {
                const plot = addNewPlot();
                plot.name = APM_PLOT_NAME_PREFIX + name;
                plot.invisible = invisible;
                plot.seriesData = { metric: signalTypeEntity.histogramMetric, regExStyle: null };
                plot.metricType = 'HISTOGRAM';
                plot.histogramMethods = [];

                return plot;
            }

            function createAllRequestsPlot(name, { signalTypeEntity, selection }) {
                const allRequestsPlot =
                    !shouldUseNonHistogramMetric && isApmHistogramMetricsEnabled
                        ? createHistogramPlot(name, signalTypeEntity)
                        : createSpanPlot(name, signalTypeEntity.errorRateMetric);
                allRequestsPlot.queryItems = apmFilterService.generateQueryItems({
                    environment: $ctrl.selectedEnvironment,
                    customFilters: $ctrl.selectedFilters,
                    signalTypeEntity,
                    selection,
                });
                if (!shouldUseNonHistogramMetric && isApmHistogramMetricsEnabled) {
                    allRequestsPlot.histogramMethods.push(
                        apmFilterService.generateDataManipulation(
                            AGGREGATION_FUNCTIONS.COUNT,
                            signalTypeEntity
                        )
                    );
                }
                allRequestsPlot.dataManipulations.push(
                    apmFilterService.generateDataManipulation(
                        AGGREGATION_FUNCTIONS.SUM,
                        signalTypeEntity
                    )
                );
                return allRequestsPlot;
            }

            function createSuccessRequestsPlot(name, { signalTypeEntity, selection }) {
                const successRequestsPlot =
                    !shouldUseNonHistogramMetric && isApmHistogramMetricsEnabled
                        ? createHistogramPlot(name, signalTypeEntity)
                        : createSpanPlot(name, signalTypeEntity.errorRateMetric);
                const queryItems = apmFilterService.generateQueryItems({
                    environment: $ctrl.selectedEnvironment,
                    customFilters: $ctrl.selectedFilters,
                    signalTypeEntity,
                    selection,
                });
                queryItems.push(
                    filterFactory.FILTER(
                        filterFactory.TYPES.VALUE,
                        'false',
                        false,
                        signalTypeService.getAPMDimensions().error
                    )
                );
                successRequestsPlot.queryItems = queryItems;
                if (!shouldUseNonHistogramMetric && isApmHistogramMetricsEnabled) {
                    successRequestsPlot.histogramMethods.push(
                        apmFilterService.generateDataManipulation(
                            AGGREGATION_FUNCTIONS.COUNT,
                            signalTypeEntity
                        )
                    );
                }
                successRequestsPlot.dataManipulations.push(
                    apmFilterService.generateDataManipulation(
                        AGGREGATION_FUNCTIONS.SUM,
                        signalTypeEntity
                    )
                );

                return successRequestsPlot;
            }

            function createErrorRatePlot(name, allRequestsPlotKey, successRequestsPlotKey) {
                const errorRatePlot = chartbuilderUtil.addNewPlot($ctrl.detector.sf_uiModel);
                errorRatePlot.name = APM_PLOT_NAME_PREFIX + name;
                errorRatePlot.type = 'ratio';
                errorRatePlot.expressionText = `100*(${allRequestsPlotKey}-${successRequestsPlotKey})/${allRequestsPlotKey}`;
                errorRatePlot.configuration = { suffix: '%' };
                errorRatePlot.invisible = false;

                return errorRatePlot;
            }

            function createErrorRatePlots(metricType, signalTypeEntity, selection) {
                const postFix = getPlotNamePostFix(selection);

                // all requests plot
                const allReqsPlotName = `All requests ${postFix}`;
                const allReqsPlot = createAllRequestsPlot(allReqsPlotName, {
                    signalTypeEntity,
                    selection,
                });
                const allReqsPlotKey = chartbuilderUtil.getLetterFromUniqueKey(
                    allReqsPlot.uniqueKey
                );

                // success requests plot
                const succReqsPlotName = `Successful requests ${postFix}`;
                const succReqsPlot = createSuccessRequestsPlot(succReqsPlotName, {
                    signalTypeEntity,
                    selection,
                });
                const succReqsPlotKey = chartbuilderUtil.getLetterFromUniqueKey(
                    succReqsPlot.uniqueKey
                );

                // error rate plot
                const errorRatePlotName = `${signalTypeService.METRIC_TYPE_TO_LABEL[metricType]} ${postFix}`;
                const errorRatePlot = createErrorRatePlot(
                    errorRatePlotName,
                    allReqsPlotKey,
                    succReqsPlotKey
                );

                selection.plotIds = [
                    allReqsPlot.uniqueKey,
                    succReqsPlot.uniqueKey,
                    errorRatePlot.uniqueKey,
                ];
            }

            function createLatencyPlot(name, percentile, { signalTypeEntity, selection }) {
                const latencyPlot =
                    !shouldUseNonHistogramMetric && isApmHistogramMetricsEnabled
                        ? createHistogramPlot(name, signalTypeEntity)
                        : createSpanPlot(
                              name,
                              `${signalTypeEntity.latencyMetric}.${PERCENTILE_TO_NAME[percentile]}`,
                              false
                          );
                latencyPlot.queryItems = apmFilterService.generateQueryItems({
                    environment: $ctrl.selectedEnvironment,
                    customFilters: $ctrl.selectedFilters,
                    signalTypeEntity,
                    selection,
                });
                if (!shouldUseNonHistogramMetric && isApmHistogramMetricsEnabled) {
                    latencyPlot.histogramMethods.push(
                        apmFilterService.generateDataManipulation(
                            AGGREGATION_FUNCTIONS.PERCENTILE,
                            signalTypeEntity,
                            {
                                percentile: percentile,
                            }
                        )
                    );
                }
                latencyPlot.dataManipulations.push(
                    apmFilterService.generateDataManipulation(
                        AGGREGATION_FUNCTIONS.MAXIMUM,
                        signalTypeEntity
                    )
                );
                latencyPlot.configuration = { unitType: LATENCY_METRIC_UNIT };

                return latencyPlot;
            }

            function createLatencyPlots(metricType, signalTypeEntity, selection) {
                const postFix = getPlotNamePostFix(selection);

                const p99Plot = createLatencyPlot(
                    `${signalTypeService.METRIC_TYPE_TO_LABEL[metricType]} (${PERCENTILE_TO_NAME[99]}) ${postFix}`,
                    99,
                    { signalTypeEntity, selection }
                );
                const p90Plot = createLatencyPlot(
                    `${signalTypeService.METRIC_TYPE_TO_LABEL[metricType]} (${PERCENTILE_TO_NAME[90]}) ${postFix}`,
                    90,
                    { signalTypeEntity, selection }
                );
                const p50Plot = createLatencyPlot(
                    `${signalTypeService.METRIC_TYPE_TO_LABEL[metricType]} (${PERCENTILE_TO_NAME[50]}) ${postFix}`,
                    50,
                    { signalTypeEntity, selection }
                );

                selection.plotIds = [p99Plot.uniqueKey, p90Plot.uniqueKey, p50Plot.uniqueKey];
            }
        },
    ],
});
