import alertEventModalV2Controller from './alertEventModalV2Controller';
import { react2angular } from 'react2angular';
import { AlertEventModalHeader } from '../../../app/alertEventModalV2/alertEventModalV2Header.react';
import MutedChip from '../../../app/alertEventModalV2/MutedChip';
import NextSteps from '../../../app/alertEventModalV2/nextSteps/NextSteps.js';
import CrossLinkAngular from '../../../app/crossLinks/crossLinkReact';
import AlertEventModalBody from '../../../app/alertEventModalV2/AlertEventModalBody';
import ChartHeader from '../../../app/alertEventModalV2/chartInformation/ChartHeader';
import ChartInformation from '../../../app/alertEventModalV2/chartInformation/ChartInformation';
import HumanReadableTimeLabel from '../../../app/alertEventModalV2/humanReadableTimeLabel/HumanReadableTimeLabel';
import { autoDetectLabel } from './AutoDetectLabel.tsx';

angular
    .module('signalview.alertEventModalV2', [])
    .controller('alertEventModalV2', alertEventModalV2Controller)
    .component('crossLinkAngular', CrossLinkAngular)
    .component('alertEventModalHeader', react2angular(AlertEventModalHeader))
    .component('mutedChip', react2angular(MutedChip))
    .component('autoDetectLabel', autoDetectLabel)
    .component('alertEventModalBody', react2angular(AlertEventModalBody))
    .component('nextSteps', react2angular(NextSteps))
    .component('chartHeader', react2angular(ChartHeader))
    .component('chartInformation', react2angular(ChartInformation))
    .component('humanReadableTimeLabel', react2angular(HumanReadableTimeLabel));
