import templateUrl from './chartTooltip.tpl.html';
import { convertMSToString, safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { CHART_OVERLAY_EVENTS } from '../chartOverlay';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { getReservedDimensionKeys } from '@splunk/olly-utilities/lib/Timeseries';

export const chartTooltip = [
    '$uibPosition',
    '$timeout',
    '$sce',
    'analyticsService',
    'sortOptionService',
    'CHART_DISPLAY_EVENTS',
    'chartbuilderUtil',
    'histogramDygraphConfiguration',
    'dyGraphUtils',
    'eventModal',
    '$window',
    '$rootScope',
    'globalEventsService',
    'uiGridConstants',
    'uiGridExporterConstants',
    'uiGridExporterService',
    '$log',
    'chartDisplayUtils',
    'hasCapability',
    function (
        $uibPosition,
        $timeout,
        $sce,
        analyticsService,
        sortOptionService,
        CHART_DISPLAY_EVENTS,
        chartbuilderUtil,
        histogramDygraphConfiguration,
        dyGraphUtils,
        eventModal,
        $window,
        $rootScope,
        globalEventsService,
        uiGridConstants,
        uiGridExporterConstants,
        uiGridExporterService,
        $log,
        chartDisplayUtils,
        hasCapability
    ) {
        return {
            scope: {
                autoAddEvents: '=?',
                hoverTime: '=',
                pinTime: '=',
                latestTime: '=',
                legendData: '=',
                isDisabled: '=',
                eventList: '=',
                streamObject: '=',
                dyGraphMaps: '=',
                chartModel: '=',
                legendKeys: '=',
                hideLegend: '=',
                resetLegendPin: '=',
                dyGraphInstance: '=', // FIXME : try to avoid passing this, pretty lame
                onRowHighlight: '&?',
                hoveredTsid: '=',
                inEditor: '<?',
                pinnedTsid: '<?',
                customEventColors: '=?',
                legendDataTableClassName: '@',
                legendEventsClassName: '@',
                legendDataTableParent: '@',
                legendEventsParent: '@',
                resolution: '<?',
                disableLegendDropdown: '<?',
            },
            replace: true,
            templateUrl,
            link: function ($scope, legendContainer) {
                $scope.legendRequestedOnce = false;
                $scope.mode = 'data'; //data or events
                $scope.hideLegend = !!$scope.hideLegend;
                $scope.legendKeys = [];
                $scope.currentLegendData = [];
                $scope.showEventDetails = eventModal;
                $scope.hasCreateEventCapability = false;

                // Required for calculating height of legend when being displayed in a
                // tab section
                let legendTab = angular.element('.legend-scroll-container');
                let legendFooter = angular.element('.timestamp-title', legendContainer);
                let dataTableContainer = angular.element(
                    '.' + ($scope.legendDataTableClassName || 'legend-data-table')
                );
                let eventsContainer = angular.element(
                    '.' + ($scope.legendEventsClassName || 'legend-events')
                );

                $scope.hasDataTableTab = dataTableContainer.length;
                $scope.hasEventsTab = eventsContainer.length;

                const rowTemplate = `
          <div
              ng-mouseenter="grid.appScope.rowMouseEnter(row.entity)"
              ng-mouseleave="grid.appScope.rowMouseLeave(row.entity)"
              ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
              ui-grid-one-bind-id-grid="rowRenderIndex + \'-\' + col.uid + \'-cell\'"
              class="ui-grid-cell ui-grid-cell-container"
              ng-class="{
                \'pinned-row\': row.entity.tsid === grid.appScope.pinnedTsid,
                \'hover-active\': row.entity.tsid === grid.appScope.hoveredTsid,
                \'ui-grid-row-header-cell\': col.isRowHeader,
              }"
              role="{{ col.isRowHeader ? \'rowheader\' : \'gridcell\' }}"
              ng-style="{ \'color\': col.colDef.color || row.entity.color }"
              ui-grid-cell>
          </div>
        `;

                const systemDimensionKeys = getReservedDimensionKeys();

                const legendChartContainer = angular.element(
                    '.legend-chart-target',
                    legendContainer
                );
                const anchorElement = legendContainer.parent();
                const scrollParent = legendContainer.parent().parent();

                let activeParentResize = null;

                $scope.uiGridOptions = {
                    data: 'currentLegendData',
                    virtualizationThreshold: 50,
                    rowHeight: 20,
                    enableColumnMenus: false, // not until we persist hidden columns
                    enableRowSelection: false,
                    enableColumnResize: true,
                    enableHorizontalScrollbar: uiGridConstants.scrollbars.WHEN_NEEDED,
                    enableVerticalScrollbar: uiGridConstants.scrollbars.WHEN_NEEDED,
                    useExternalSorting: true,
                    sortInfo: { fields: ['value'], directions: ['desc'], columns: [] },
                    exporterSuppressColumns: ['_dataTableAction'],
                    onRegisterApi: function (gridApi) {
                        $scope.gridApi = gridApi;
                        $scope.gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                            // sortColumns is an array containing just the column sorted in the grid
                            const name = sortColumns[0].name; // the name of the column sorted
                            const direction = sortColumns[0].sort.direction; // "desc" or "asc"
                            $scope.uiGridOptions.sortInfo = {
                                fields: [name],
                                directions: [direction],
                                columns: [],
                            };
                        });
                    },
                    // NOTE(jwy): Template may need to be updated if the default ui-grid one changes
                    rowTemplate: rowTemplate,
                    columnDefs: null,
                    flatEntityAccess: true,
                };

                // Need to show the value columns with prefix/suffix in the data
                // table but exclude them when exporting data as CSV
                $scope.uiGridOptions.exporterSuppressColumns.push('pinnedValue', 'value');

                $scope.$watchCollection('sortedPoints', processLegendData);
                $scope.$watch('pinTime', updateColumnDefs);
                $scope.$watch('hideLegend', updateHideLegend);
                $scope.$watch('isDisabled', repositionTooltip);
                $scope.$watch('eventList', updateEventList);
                $scope.$watch('uiGridOptions.sortInfo', sortValues, true);
                $scope.$watchCollection('legendData', sortValues);
                $scope.$watchCollection('legendKeys', updateLegendKeys);

                $scope.keyPressEvent = function (event) {
                    $scope.mode = event;
                };
                /* Event bindings */
                function handleCloseEvent(event, ignoredScope) {
                    if ($scope.$parent !== ignoredScope) {
                        // close all other chart tooltips
                        closeTooltip();
                    } else {
                        // Re-enable scrolling within the entire dashboard
                        $scope.scrollRelease();
                    }
                }

                hasCapability(Capability.CREATE_EVENT).then(
                    (hasCreateEventCapability) =>
                        ($scope.hasCreateEventCapability = hasCreateEventCapability)
                );

                $scope.$on(CHART_DISPLAY_EVENTS.CHART_CLOSE_TOOLTIPS, handleCloseEvent);

                // Close tooltips when a tooltip is added from custom charts
                $rootScope.$on(CHART_OVERLAY_EVENTS.OVERLAY_CREATED, (event, ignoredScope) => {
                    handleCloseEvent(event, ignoredScope);
                    $scope.pinTime = null;
                });

                $scope.$on(CHART_DISPLAY_EVENTS.SET_LEGEND_CONTENT, function (ev, tab) {
                    switchToTab(tab, tab === 'data' ? dataTableContainer : eventsContainer);
                });

                $scope.$on(CHART_DISPLAY_EVENTS.LEGEND_TAB_SELECTED, resizeGridAgainstParent);

                $scope.$on(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE, resizeGridAgainstParent);

                $scope.$on('$destroy', function () {
                    if ($scope.tooltipDygraph && $scope.tooltipDygraph.destroy) {
                        $scope.tooltipDygraph.destroy();
                        $scope.tooltipDygraph = null;
                    }

                    legendContainer.remove();
                });

                // Recompute the elements for the legend on resize. This is a hack to get the data table
                // working correctly in olly since when the SignalView component is mounted in olly it
                // triggers a resize event. Without this it is possible that the dataTableContainer
                // and eventsContainer would not be set correctly.
                angular.element($window).resize(_.debounce(onResize, 100));
                $scope.$on(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE, _.debounce(onResize, 100));

                $scope.rowMouseEnter = function (a) {
                    $scope.onRowHighlight({ id: a.tsid });
                    $scope.hoveredTsid = a.tsid;
                    $scope.isHoveringRow = true;
                };

                $scope.rowMouseLeave = function () {
                    $scope.isHoveringRow = false;
                };

                $scope.dismissTooltip = function () {
                    $scope.resetLegendPin();
                };

                function updateColumnDefs(ts, oldts) {
                    if (!ts && !oldts) {
                        return;
                    }
                    // Default to sorting by value
                    let sortField = 'value';
                    if (ts) {
                        if (dyGraphUtils.inRange($scope.dyGraphInstance, $scope.pinTime)) {
                            $scope.formattedPinTime = chartbuilderUtil.getLegendTimeStampString(
                                $scope.pinTime
                            );
                            // upon pinning being set, sort by pinned value
                            sortField = 'pinnedValue';
                        } else {
                            $scope.formattedPinTime = 'Out of range';
                        }
                    }

                    // Sort grid after it's complete
                    $timeout(function sortCols() {
                        if ($scope.gridApi) {
                            const col = $scope.gridApi.grid.getColumn(sortField);
                            $scope.gridApi.grid.sortColumn(col, uiGridConstants.DESC);
                        }
                    }, 0);
                }

                function processLegendData(data) {
                    $scope.timeRange = null;
                    if ($scope.pinTime || $scope.latestTime) {
                        // Set to pinTime, or use the resolution window from chart's latestTime
                        $scope.timeRange = {
                            pinnedTime: $scope.pinTime || $scope.latestTime - $scope.resolution / 2,
                            resolution: $scope.resolution,
                        };
                    }

                    if ($scope.hoverTime && $scope.pinTime) {
                        const delta = $scope.hoverTime - $scope.pinTime;
                        const deltaStr = convertMSToString(Math.abs(delta));
                        $scope.hoverToPinnedDelta = deltaStr
                            ? (delta < 0 ? '-' : '+') + deltaStr
                            : null;
                    } else {
                        $scope.hoverToPinnedDelta = null;
                    }

                    let timeToFormat;
                    if ($scope.mode === 'data' || $scope.pinTime) {
                        timeToFormat = $scope.hoverTime ? $scope.hoverTime : $scope.latestTime;
                    }
                    $scope.formattedHoverTime =
                        chartbuilderUtil.getLegendTimeStampString(timeToFormat);
                    $scope.currentLegendData = [];
                    $scope.hasHeatMap = chartbuilderUtil.hasVisualization(
                        $scope.chartModel.sf_uiModel,
                        'heatmap'
                    );
                    const pinnedTS = $scope.pinTime;

                    if (data) {
                        if (!$scope.hasHeatMap) {
                            data.forEach(function (tsr) {
                                const legendRow = {};
                                $scope.legendKeys.forEach(function (key) {
                                    if (systemDimensionKeys.indexOf(key) > -1) {
                                        return;
                                    }
                                    const val = analyticsService.syntheticIdFilter(
                                        tsr.pointMetaData[key],
                                        $scope.dyGraphMaps.tsidToPlot[tsr.pointMetaData.tsid],
                                        tsr.pointMetaData
                                    );
                                    legendRow[key] = val;
                                });
                                legendRow.plainValue = tsr.value;
                                legendRow.value = tsr.prefix + ' ' + tsr.value + ' ' + tsr.suffix;
                                legendRow.valuePrefix = tsr.prefix;
                                legendRow.valueSuffix = tsr.suffix;
                                legendRow.color = tsr.color;
                                legendRow.tsid = tsr.tsid;
                                legendRow.rollup = tsr.rollup;
                                if (pinnedTS) {
                                    legendRow.plainPinnedValue = tsr.pinnedValue;
                                    legendRow.pinnedValue =
                                        tsr.prefix + ' ' + tsr.pinnedValue + ' ' + tsr.suffix;
                                }
                                legendRow.highThreshold = tsr.highThreshold;
                                legendRow.lowThreshold = tsr.lowThreshold;
                                legendRow.isPinnedOnPoint = tsr.tsid === $scope.pinnedTsid;
                                $scope.currentLegendData.push(legendRow);
                            });
                        } else {
                            processHeatMapData(data);
                        }
                    }
                }

                function processHeatMapData(data) {
                    const heatRange = $scope.dyGraphInstance.yAxisRange(0);
                    const labels = ['Value', 'Hover'];
                    const bucketCount = $scope.chartModel.sf_uiModel.chartconfig.bucketCount || 20;
                    const allHeatMapTSIDs = data.map(function (d) {
                        const visualization = safeLookup(
                            $scope.dyGraphMaps.tsidToPlot[d.tsid],
                            'configuration.visualization'
                        );
                        if (
                            visualization === 'heatmap' ||
                            (!visualization && $scope.chartModel.sf_uiModel.chartType === 'heatmap')
                        ) {
                            return d.tsid;
                        }
                        return null;
                    });

                    const datasets = data.map(function (d) {
                        return [{ yval: d.raw, canvasx: 0, name: d.tsid }];
                    });
                    const dyGraphData = dyGraphUtils.bucketize(
                        datasets,
                        0,
                        heatRange[0],
                        heatRange[1],
                        bucketCount,
                        allHeatMapTSIDs
                    );
                    const file = dyGraphData.bucketRanges.map(function (r, i) {
                        return [(r[0] + r[1]) / 2, dyGraphData.counts[i]];
                    });

                    const config = angular.extend(histogramDygraphConfiguration, {
                        dateWindow: [heatRange[0], heatRange[1]],
                        labels: labels,
                        series: {
                            Hover: {
                                color: $scope.dyGraphInstance.getColors()[0],
                                plotter: dyGraphUtils.plotters.bar,
                            },
                        },
                    });

                    $scope.tooltipDygraph = new Dygraph(legendChartContainer[0], file, config);
                }

                function closeTooltip() {
                    $scope.hideLegend = true;
                    // Un-highlight previously selected chart
                    angular.element('.chart-selected').removeClass('chart-selected');
                }

                function resizeGridInTab() {
                    // Calculate how much space there is for the grid, reserving some
                    // space for the timestamp footer. Use twice the footer height as a
                    // minimum.
                    $timeout(
                        function resizeTooltipGrid() {
                            let height;
                            if ($scope.legendDataTableParent && $scope.mode === 'data') {
                                height = angular
                                    .element('.' + $scope.legendDataTableParent)
                                    .outerHeight();
                            } else if ($scope.legendEventsParent && $scope.mode === 'event') {
                                height = angular
                                    .element('.' + $scope.legendEventsParent)
                                    .outerHeight();
                            } else {
                                height = Math.max(
                                    legendTab.outerHeight() - legendFooter.outerHeight(),
                                    2 * legendFooter.outerHeight()
                                );
                            }
                            angular
                                .element('.legend-contents', legendContainer)
                                .css('height', height + 'px');
                        },
                        0,
                        false
                    );
                }

                function onResize() {
                    // Required for calculating height of legend when being displayed in a
                    // tab section
                    legendTab = angular.element('.legend-scroll-container');
                    legendFooter = angular.element('.timestamp-title', legendContainer);
                    dataTableContainer = angular.element(
                        '.' + ($scope.legendDataTableClassName || 'legend-data-table')
                    );
                    eventsContainer = angular.element(
                        '.' + ($scope.legendEventsClassName || 'legend-events')
                    );

                    $scope.hasDataTableTab = dataTableContainer.length;
                    $scope.hasEventsTab = eventsContainer.length;

                    repositionTooltip();
                }

                function repositionTooltip() {
                    if (
                        !scrollParent.length ||
                        !scrollParent[0].getBoundingClientRect ||
                        $scope.hideLegend
                    ) {
                        return;
                    }

                    if ($scope.hasDataTableTab || $scope.hasEventsTab) {
                        resizeGridInTab();
                        return;
                    }

                    // Legend stretches from far left to right of window
                    // TODO(jwy): Remove this once charts in dashboards are tabbified
                    const elemPos = $uibPosition.offset(anchorElement);
                    let legendLeft = 0 - elemPos.left;
                    let sidebarWidths = 0;
                    const leftSidebar = angular.element('.left-side-bar');
                    if (leftSidebar.not('.ng-hide').length) {
                        legendLeft += leftSidebar.width();
                    }

                    // Compensate viewport origin difference when embedded in olly.
                    legendLeft += 3;
                    // #root id selector used so that the old nav-drawer can't be selected, as the
                    // old one shows up in an overlay and shouldn't be used in positioning
                    // calculations
                    const ollyLeftNav = angular.element('#root [data-test="nav-drawer"]');
                    if (ollyLeftNav.length > 0) {
                        legendLeft += ollyLeftNav.width();
                    }

                    // Right sidebars
                    const metricsSidebar = angular.element('.metrics-sidebar');
                    if (metricsSidebar.not('.ng-hide').length) {
                        sidebarWidths += metricsSidebar.width();
                    }

                    const scrollPos = $uibPosition.offset(scrollParent);
                    elemPos.top = elemPos.top - scrollPos.top;
                    const legendTop = elemPos.top + scrollParent.scrollTop() + elemPos.height;

                    // Scrollbar width. Borrowed from Bootstrap modal code.
                    const scrollDiv = angular.element('<div>')[0];
                    angular.element(scrollDiv).css({
                        position: 'absolute',
                        top: '-9999px',
                        width: '50px',
                        height: '50px',
                        overflow: 'scroll',
                    });
                    angular.element('.sf-ui')[0].append(scrollDiv);
                    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
                    angular.element('.sf-ui')[0].removeChild(scrollDiv);

                    // When getting the scroll parent of the data table's
                    // scroll parent, include "overflow: hidden" elements by
                    // passing true (default behavior of the jQuery function is to
                    // exclude). When hovering over the data table and the width
                    // of the browser is below the min width of one of the chart
                    // container's parents, overflow is set to hidden on what
                    // would normally be the scroll parent of the data table's
                    // scroll parent (see "scrollTrap" function). But we still
                    // want the width of the data table to match that element.
                    const legendWidth =
                        scrollParent.scrollParent(true).width() - scrollbarWidth - sidebarWidths;

                    // scrollParent of the legend is the chart. Use the width of the
                    // chart's parent.
                    legendContainer.css({
                        left: legendLeft,
                        top: legendTop,
                        width: legendWidth,
                    });
                }

                function sortValues() {
                    const legendDataCopy = angular.copy($scope.legendData);
                    let prependList;
                    if ($scope.pinnedTsid) {
                        const pinnedIdx = legendDataCopy.findIndex(
                            (d) => d.tsid === $scope.pinnedTsid
                        );
                        prependList = legendDataCopy.splice(pinnedIdx, 1);
                    } else {
                        prependList = [];
                    }
                    const sortOptions = sortOptionService.getSortOption(
                        $scope.uiGridOptions.sortInfo.fields[0]
                    );
                    sortOptions.ascending = $scope.uiGridOptions.sortInfo.directions[0] === 'asc';
                    $scope.sortedPoints = prependList.concat(
                        sortOptionService.sortTimesliceValues(legendDataCopy, sortOptions)
                    );
                }

                function updateEventList(events) {
                    $scope.detectorEvents = [];
                    $scope.customEvents = [];
                    if (events) {
                        events.forEach(function (e) {
                            if (e.metadata.sf_eventCategory === 'ALERT') {
                                $scope.detectorEvents.push(e);
                            } else {
                                if (!e.customColor && $scope.customEventColors) {
                                    e.customColor =
                                        $scope.customEventColors[e.metadata.sf_eventType];
                                }
                                $scope.customEvents.push(e);
                            }
                        });
                        $timeout(() => {
                            angular.element('.markdown-event-source-value a').click((ev) => {
                                ev.stopPropagation();
                            });
                        });
                    }
                }

                function getKeyAlias(key) {
                    switch (key) {
                        case 'value':
                            return 'Value';
                        case 'pinnedValue':
                            return 'Pinned value';
                        case 'rollup':
                            return 'Rollup';
                        case 'sf_metric':
                            return 'Plot name';
                        case 'sf_originatingMetric':
                            return 'sf_metric';
                        case 'highThreshold':
                            return 'High';
                        case 'lowThreshold':
                            return 'Low';
                        default:
                            return null;
                    }
                }

                const UNIT_COLUMNS = [
                    'plainPinnedValue',
                    'plainValue',
                    'valuePrefix',
                    'valueSuffix',
                ];

                function getColDef(key, forceWidth) {
                    const isValue = key === 'value' || key === 'pinnedValue';
                    // Need to include the value columns with prefix/suffix when
                    // exporting data as CVS but hide them in the data table
                    const isExportOnly = UNIT_COLUMNS.indexOf(key) !== -1;
                    const isThreshold = key === 'highThreshold' || key === 'lowThreshold';
                    const def = {
                        field: key,
                        color: isThreshold ? '#EA1849' : null,
                        displayName: key,
                        width: forceWidth || '*', // Default to 'auto'
                        minWidth: 100,
                        pinnedLeft: isValue,
                        visible: !isExportOnly,
                        resizeable: true,
                        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
                        // declare-used-dependency-to-linter::tableCell
                        cellTemplate: `<table-cell grid="grid"
                                       row="row"
                                       column="col"
                                       link-time="grid.appScope.timeRange"
                                       in-editor="${$scope.inEditor}"
                                       disable-legend-dropdown="${$scope.disableLegendDropdown}">
                           </table-cell>`,
                    };

                    if (key === 'value') {
                        def.sort = {
                            direction: uiGridConstants.DESC,
                            priority: 0,
                        };
                    }

                    //map some of our internal nomenclature to what makes sense to users
                    const displayName = getKeyAlias(key);
                    if (displayName) {
                        def.displayName = displayName;
                    }

                    return def;
                }

                function resizeGridAgainstParent() {
                    $timeout.cancel(activeParentResize);
                    activeParentResize = $timeout(function () {
                        resizeGridAgainstNode(legendChartContainer.parent());
                    }, 300);
                }

                function resizeGridAgainstNode(resizeParent) {
                    if (!resizeParent || !$scope.gridApi) {
                        return;
                    }

                    const grid = $scope.gridApi.grid;
                    if (!grid) {
                        return;
                    }

                    grid.gridWidth = resizeParent.width();
                    grid.gridHeight = resizeParent.height();
                    grid.refreshCanvas(true);
                }

                function updateLegendKeys(legendKeys) {
                    // Length of value typically won't be much and will vary when the
                    // mouse moves around the selected chart, so keep more space for the
                    // remaining columns
                    const valueColWidth = 2;
                    const extraCols = [
                        getColDef('pinnedValue', valueColWidth + '%'),
                        getColDef('value', valueColWidth + '%'),
                        getColDef('rollup', valueColWidth + '%'),
                    ];

                    UNIT_COLUMNS.forEach((colName) => {
                        extraCols.push(getColDef(colName));
                    });

                    const nonSystemKeys = legendKeys.filter(function (key) {
                        return systemDimensionKeys.indexOf(key) === -1;
                    });
                    const columnsAreCustom = !!safeLookup(
                        $scope.chartModel,
                        'sf_uiModel.chartconfig.legendColumnConfiguration'
                    );
                    // Use cardinality to order columns in grid so that, for example, a
                    // column with a different value in each row will appear towards the
                    // left of the grid, whereas a column with the same value in each
                    // row will appear towards the right
                    const colsCardinality = {};
                    if ($scope.streamObject) {
                        for (const tsid in $scope.streamObject.metaDataMap) {
                            for (let i = 0; i < nonSystemKeys.length; i++) {
                                const key = nonSystemKeys[i];
                                const val = $scope.streamObject.metaDataMap[tsid][key];

                                if (!colsCardinality.hasOwnProperty(key)) {
                                    colsCardinality[key] = {};
                                }
                                if (!colsCardinality[key].hasOwnProperty(val)) {
                                    colsCardinality[key][val] = 0;
                                }
                                colsCardinality[key][val]++;
                            }
                        }
                    }

                    // do not sort by cardinality if custom column definitions have been set, as they imply order
                    if (!columnsAreCustom) {
                        const keysCardinality = Object.keys(colsCardinality);
                        keysCardinality.sort(function (a, b) {
                            // ui-grid will output the columns in reverse order of what it's given
                            let diff =
                                Object.keys(colsCardinality[a]).length -
                                Object.keys(colsCardinality[b]).length;
                            if (!diff) {
                                // Default to sorting keys alphabetically by display name
                                let aName = getKeyAlias(a);
                                if (aName === null) {
                                    aName = a;
                                }
                                let bName = getKeyAlias(b);
                                if (bName === null) {
                                    bName = b;
                                }
                                diff = aName < bName ? 1 : -1;
                            }
                            return diff;
                        });
                        nonSystemKeys.sort(function (a, b) {
                            return keysCardinality.indexOf(b) - keysCardinality.indexOf(a);
                        });

                        const lowThresholdIdx = nonSystemKeys.indexOf('lowThreshold');
                        if (lowThresholdIdx !== -1) {
                            nonSystemKeys.splice(0, 0, nonSystemKeys.splice(lowThresholdIdx, 1)[0]);
                        }

                        const highTresholdIdx = nonSystemKeys.indexOf('highThreshold');
                        if (highTresholdIdx !== -1) {
                            nonSystemKeys.splice(0, 0, nonSystemKeys.splice(highTresholdIdx, 1)[0]);
                        }
                    }

                    if (!$scope.uiGridOptions.columnDefs) {
                        $scope.uiGridOptions.columnDefs = [];
                    }
                    const nonSystemCols = [];
                    nonSystemKeys.forEach(function (key) {
                        nonSystemCols.push(getColDef(key));
                    });
                    $scope.uiGridOptions.columnDefs = extraCols.concat(nonSystemCols);
                    repositionTooltip();
                }

                function switchToTab(tabMode, tabContainer) {
                    $scope.mode = tabMode;
                    resizeGridInTab();
                    tabContainer.append(legendContainer);
                    if (tabMode === 'data') {
                        $timeout(() => {
                            angular
                                .element('.data-table')
                                .find('canvas')
                                .attr('aria-label', 'Interactive chart');
                        }, 2000);
                    }
                }

                function updateHideLegend(newval, oldval) {
                    if (newval === oldval) {
                        return;
                    }
                    if (!$scope.legendRequestedOnce && $scope.hideLegend === false) {
                        $scope.legendRequestedOnce = true;
                    }

                    if ($scope.hideLegend === false) {
                        // Show legend in tab
                        if ($scope.hasDataTableTab && $scope.mode === 'data') {
                            resizeGridInTab();
                            dataTableContainer.append(legendContainer);
                            return;
                        } else if ($scope.hasEventsTab && $scope.mode === 'event') {
                            resizeGridInTab();
                            eventsContainer.append(legendContainer);
                            return;
                        }
                    }

                    if (!$scope.hideLegendRunOnce && !newval) {
                        if (scrollParent.length) {
                            scrollParent.append(legendContainer);
                        }

                        $scope.hideLegendRunOnce = true;
                    }

                    if (!newval) {
                        repositionTooltip();
                    }
                }

                $scope.getEventName = function (event) {
                    if (event.metadata.sf_eventSloAlertType) {
                        return event.metadata.sf_eventSloAlertType;
                    } else if (event.metadata.sf_detector) {
                        return event.metadata.sf_detector + ' ' + event.metadata.sf_displayName;
                    } else if (event.metadata.sf_displayName || event.metadata.sf_detectLabel) {
                        return event.metadata.sf_displayName || event.metadata.sf_detectLabel;
                    } else {
                        return (
                            event.metadata.sf_eventType +
                            ' - ' +
                            event.metadata.sf_key
                                .map(function (key) {
                                    return key !== 'sf_eventType' ? event.metadata[key] : '';
                                })
                                .join(' ')
                        );
                    }
                };

                $scope.createNewEvent = function () {
                    globalEventsService
                        .createGlobalEvent(
                            $scope.pinTime ? $scope.pinTime : Date.now(),
                            {},
                            $scope.chartModel.sf_id ? { chartId: $scope.chartModel.sf_id } : null
                        )
                        .then(function (event) {
                            if (event && event.eventType && $scope.autoAddEvents) {
                                const plots = $scope.chartModel.sf_uiModel.allPlots;
                                if (
                                    plots.some(function (eventPlot) {
                                        return (
                                            eventPlot.type === 'event' &&
                                            eventPlot.seriesData.eventQuery === event.eventType
                                        );
                                    })
                                ) {
                                    return;
                                }
                                const length = plots.length;
                                if (length === 0) {
                                    $log.warn(
                                        'Unable to add an event time series plot after creating an event.  Likely because it is a V2 Chart.'
                                    );
                                    return;
                                }
                                plots[length - 1] = angular.extend(plots[length - 1], {
                                    type: 'event',
                                    seriesData: {
                                        eventQuery: event.eventType,
                                    },
                                });
                                chartbuilderUtil.createTransientIfNeeded(
                                    $scope.chartModel.sf_uiModel.allPlots
                                );
                            }
                        });
                };

                $scope.exportAsCsv = function () {
                    const grid = $scope.gridApi.grid;
                    const exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                        grid,
                        uiGridExporterConstants.ALL
                    );

                    // Include timestamps in value column headers, and use plain values,
                    // without prefix of suffix
                    exportColumnHeaders.forEach(function (header) {
                        if (header.name === 'plainPinnedValue') {
                            header.displayName = 'Pinned Value - ' + $scope.formattedPinTime;
                        } else if (header.name === 'plainValue') {
                            header.displayName = 'Value - ' + $scope.formattedHoverTime;
                        } else if (header.name === 'valuePrefix') {
                            header.displayName = 'Value Prefix';
                        } else if (header.name === 'valueSuffix') {
                            header.displayName = 'Value Suffix';
                        }
                    });
                    const exportData = uiGridExporterService.getData(
                        grid,
                        uiGridExporterConstants.ALL,
                        uiGridExporterConstants.ALL,
                        true
                    );
                    const csvContent = uiGridExporterService.formatAsCsv(
                        exportColumnHeaders,
                        exportData
                    );

                    // There is a uiGridExporterService.downloadFile function, but the
                    // file contents open up in a separate tab in Safari, which won't be
                    // seen by users who have pop-up windows blocked in Preferences
                    const blob = new Blob([csvContent], {
                        type: 'text/csv;charset=utf-8',
                    });
                    const url = URL.createObjectURL(blob);
                    $scope.csvBlob = $sce.trustAsResourceUrl(url);

                    const chartName = $scope.chartModel.sf_chart;
                    $scope.csvBlobFile =
                        chartName
                            .replace(/[^\s\w.-]/gi, ' ')
                            .trim()
                            .replace(/\s+/g, '_') + '-data.csv';
                };

                let scrollParents = [];

                $scope.scrollTrap = function () {
                    chartDisplayUtils.enableParentScrolling(scrollParents);
                    scrollParents = chartDisplayUtils.disableParentScrolling(
                        angular.element(legendContainer)
                    );
                };

                $scope.scrollRelease = function () {
                    chartDisplayUtils.enableParentScrolling(scrollParents);
                    scrollParents = [];
                };
            },
        };
    },
];
